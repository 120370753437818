OpenSeadragon = window.OpenSeadragon || function (a) {
        return new OpenSeadragon.Viewer(a)
    };
(function (f) {
    var d = {
            "[object Boolean]": "boolean",
            "[object Number]": "number",
            "[object String]": "string",
            "[object Function]": "function",
            "[object Array]": "array",
            "[object Date]": "date",
            "[object RegExp]": "regexp",
            "[object Object]": "object"
        }, g = Object.prototype.toString, b = Object.prototype.hasOwnProperty, c = Array.prototype.push, h = Array.prototype.slice, a = String.prototype.trim,
        e = Array.prototype.indexOf;
    f.isFunction = function (j) {
        return f.type(j) === "function"
    };
    f.isArray = Array.isArray || function (j) {
            return f.type(j) === "array"
        };
    f.isWindow = function (j) {
        return j && typeof j === "object" && "setInterval" in j
    };
    f.type = function (j) {
        return j == null ? String(j) : d[g.call(j)] || "object"
    };
    f.isPlainObject = function (k) {
        if (!k || OpenSeadragon.type(k) !== "object" || k.nodeType || f.isWindow(k)) {
            return false
        }
        if (k.constructor && !b.call(k, "constructor") && !b.call(k.constructor.prototype, "isPrototypeOf")) {
            return false
        }
        var j;
        for (j in k) {
        }
        return j === undefined || b.call(k, j)
    };
    f.isEmptyObject = function (k) {
        for (var j in k) {
            return false
        }
        return true
    }
}(OpenSeadragon));
(function (g) {
    g.extend = function () {
        var v, o, m, n, s, t, r = arguments[0] || {}, p = arguments.length, u = false, q = 1;
        if (typeof r === "boolean") {
            u = r;
            r = arguments[1] || {};
            q = 2
        }
        if (typeof r !== "object" && !OpenSeadragon.isFunction(r)) {
            r = {}
        }
        if (p === q) {
            r = this;
            --q
        }
        for (; q < p; q++) {
            if ((v = arguments[q]) != null) {
                for (o in v) {
                    m = r[o];
                    n = v[o];
                    if (r === n) {
                        continue
                    }
                    if (u && n && (OpenSeadragon.isPlainObject(n) || (s = OpenSeadragon.isArray(n)))) {
                        if (s) {
                            s = false;
                            t = m && OpenSeadragon.isArray(m) ? m : []
                        } else {
                            t = m && OpenSeadragon.isPlainObject(m) ? m : {}
                        }
                        r[o] = OpenSeadragon.extend(u, t, n)
                    } else {
                        if (n !== undefined) {
                            r[o] = n
                        }
                    }
                }
            }
        }
        return r
    };
    g.extend(g, {
        DEFAULT_SETTINGS: {
            xmlPath: null,
            tileSources: null,
            tileHost: null,
            debugMode: true,
            animationTime: 1.5,
            blendTime: 0.1,
            alwaysBlend: false,
            autoHideControls: true,
            immediateRender: false,
            wrapHorizontal: false,
            wrapVertical: false,
            panHorizontal: true,
            panVertical: true,
            visibilityRatio: 0.5,
            springStiffness: 5,
            clickTimeThreshold: 200,
            clickDistThreshold: 5,
            zoomPerClick: 2,
            zoomPerScroll: 1.2,
            zoomPerSecond: 2,
            showNavigationControl: true,
            showSequenceControl: true,
            controlsFadeDelay: 2000,
            controlsFadeLength: 1500,
            mouseNavEnabled: true,
            showNavigator: true,
            navigatorElement: null,
            navigatorHeight: null,
            navigatorWidth: null,
            navigatorPosition: null,
            navigatorSizeRatio: 0.25,
            preserveViewport: false,
            defaultZoomLevel: 0,
            onPageChange: null,
            minPixelRatio: 0.5,
            imageLoaderLimit: 0,
            maxImageCacheCount: 200,
            minZoomImageRatio: 0.8,
            maxZoomPixelRatio: 2,
            fastAnimations: false,
            prefixUrl: null,
            navImages: {
                zoomIn: {
                    REST: "/images/zoomin_rest.png",
                    GROUP: "/images/zoomin_grouphover.png",
                    HOVER: "/images/zoomin_hover.png",
                    DOWN: "/images/zoomin_pressed.png"
                },
                zoomOut: {
                    REST: "/images/zoomout_rest.png",
                    GROUP: "/images/zoomout_grouphover.png",
                    HOVER: "/images/zoomout_hover.png",
                    DOWN: "/images/zoomout_pressed.png"
                },
                home: {REST: "/images/home_rest.png", GROUP: "/images/home_grouphover.png", HOVER: "/images/home_hover.png", DOWN: "/images/home_pressed.png"},
                fullpage: {
                    REST: "/images/fullpage_rest.png",
                    GROUP: "/images/fullpage_grouphover.png",
                    HOVER: "/images/fullpage_hover.png",
                    DOWN: "/images/fullpage_pressed.png"
                },
                previous: {
                    REST: "/images/previous_rest.png",
                    GROUP: "/images/previous_grouphover.png",
                    HOVER: "/images/previous_hover.png",
                    DOWN: "/images/previous_pressed.png"
                },
                next: {REST: "/images/next_rest.png", GROUP: "/images/next_grouphover.png", HOVER: "/images/next_hover.png", DOWN: "/images/next_pressed.png"}
            }
        }, SIGNAL: "----seadragon----", delegate: function (m, n) {
            return function () {
                // need to reset, because webpack fails otherwise -> arguments is a reserved wird in strict mode
                var tmp = arguments;

                if (tmp === undefined) {
                    tmp = []
                }
                return n.apply(m, tmp)
            }
        }, BROWSERS: {UNKNOWN: 0, IE: 1, FIREFOX: 2, SAFARI: 3, CHROME: 4, OPERA: 5}, getElement: function (m) {
            if (typeof(m) == "string") {
                m = document.getElementById(m)
            }
            return m
        }, getElementPosition: function (n) {
            var m = new g.Point(), p, o;
            n = g.getElement(n);
            p = g.getElementStyle(n).position == "fixed";
            o = k(n, p);
            while (o) {
                m.x += n.offsetLeft;
                m.y += n.offsetTop;
                if (p) {
                    m = m.plus(g.getPageScroll())
                }
                n = o;
                p = g.getElementStyle(n).position == "fixed";
                o = k(n, p)
            }
            return m
        }, getElementSize: function (m) {
            m = g.getElement(m);
            return new g.Point(m.clientWidth, m.clientHeight)
        }, getElementStyle: document.documentElement.currentStyle ? function (m) {
            m = g.getElement(m);
            return m.currentStyle
        } : function (m) {
            m = g.getElement(m);
            return window.getComputedStyle(m, "")
        }, getEvent: function (m) {
            if (m) {
                g.getEvent = function (n) {
                    return n
                }
            } else {
                g.getEvent = function (n) {
                    return window.event
                }
            }
            return g.getEvent(m)
        }, getMousePosition: function (m) {
            if (typeof(m.pageX) == "number") {
                g.getMousePosition = function (o) {
                    var n = new g.Point();
                    o = g.getEvent(o);
                    n.x = o.pageX;
                    n.y = o.pageY;
                    return n
                }
            } else {
                if (typeof(m.clientX) == "number") {
                    g.getMousePosition = function (o) {
                        var n = new g.Point();
                        o = g.getEvent(o);
                        n.x = o.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                        n.y = o.clientY + document.body.scrollTop + document.documentElement.scrollTop;
                        return n
                    }
                } else {
                    throw new Error("Unknown event mouse position, no known technique.")
                }
            }
            return g.getMousePosition(m)
        }, getPageScroll: function () {
            var n = document.documentElement || {}, m = document.body || {};
            if (typeof(window.pageXOffset) == "number") {
                g.getPageScroll = function () {
                    return new g.Point(window.pageXOffset, window.pageYOffset)
                }
            } else {
                if (m.scrollLeft || m.scrollTop) {
                    g.getPageScroll = function () {
                        return new g.Point(document.body.scrollLeft, document.body.scrollTop)
                    }
                } else {
                    if (n.scrollLeft || n.scrollTop) {
                        g.getPageScroll = function () {
                            return new g.Point(document.documentElement.scrollLeft, document.documentElement.scrollTop)
                        }
                    } else {
                        g.getPageScroll = function () {
                            return new g.Point(0, 0)
                        }
                    }
                }
            }
            return g.getPageScroll()
        }, getWindowSize: function () {
            var n = document.documentElement || {}, m = document.body || {};
            if (typeof(window.innerWidth) == "number") {
                g.getWindowSize = function () {
                    return new g.Point(window.innerWidth, window.innerHeight)
                }
            } else {
                if (n.clientWidth || n.clientHeight) {
                    g.getWindowSize = function () {
                        return new g.Point(document.documentElement.clientWidth, document.documentElement.clientHeight)
                    }
                } else {
                    if (m.clientWidth || m.clientHeight) {
                        g.getWindowSize = function () {
                            return new g.Point(document.body.clientWidth, document.body.clientHeight)
                        }
                    } else {
                        throw new Error("Unknown window size, no known technique.")
                    }
                }
            }
            return g.getWindowSize()
        }, makeCenteredNode: function (o) {
            var q = g.makeNeutralElement("div"), n = [], p, m;
            o = g.getElement(o);
            n.push('<div style="display:table; height:100%; width:100%;');
            n.push("border:none; margin:0px; padding:0px;");
            n.push('#position:relative; overflow:hidden; text-align:left;">');
            n.push('<div style="#position:absolute; #top:50%; width:100%; ');
            n.push("border:none; margin:0px; padding:0px;");
            n.push('display:table-cell; vertical-align:middle;">');
            n.push('<div style="#position:relative; #top:-50%; width:100%; ');
            n.push("border:none; margin:0px; padding:0px;");
            n.push('text-align:center;"></div></div></div>');
            q.innerHTML = n.join("");
            q = q.firstChild;
            p = q;
            m = q.getElementsByTagName("div");
            while (m.length > 0) {
                p = m[0];
                m = p.getElementsByTagName("div")
            }
            p.appendChild(o);
            return q
        }, makeNeutralElement: function (n) {
            var m = document.createElement(n), o = m.style;
            o.background = "transparent none";
            o.border = "none";
            o.margin = "0px";
            o.padding = "0px";
            o.position = "static";
            return m
        }, makeTransparentImage: function (m) {
            g.makeTransparentImage = function (o) {
                var n = g.makeNeutralElement("img");
                n.src = o;
                return n
            };
            if (g.Browser.vendor == g.BROWSERS.IE && g.Browser.version < 7) {
                g.makeTransparentImage = function (p) {
                    var n = g.makeNeutralElement("img"), o = null;
                    o = g.makeNeutralElement("span");
                    o.style.display = "inline-block";
                    n.onload = function () {
                        o.style.width = o.style.width || n.width + "px";
                        o.style.height = o.style.height || n.height + "px";
                        n.onload = null;
                        n = null
                    };
                    n.src = p;
                    o.style.filter = "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='" + p + "', sizingMethod='scale')";
                    return o
                }
            }
            return g.makeTransparentImage(m)
        }, setElementOpacity: function (p, o, s) {
            var n, m, q;
            p = g.getElement(p);
            if (s && !g.Browser.alpha) {
                o = Math.round(o)
            }
            if (o < 1) {
                p.style.opacity = o
            } else {
                p.style.opacity = ""
            }
            if (o == 1) {
                prevFilter = p.style.filter || "";
                p.style.filter = prevFilter.replace(/alpha\(.*?\)/g, "");
                return
            }
            m = Math.round(100 * o);
            q = " alpha(opacity=" + m + ") ";
            try {
                if (p.filters && p.filters.alpha) {
                    p.filters.alpha.opacity = m
                } else {
                    p.style.filter += q
                }
            } catch (r) {
                p.style.filter += q
            }
        }, addEvent: function (o, n, p, m) {
            o = g.getElement(o);
            if (o.addEventListener) {
                g.addEvent = function (s, r, t, q) {
                    s = g.getElement(s);
                    s.addEventListener(r, t, q)
                }
            } else {
                if (o.attachEvent) {
                    g.addEvent = function (s, r, t, q) {
                        s = g.getElement(s);
                        s.attachEvent("on" + r, t);
                        if (q && s.setCapture) {
                            s.setCapture()
                        }
                    }
                } else {
                    throw new Error("Unable to attach event handler, no known technique.")
                }
            }
            return g.addEvent(o, n, p, m)
        }, removeEvent: function (o, n, p, m) {
            o = g.getElement(o);
            if (o.removeEventListener) {
                g.removeEvent = function (s, r, t, q) {
                    s = g.getElement(s);
                    s.removeEventListener(r, t, q)
                }
            } else {
                if (o.detachEvent) {
                    g.removeEvent = function (s, r, t, q) {
                        s = g.getElement(s);
                        s.detachEvent("on" + r, t);
                        if (q && s.releaseCapture) {
                            s.releaseCapture()
                        }
                    }
                } else {
                    throw new Error("Unable to detach event handler, no known technique.")
                }
            }
            return g.removeEvent(o, n, p, m)
        }, cancelEvent: function (m) {
            m = g.getEvent(m);
            if (m.preventDefault) {
                g.cancelEvent = function (n) {
                    n.preventDefault()
                }
            } else {
                g.cancelEvent = function (n) {
                    n = g.getEvent(n);
                    n.cancel = true;
                    n.returnValue = false
                }
            }
            g.cancelEvent(m)
        }, stopEvent: function (m) {
            m = g.getEvent(m);
            if (m.stopPropagation) {
                g.stopEvent = function (n) {
                    n.stopPropagation()
                }
            } else {
                g.stopEvent = function (n) {
                    n = g.getEvent(n);
                    n.cancelBubble = true
                }
            }
            g.stopEvent(m)
        }, createCallback: function (m, p) {
            var o = [], n;
            for (n = 2; n < arguments.length; n++) {
                o.push(arguments[n])
            }
            return function () {
                var q = o.concat([]), r;
                for (r = 0; r < arguments.length; r++) {
                    q.push(arguments[r])
                }
                return p.apply(m, q)
            }
        }, getUrlParameter: function (m) {
            var n = j[m];
            return n ? n : null
        }, createAjaxRequest: function () {
            var m;
            if (window.ActiveXObject) {
                for (i = 0; i < d.length; i++) {
                    try {
                        m = new ActiveXObject(d[i]);
                        g.createAjaxRequest = function () {
                            return new ActiveXObject(d[i])
                        };
                        break
                    } catch (n) {
                        continue
                    }
                }
            } else {
                if (window.XMLHttpRequest) {
                    g.createAjaxRequest = function () {
                        return new XMLHttpRequest()
                    };
                    m = new XMLHttpRequest()
                }
            }
            if (!m) {
                throw new Error("Browser doesn't support XMLHttpRequest.")
            }
            return m
        }, makeAjaxRequest: function (n, t) {
            var p = true, q = g.createAjaxRequest(), s, m, o;
            if (g.isPlainObject(n)) {
                m.async = m.async || p
            } else {
                m = {url: n, async: g.isFunction(t), success: t, error: null}
            }
            if (m.async) {
                q.onreadystatechange = function () {
                    if (q.readyState == 4) {
                        q.onreadystatechange = new function () {
                        };
                        m.success(q)
                    }
                }
            }
            try {
                q.open("GET", m.url, m.async);
                q.send(null)
            } catch (r) {
                g.console.log("%s while making AJAX request: %s", r.name, r.message);
                q.onreadystatechange = null;
                q = null;
                if (m.error && g.isFunction(m.error)) {
                    m.error(q)
                }
            }
            if (!m.async && g.isFunction(m.success)) {
                m.success(q)
            }
            return m.async ? null : q
        }, jsonp: function (u) {
            var s, m = u.url, r = document.head || document.getElementsByTagName("head")[0] || document.documentElement,
                n = u.callbackName || "openseadragon" + (+new Date()), q = window[n], o = "$1" + n + "$2", p = u.param || "callback", t = u.callback;
            m = m.replace(/(\=)\?(&|$)|\?\?/i, o);
            m += (/\?/.test(m) ? "&" : "?") + p + "=" + n;
            window[n] = function (v) {
                if (!q) {
                    try {
                        delete window[n]
                    } catch (w) {
                    }
                } else {
                    window[n] = q
                }
                if (t && g.isFunction(t)) {
                    t(v)
                }
            };
            s = document.createElement("script");
            if (undefined !== u.async || false !== u.async) {
                s.async = "async"
            }
            if (u.scriptCharset) {
                s.charset = u.scriptCharset
            }
            s.src = m;
            s.onload = s.onreadystatechange = function (w, v) {
                if (v || !s.readyState || /loaded|complete/.test(s.readyState)) {
                    s.onload = s.onreadystatechange = null;
                    if (r && s.parentNode) {
                        r.removeChild(s)
                    }
                    s = undefined
                }
            };
            r.insertBefore(s, r.firstChild)
        }, createFromDZI: function (u, x, y) {
            var q = typeof(x) == "function", w = (u.substring(0, 1) != "<" && u.substring(0, 1) != "{") ? u : null, n = w ? null : u, r = null, p, m, o, t, s;
            if (y) {
                t = y + "/_files/"
            } else {
                if (w) {
                    p = w.split("/");
                    m = p[p.length - 1];
                    if (m.match(/_dzi\.js$/)) {
                        m = m.replace("_dzi.js", ".js")
                    }
                    o = m.lastIndexOf(".");
                    if (o > -1) {
                        p[p.length - 1] = m.slice(0, o)
                    }
                    t = p.join("/") + "_files/"
                }
            }
            function v(z, B) {
                try {
                    return z(B, t)
                } catch (A) {
                    if (q) {
                        return null
                    } else {
                        throw A
                    }
                }
            }

            if (q) {
                if (n) {
                    window.setTimeout(function () {
                        var z = v(a, g.parseXml(xmlString));
                        x(z, r)
                    }, 1)
                } else {
                    if (w.match(/_dzi\.js$/)) {
                        s = w.split("/").pop().replace(".js", "");
                        g.jsonp({
                            url: w, callbackName: s, callback: function (A) {
                                var z = v(c, A.Image);
                                x(z)
                            }
                        })
                    } else {
                        g.makeAjaxRequest(w, function (A) {
                            var z = v(l, A);
                            x(z, r)
                        })
                    }
                }
                return null
            }
            if (n) {
                return v(a, g.parseXml(n))
            } else {
                return v(l, g.makeAjaxRequest(w))
            }
        }, parseXml: function (m) {
            if (window.ActiveXObject) {
                g.parseXml = function (n) {
                    var o = null, p;
                    o = new ActiveXObject("Microsoft.XMLDOM");
                    o.async = false;
                    o.loadXML(n);
                    return o
                }
            } else {
                if (window.DOMParser) {
                    g.parseXml = function (n) {
                        var o = null, p;
                        p = new DOMParser();
                        o = p.parseFromString(n, "text/xml");
                        return o
                    }
                } else {
                    throw new Error("Browser doesn't support XML DOM.")
                }
            }
            return g.parseXml(m)
        }, imageFormatSupported: function (m) {
            m = m ? m : "";
            return !!f[m.toLowerCase()]
        }
    });
    g.Browser = {vendor: g.BROWSERS.UNKNOWN, version: 0, alpha: true};
    var d = ["Msxml2.XMLHTTP", "Msxml3.XMLHTTP", "Microsoft.XMLHTTP"], f = {bmp: false, jpeg: true, jpg: true, png: true, tif: false, wdp: false}, j = {};
    (function () {
        var t = navigator.appName, m = navigator.appVersion, q = navigator.userAgent;
        switch (navigator.appName) {
            case"Microsoft Internet Explorer":
                if (!!window.attachEvent && !!window.ActiveXObject) {
                    g.Browser.vendor = g.BROWSERS.IE;
                    g.Browser.version = parseFloat(q.substring(q.indexOf("MSIE") + 5, q.indexOf(";", q.indexOf("MSIE"))))
                }
                break;
            case"Netscape":
                if (!!window.addEventListener) {
                    if (q.indexOf("Firefox") >= 0) {
                        g.Browser.vendor = g.BROWSERS.FIREFOX;
                        g.Browser.version = parseFloat(q.substring(q.indexOf("Firefox") + 8))
                    } else {
                        if (q.indexOf("Safari") >= 0) {
                            g.Browser.vendor = q.indexOf("Chrome") >= 0 ? g.BROWSERS.CHROME : g.BROWSERS.SAFARI;
                            g.Browser.version = parseFloat(q.substring(q.substring(0, q.indexOf("Safari")).lastIndexOf("/") + 1, q.indexOf("Safari")))
                        }
                    }
                }
                break;
            case"Opera":
                g.Browser.vendor = g.BROWSERS.OPERA;
                g.Browser.version = parseFloat(m);
                break
        }
        var r = window.location.search.substring(1), s = r.split("&"), o, n, p;
        for (p = 0; p < s.length; p++) {
            o = s[p];
            n = o.indexOf("=");
            if (n > 0) {
                j[o.substring(0, n)] = decodeURIComponent(o.substring(n + 1))
            }
        }
        g.Browser.alpha = !((g.Browser.vendor == g.BROWSERS.IE && g.Browser.version < 9) || (g.Browser.vendor == g.BROWSERS.CHROME && g.Browser.version < 2))
    })();
    var e = function (m) {
    };
    g.console = window.console || {log: e, debug: e, info: e, warn: e, error: e};
    function k(m, n) {
        if (n && m != document.body) {
            return document.body
        } else {
            return m.offsetParent
        }
    }

    function l(q, n) {
        var m, p, o = null;
        if (!q) {
            throw new Error(g.getString("Errors.Security"))
        } else {
            if (q.status !== 200 && q.status !== 0) {
                m = q.status;
                p = (m == 404) ? "Not Found" : q.statusText;
                throw new Error(g.getString("Errors.Status", m, p))
            }
        }
        if (q.responseXML && q.responseXML.documentElement) {
            o = q.responseXML
        } else {
            if (q.responseText) {
                o = g.parseXml(q.responseText)
            }
        }
        return a(o, n)
    }

    function a(o, n) {
        if (!o || !o.documentElement) {
            throw new Error(g.getString("Errors.Xml"))
        }
        var m = o.documentElement, q = m.tagName;
        if (q == "Image") {
            try {
                return h(m, n)
            } catch (p) {
                throw (p instanceof Error) ? p : new Error(g.getString("Errors.Dzi"))
            }
        } else {
            if (q == "Collection") {
                throw new Error(g.getString("Errors.Dzc"))
            } else {
                if (q == "Error") {
                    return b(m)
                }
            }
        }
        throw new Error(g.getString("Errors.Dzi"))
    }

    function h(x, u) {
        var s = x.getAttribute("Format"), r = x.getElementsByTagName("Size")[0], q = x.getElementsByTagName("DisplayRect"),
            n = parseInt(r.getAttribute("Width")), y = parseInt(r.getAttribute("Height")), A = parseInt(x.getAttribute("TileSize")),
            t = parseInt(x.getAttribute("Overlap")), z = [], w, o, p;
        if (!g.imageFormatSupported(s)) {
            throw new Error(g.getString("Errors.ImageFormat", s.toUpperCase()))
        }
        var m = null;
        var v = null;
        for (p = 0; p < q.length; p++) {
            w = q[p];
            o = w.getElementsByTagName("Rect")[0];
            m = parseInt(w.getAttribute("MinLevel"));
            v = parseInt(w.getAttribute("MaxLevel"));
            z.push(new g.DisplayRect(parseInt(o.getAttribute("X")), parseInt(o.getAttribute("Y")), parseInt(o.getAttribute("Width")), parseInt(o.getAttribute("Height")), parseInt(w.getAttribute("MinLevel")), parseInt(w.getAttribute("MaxLevel"))))
        }
        return new g.DziTileSource(n, y, A, t, u, s, z, m, v)
    }

    function c(m, s) {
        var q = m.Format, t = m.Size, x = m.DisplayRect || [], n = parseInt(t.Width), u = parseInt(t.Height), w = parseInt(m.TileSize), r = parseInt(m.Overlap),
            v = [], o, p;
        if (!imageFormatSupported(q)) {
            throw new Error(g.getString("Errors.ImageFormat", q.toUpperCase()))
        }
        for (p = 0; p < x.length; p++) {
            o = x[p].Rect;
            v.push(new g.DisplayRect(parseInt(o.X), parseInt(o.Y), parseInt(o.Width), parseInt(o.Height), 0, parseInt(o.MaxLevel)))
        }
        return new g.DziTileSource(n, u, w, r, s, q, v)
    }

    function b(o) {
        var m = o.getElementsByTagName("Message")[0], n = m.firstChild.nodeValue;
        throw new Error(n)
    }
}(OpenSeadragon));
(function (a) {
    a.EventHandler = function () {
        this.events = {}
    };
    a.EventHandler.prototype = {
        addHandler: function (b, d) {
            var c = this.events[b];
            if (!c) {
                this.events[b] = c = []
            }
            if (d && a.isFunction(d)) {
                c[c.length] = d
            }
        }, removeHandler: function (b, d) {
            var c = this.events[b];
            if (!c) {
                return
            }
        }, getHandler: function (b) {
            var c = this.events[b];
            if (!c || !c.length) {
                return null
            }
            c = c.length === 1 ? [c[0]] : Array.apply(null, c);
            return function (g, d) {
                var e, f = c.length;
                for (e = 0; e < f; e++) {
                    if (c[e]) {
                        c[e](g, d)
                    }
                }
            }
        }, raiseEvent: function (c, b) {
            var d = this.getHandler(c);
            if (d) {
                if (!b) {
                    b = new Object()
                }
                d(this, b)
            }
        }
    }
}(OpenSeadragon));
(function (l) {
    var f = false, c = false, p = {}, n = [], t = {};
    l.MouseTracker = function (L) {
        var K = arguments;
        if (!l.isPlainObject(L)) {
            L = {element: K[0], clickTimeThreshold: K[1], clickDistThreshold: K[2]}
        }
        this.hash = Math.random();
        this.element = l.getElement(L.element);
        this.clickTimeThreshold = L.clickTimeThreshold;
        this.clickDistThreshold = L.clickDistThreshold;
        this.enterHandler = L.enterHandler || null;
        this.exitHandler = L.exitHandler || null;
        this.pressHandler = L.pressHandler || null;
        this.releaseHandler = L.releaseHandler || null;
        this.scrollHandler = L.scrollHandler || null;
        this.clickHandler = L.clickHandler || null;
        this.dragHandler = L.dragHandler || null;
        this.keyHandler = L.keyHandler || null;
        this.focusHandler = L.focusHandler || null;
        this.blurHandler = L.blurHandler || null;
        var M = this;
        t[this.hash] = {
            mouseover: function (N) {
                B(M, N)
            },
            mouseout: function (N) {
                w(M, N)
            },
            mousedown: function (N) {
                o(M, N)
            },
            mouseup: function (N) {
                h(M, N)
            },
            click: function (N) {
                m(M, N)
            },
            DOMMouseScroll: function (N) {
                e(M, N)
            },
            mousewheel: function (N) {
                e(M, N)
            },
            mouseupie: function (N) {
                A(M, N)
            },
            mousemoveie: function (N) {
                q(M, N)
            },
            mouseupwindow: function (N) {
                s(M, N)
            },
            mousemove: function (N) {
                v(M, N)
            },
            touchstart: function (N) {
                g(M, N)
            },
            touchmove: function (N) {
                k(M, N)
            },
            touchend: function (N) {
                d(M, N)
            },
            keypress: function (N) {
                x(M, N)
            },
            focus: function (N) {
                j(M, N)
            },
            blur: function (N) {
                u(M, N)
            },
            tracking: false,
            capturing: false,
            buttonDown: false,
            insideElement: false,
            lastPoint: null,
            lastMouseDownTime: null,
            lastMouseDownPoint: null,
            lastPinchDelta: 0
        }
    };
    l.MouseTracker.prototype = {
        isTracking: function () {
            return t[this.hash].tracking
        }, setTracking: function (K) {
            if (K) {
                F(this)
            } else {
                J(this)
            }
            return this
        }, enterHandler: function () {
        }, exitHandler: function () {
        }, pressHandler: function () {
        }, releaseHandler: function () {
        }, scrollHandler: function () {
        }, clickHandler: function () {
        }, dragHandler: function () {
        }, keyHandler: function () {
        }, focusHandler: function () {
        }, blurHandler: function () {
        }
    };
    function F(O) {
        var L = ["mouseover", "mouseout", "mousedown", "mouseup", "click", "DOMMouseScroll", "mousewheel", "keypress", "focus", "blur"], M = t[O.hash], N, K;
        if (!M.tracking) {
            for (K = 0; K < L.length; K++) {
                N = L[K];
                l.addEvent(O.element, N, M[N], false)
            }
            M.tracking = true;
            p[O.hash] = O
        }
    }

    function J(O) {
        var L = ["mouseover", "mouseout", "mousedown", "mouseup", "click", "DOMMouseScroll", "mousewheel", "keypress", "focus", "blur"], M = t[O.hash], N, K;
        if (M.tracking) {
            for (K = 0; K < L.length; K++) {
                N = L[K];
                l.removeEvent(O.element, N, M[N], false)
            }
            H(O);
            M.tracking = false;
            delete p[O.hash]
        }
    }

    function b(K) {
        return t[K.hash].insideElement
    }

    function y(L) {
        var K = t[L.hash];
        if (!K.capturing) {
            if (l.Browser.vendor == l.BROWSERS.IE && l.Browser.version < 9) {
                l.removeEvent(L.element, "mouseup", K.mouseup, false);
                l.addEvent(L.element, "mouseup", K.mouseupie, true);
                l.addEvent(L.element, "mousemove", K.mousemoveie, true)
            } else {
                l.addEvent(window, "mouseup", K.mouseupwindow, true);
                l.addEvent(window, "mousemove", K.mousemove, true)
            }
            K.capturing = true
        }
    }

    function H(L) {
        var K = t[L.hash];
        if (K.capturing) {
            if (l.Browser.vendor == l.BROWSERS.IE && l.Browser.version < 9) {
                l.removeEvent(L.element, "mousemove", K.mousemoveie, true);
                l.removeEvent(L.element, "mouseup", K.mouseupie, true);
                l.addEvent(L.element, "mouseup", K.mouseup, false)
            } else {
                l.removeEvent(window, "mousemove", K.mousemove, true);
                l.removeEvent(window, "mouseup", K.mouseupwindow, true)
            }
            K.capturing = false
        }
    }

    function I(N, K, M) {
        var L;
        for (L in p) {
            if (p.hasOwnProperty(L) && N.hash != L) {
                K(p[L], M)
            }
        }
    }

    function j(M, L) {
        var K;
        if (M.focusHandler) {
            K = M.focusHandler(M, L);
            if (K === false) {
                l.cancelEvent(L)
            }
        }
    }

    function u(M, L) {
        var K;
        if (M.blurHandler) {
            K = M.blurHandler(M, L);
            if (K === false) {
                l.cancelEvent(L)
            }
        }
    }

    function x(M, L) {
        var K;
        if (M.keyHandler) {
            K = M.keyHandler(M, L.keyCode ? L.keyCode : L.charCode, L.shiftKey);
            if (K === false) {
                l.cancelEvent(L)
            }
        }
    }

    function B(N, M) {
        var M = l.getEvent(M), L = t[N.hash], K;
        if (l.Browser.vendor == l.BROWSERS.IE && l.Browser.version < 9 && L.capturing && !r(M.srcElement, N.element)) {
            I(N, B, M)
        }
        var P = M.target ? M.target : M.srcElement, O = M.relatedTarget ? M.relatedTarget : M.fromElement;
        if (!r(N.element, P) || r(N.element, O)) {
            return
        }
        L.insideElement = true;
        if (N.enterHandler) {
            K = N.enterHandler(N, D(M, N.element), L.buttonDown, f);
            if (K === false) {
                l.cancelEvent(M)
            }
        }
    }

    function w(N, M) {
        var M = l.getEvent(M), L = t[N.hash], K;
        if (l.Browser.vendor == l.BROWSERS.IE && l.Browser.version < 9 && L.capturing && !r(M.srcElement, N.element)) {
            I(N, w, M)
        }
        var P = M.target ? M.target : M.srcElement, O = M.relatedTarget ? M.relatedTarget : M.toElement;
        if (!r(N.element, P) || r(N.element, O)) {
            return
        }
        L.insideElement = false;
        if (N.exitHandler) {
            K = N.exitHandler(N, D(M, N.element), L.buttonDown, f);
            if (K === false) {
                l.cancelEvent(M)
            }
        }
    }

    function o(N, M) {
        var M = l.getEvent(M), L = t[N.hash], K;
        if (M.button == 2) {
            return
        }
        L.buttonDown = true;
        L.lastPoint = E(M);
        L.lastMouseDownPoint = L.lastPoint;
        L.lastMouseDownTime = +new Date();
        if (N.pressHandler) {
            K = N.pressHandler(N, D(M, N.element));
            if (K === false) {
                l.cancelEvent(M)
            }
        }
        if (N.pressHandler || N.dragHandler) {
            l.cancelEvent(M)
        }
        if (!(l.Browser.vendor == l.BROWSERS.IE && l.Browser.version < 9) || !c) {
            y(N);
            c = true;
            n = [N]
        } else {
            if (l.Browser.vendor == l.BROWSERS.IE && l.Browser.version < 9) {
                n.push(N)
            }
        }
    }

    function g(N, M) {
        var L, K;
        N.ignoreMouseMove = true;
        if (M.touches.length == 1 && M.targetTouches.length == 1 && M.changedTouches.length == 1) {
            t[N.hash].lastTouch = M.touches[0];
            B(N, M.changedTouches[0]);
            o(N, M.touches[0])
        }
        if (M.touches.length == 2) {
            L = E(M.touches[0]);
            K = E(M.touches[1]);
            t[N.hash].lastPinchDelta = Math.abs(L.x - K.x) + Math.abs(L.y - K.y)
        }
        M.preventDefault()
    }

    function h(O, N) {
        var N = l.getEvent(N), M = t[O.hash], L = M.buttonDown, P = M.insideElement, K;
        if (N.button == 2) {
            return
        }
        M.buttonDown = false;
        if (O.releaseHandler) {
            K = O.releaseHandler(O, D(N, O.element), L, P);
            if (K === false) {
                l.cancelEvent(N)
            }
        }
        if (L && P) {
            z(O, N)
        }
    }

    function d(L, K) {
        if (K.touches.length == 0 && K.targetTouches.length == 0 && K.changedTouches.length == 1) {
            t[L.hash].lastTouch = null;
            h(L, K.changedTouches[0]);
            w(L, K.changedTouches[0])
        }
        if (K.touches.length + K.changedTouches.length == 2) {
            t[L.hash].lastPinchDelta = null
        }
        K.preventDefault()
    }

    function A(M, L) {
        var L = l.getEvent(L), N, K;
        if (L.button == 2) {
            return
        }
        for (K = 0; K < n.length; K++) {
            N = n[K];
            if (!b(N)) {
                h(N, L)
            }
        }
        H(M);
        c = false;
        L.srcElement.fireEvent("on" + L.type, document.createEventObject(L));
        l.stopEvent(L)
    }

    function s(L, K) {
        if (!t[L.hash].insideElement) {
            h(L, K)
        }
        H(L)
    }

    function m(L, K) {
        if (L.clickHandler) {
            l.cancelEvent(K)
        }
    }

    function e(M, L) {
        var N = 0, K;
        if (!L) {
            L = window.event
        }
        if (L.wheelDelta) {
            N = L.wheelDelta;
            if (window.opera) {
                N = -N
            }
        } else {
            if (L.detail) {
                N = -L.detail
            }
        }
        N = N > 0 ? 1 : -1;
        if (M.scrollHandler) {
            K = M.scrollHandler(M, D(L, M.element), N, L.shiftKey);
            if (K === false) {
                l.cancelEvent(L)
            }
        }
    }

    function z(O, N) {
        var N = l.getEvent(N), M = t[O.hash], L;
        if (N.button == 2) {
            return
        }
        var Q = +new Date() - M.lastMouseDownTime, K = E(N), R = M.lastMouseDownPoint.distanceTo(K), P = Q <= O.clickTimeThreshold && R <= O.clickDistThreshold;
        if (O.clickHandler) {
            L = O.clickHandler(O, D(N, O.element), P, N.shiftKey, N);
            if (L === false) {
                l.cancelEvent(N)
            }
        }
    }

    function v(L, K) {
        if (!L.ignoreMouseMove) {
            G(L, K)
        }
    }

    function G(O, N) {
        var N = l.getEvent(N), M = t[O.hash], L = E(N), P = L.minus(M.lastPoint), K;
        M.lastPoint = L;
        if (O.dragHandler) {
            K = O.dragHandler(O, D(N, O.element), P, N.shiftKey);
            if (K === false) {
                l.cancelEvent(N)
            }
        }
    }

    function k(O, N) {
        var L, K, M;
        if (N.touches.length === 1 && N.targetTouches.length === 1 && N.changedTouches.length === 1 && true) {
            G(O, N.touches[0])
        } else {
            if (N.touches.length === 2) {
                L = E(N.touches[0]);
                K = E(N.touches[1]);
                M = Math.abs(L.x - K.x) + Math.abs(L.y - K.y);
                if (Math.abs(t[O.hash].lastPinchDelta - M) > 75) {
                    e(O, {
                        shift: false,
                        pageX: (N.touches[0].pageX + N.touches[1].pageX) / 2,
                        pageY: (N.touches[0].pageY + N.touches[1].pageY) / 2,
                        detail: (t[O.hash].lastPinchDelta > M) ? 1 : -1
                    });
                    t[O.hash].lastPinchDelta = M
                }
            }
        }
        N.preventDefault()
    }

    function q(M, L) {
        var K;
        for (K = 0; K < n.length; K++) {
            v(n[K], L)
        }
        l.stopEvent(L)
    }

    function E(K) {
        return l.getMousePosition(K)
    }

    function D(M, L) {
        var K = l.getMousePosition(M), N = l.getElementPosition(L);
        return K.minus(N)
    }

    function r(M, L) {
        var K = document.body;
        while (L && M != L && K != L) {
            try {
                L = L.parentNode
            } catch (N) {
                return false
            }
        }
        return M == L
    }

    function a() {
        f = true
    }

    function C() {
        f = false
    }

    (function () {
        if (l.Browser.vendor == l.BROWSERS.IE && l.Browser.version < 9) {
            l.addEvent(document, "mousedown", a, false);
            l.addEvent(document, "mouseup", C, false)
        } else {
            l.addEvent(window, "mousedown", a, true);
            l.addEvent(window, "mouseup", C, true)
        }
    })()
}(OpenSeadragon));
(function (a) {
    a.ControlAnchor = {NONE: 0, TOP_LEFT: 1, TOP_RIGHT: 2, BOTTOM_RIGHT: 3, BOTTOM_LEFT: 4};
    a.Control = function (d, c, b) {
        this.element = d;
        this.anchor = c;
        this.container = b;
        this.wrapper = a.makeNeutralElement("span");
        this.wrapper.style.display = "inline-block";
        this.wrapper.appendChild(this.element);
        if (this.anchor == a.ControlAnchor.NONE) {
            this.wrapper.style.width = this.wrapper.style.height = "100%"
        }
        if (this.anchor == a.ControlAnchor.TOP_RIGHT || this.anchor == a.ControlAnchor.BOTTOM_RIGHT) {
            this.container.insertBefore(this.wrapper, this.container.firstChild)
        } else {
            this.container.appendChild(this.wrapper)
        }
    };
    a.Control.prototype = {
        destroy: function () {
            this.wrapper.removeChild(this.element);
            this.container.removeChild(this.wrapper)
        }, isVisible: function () {
            return this.wrapper.style.display != "none"
        }, setVisible: function (b) {
            this.wrapper.style.display = b ? "inline-block" : "none"
        }, setOpacity: function (b) {
            if (this.element[a.SIGNAL] && a.Browser.vendor == a.BROWSERS.IE) {
                a.setElementOpacity(this.element, b, true)
            } else {
                a.setElementOpacity(this.wrapper, b, true)
            }
        }
    }
}(OpenSeadragon));
(function (c) {
    var b = {};
    c.ControlDock = function (d) {
        var g = ["topleft", "topright", "bottomright", "bottomleft"], f, e;
        c.extend(true, this, {
            id: "controldock-" + (+new Date()) + "-" + Math.floor(Math.random() * 1000000),
            container: c.makeNeutralElement("form"),
            controls: []
        }, d);
        if (this.element) {
            this.element = c.getElement(this.element);
            this.element.appendChild(this.container);
            this.element.style.position = "relative";
            this.container.style.width = "100%";
            this.container.style.height = "100%"
        }
        for (e = 0; e < g.length; e++) {
            f = g[e];
            this.controls[f] = c.makeNeutralElement("div");
            this.controls[f].style.position = "absolute";
            if (f.match("left")) {
                this.controls[f].style.left = "0px"
            }
            if (f.match("right")) {
                this.controls[f].style.right = "0px"
            }
            if (f.match("top")) {
                this.controls[f].style.top = "0px"
            }
            if (f.match("bottom")) {
                this.controls[f].style.bottom = "0px"
            }
        }
        this.container.appendChild(this.controls.topleft);
        this.container.appendChild(this.controls.topright);
        this.container.appendChild(this.controls.bottomright);
        this.container.appendChild(this.controls.bottomleft)
    };
    c.ControlDock.prototype = {
        addControl: function (e, d) {
            var e = c.getElement(e), f = null;
            if (a(this, e) >= 0) {
                return
            }
            switch (d) {
                case c.ControlAnchor.TOP_RIGHT:
                    f = this.controls.topright;
                    e.style.position = "relative";
                    e.style.marginRight = "4px";
                    e.style.marginTop = "4px";
                    break;
                case c.ControlAnchor.BOTTOM_RIGHT:
                    f = this.controls.bottomright;
                    e.style.position = "relative";
                    e.style.marginRight = "4px";
                    e.style.marginBottom = "4px";
                    break;
                case c.ControlAnchor.BOTTOM_LEFT:
                    f = this.controls.bottomleft;
                    e.style.position = "relative";
                    e.style.marginLeft = "4px";
                    e.style.marginBottom = "4px";
                    break;
                case c.ControlAnchor.TOP_LEFT:
                    f = this.controls.topleft;
                    e.style.position = "relative";
                    e.style.marginLeft = "4px";
                    e.style.marginTop = "4px";
                    break;
                case c.ControlAnchor.NONE:
                default:
                    f = this.container;
                    e.style.margin = "0px";
                    e.style.padding = "0px";
                    break
            }
            this.controls.push(new c.Control(e, d, f));
            e.style.display = "inline-block"
        }, removeControl: function (e) {
            var e = c.getElement(e), d = a(this, e);
            if (d >= 0) {
                this.controls[d].destroy();
                this.controls.splice(d, 1)
            }
            return this
        }, clearControls: function () {
            while (this.controls.length > 0) {
                this.controls.pop().destroy()
            }
            return this
        }, areControlsEnabled: function () {
            var d;
            for (d = this.controls.length - 1; d >= 0; d--) {
                if (this.controls[d].isVisible()) {
                    return true
                }
            }
            return false
        }, setControlsEnabled: function (d) {
            var e;
            for (e = this.controls.length - 1; e >= 0; e--) {
                this.controls[e].setVisible(d)
            }
            return this
        }
    };
    function a(g, f) {
        var d = g.controls, e;
        for (e = d.length - 1; e >= 0; e--) {
            if (d[e].element == f) {
                return e
            }
        }
        return -1
    }
}(OpenSeadragon));
(function (C) {
    var p = {}, b = {};
    var M = false;
    var t = null;
    var q = null;
    var f = "none";
    (function () {
        var U = ["transform", "WebkitTransform", "msTransform", "MozTransform"];
        var T = document.documentElement || {};
        var V;
        while (V = U.shift()) {
            if (typeof T.style[V] != "undefined") {
                M = true;
                t = V;
                q = V + "Origin";
                break
            }
        }
        C.CAN_USE_CSS_TRANSFORMS = M
    })();
    var j = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
    var P = window.cancelAnimationFrame || window.mozCancelAnimationFrame || window.webkitCancelAnimationFrame || window.msCancelAnimationFrame;
    var a = !(typeof j === "undefined");
    C.CAN_USE_REQUEST_ANIMATION_FRAME = a;
    C.Viewer = function (V) {
        var U = arguments, Y = this, W;
        if (!C.isPlainObject(V)) {
            V = {
                id: U[0],
                xmlPath: U.length > 1 ? U[1] : undefined,
                prefixUrl: U.length > 2 ? U[2] : undefined,
                controls: U.length > 3 ? U[3] : undefined,
                overlays: U.length > 4 ? U[4] : undefined,
                overlayControls: U.length > 5 ? U[5] : undefined
            }
        }
        if (V.config) {
            C.extend(true, V, V.config);
            delete V.config
        }
        C.extend(true, this, {
            id: V.id,
            hash: V.id,
            element: null,
            canvas: null,
            container: null,
            overlays: [],
            overlayControls: [],
            drawers: [],
            previousBody: [],
            customControls: [],
            source: null,
            drawer: null,
            viewport: null,
            navigator: null,
            navImages: null,
            buttons: null,
            profiler: null
        }, C.DEFAULT_SETTINGS, V);
        p[this.hash] = {
            fsBoundsDelta: new C.Point(1, 1),
            prevContainerSize: null,
            lastOpenStartTime: 0,
            lastOpenEndTime: 0,
            animating: false,
            forceRedraw: false,
            mouseInside: false,
            group: null,
            zooming: false,
            zoomFactor: null,
            lastZoomTime: null,
            sequenced: false,
            sequence: 0
        };
        C.EventHandler.call(this);
        C.ControlDock.call(this, V);
        var T, X;
        if (this.xmlPath) {
            this.tileSources = [this.xmlPath]
        }
        if (this.tileSources) {
            if (C.isArray(this.tileSources)) {
                if (this.tileSources.length > 1) {
                    p[this.hash].sequenced = true
                }
                T = this.tileSources[0]
            } else {
                T = this.tileSources
            }
            this.openTileSource(T)
        }
        this.element = this.element || document.getElementById(this.id);
        this.canvas = C.makeNeutralElement("div");
        (function (Z) {
            Z.width = "100%";
            Z.height = "100%";
            Z.overflow = "hidden";
            Z.position = "absolute";
            Z.top = "0px";
            Z.left = "0px"
        }(this.canvas.style));
        (function (Z) {
            Z.width = "100%";
            Z.height = "100%";
            Z.position = "relative";
            Z.left = "0px";
            Z.top = "0px";
            Z.textAlign = "left"
        }(this.container.style));
        this.container.insertBefore(this.canvas, this.container.firstChild);
        this.element.appendChild(this.container);
        this.bodyWidth = document.body.style.width;
        this.bodyHeight = document.body.style.height;
        this.bodyOverflow = document.body.style.overflow;
        this.docOverflow = document.documentElement.style.overflow;
        this.innerTracker = new C.MouseTracker({
            element: this.canvas,
            clickTimeThreshold: this.clickTimeThreshold,
            clickDistThreshold: this.clickDistThreshold,
            clickHandler: C.delegate(this, y),
            dragHandler: C.delegate(this, w),
            releaseHandler: C.delegate(this, B),
            scrollHandler: C.delegate(this, L)
        }).setTracking(this.mouseNavEnabled ? true : false);
        this.outerTracker = new C.MouseTracker({
            element: this.container,
            clickTimeThreshold: this.clickTimeThreshold,
            clickDistThreshold: this.clickDistThreshold,
            enterHandler: C.delegate(this, K),
            exitHandler: C.delegate(this, F),
            releaseHandler: C.delegate(this, r)
        }).setTracking(this.mouseNavEnabled ? true : false);
        if (this.toolbar) {
            this.toolbar = new C.ControlDock({element: this.toolbar})
        }
        this.bindStandardControls();
        this.bindSequenceControls();
        for (W = 0; W < this.customControls.length; W++) {
            this.addControl(this.customControls[W].id, this.customControls[W].anchor)
        }
        window.setTimeout(function () {
            u(Y)
        }, 1)
    };
    C.extend(C.Viewer.prototype, C.EventHandler.prototype, C.ControlDock.prototype, {
        isOpen: function () {
            return !!this.source
        }, openDzi: function (T) {
            var U = this;
            C.createFromDZI(T, function (V) {
                U.open(V)
            }, this.tileHost);
            return this
        }, openTileSource: function (X) {
            var Y = this, W, T, V, U;
            setTimeout(function () {
                if (C.type(X) == "string") {
                    X = new C.TileSource(X, function (Z) {
                        Y.open(Z)
                    })
                } else {
                    if (C.isPlainObject(X)) {
                        if (C.isFunction(X.getTileUrl)) {
                            W = new C.TileSource(X.width, X.height, X.tileSize, X.tileOverlap, X.minLevel, X.maxLevel);
                            W.getTileUrl = X.getTileUrl;
                            Y.open(W)
                        } else {
                            V = C.TileSource.determineType(Y, X);
                            U = V.prototype.configure.apply(Y, [X]);
                            T = new V(U);
                            Y.open(T)
                        }
                    } else {
                        Y.open(X)
                    }
                }
            }, 1);
            return this
        }, open: function (V) {
            var W = this, T, U;
            if (this.source) {
                this.close()
            }
            p[this.hash].lastOpenStartTime = +new Date();
            window.setTimeout(function () {
                if (p[W.hash].lastOpenStartTime > p[W.hash].lastOpenEndTime) {
                    p[W.hash].setMessage(C.getString("Messages.Loading"))
                }
            }, 2000);
            p[this.hash].lastOpenEndTime = +new Date();
            this.canvas.innerHTML = "";
            p[this.hash].prevContainerSize = C.getElementSize(this.container);
            if (V) {
                this.source = V
            }
            this.viewport = this.viewport ? this.viewport : new C.Viewport({
                containerSize: p[this.hash].prevContainerSize,
                contentSize: this.source.dimensions,
                springStiffness: this.springStiffness,
                animationTime: this.animationTime,
                minZoomImageRatio: this.minZoomImageRatio,
                maxZoomPixelRatio: this.maxZoomPixelRatio,
                visibilityRatio: this.visibilityRatio,
                wrapHorizontal: this.wrapHorizontal,
                wrapVertical: this.wrapVertical
            });
            if (this.preserveVewport) {
                this.viewport.resetContentSize(this.source.dimensions)
            } else {
                if (this.defaultZoomLevel) {
                    this.viewport.zoomTo(this.defaultZoomLevel, this.viewport.getCenter(), true)
                }
            }

            this.drawer = new C.Drawer({
                source: this.source,
                viewport: this.viewport,
                element: this.canvas,
                overlays: this.overlays,
                maxImageCacheCount: this.maxImageCacheCount,
                imageLoaderLimit: this.imageLoaderLimit,
                minZoomImageRatio: this.minZoomImageRatio,
                wrapHorizontal: this.wrapHorizontal,
                wrapVertical: this.wrapVertical,
                immediateRender: this.immediateRender,
                blendTime: this.blendTime,
                alwaysBlend: this.alwaysBlend,
                minPixelRatio: this.minPixelRatio,
                fastAnimations: this.fastAnimations
            });
            this.drawers.push(this.drawer);
            if (this.showNavigator && !this.navigator) {
                this.navigator = new C.Navigator({
                    id: this.navigatorElement,
                    position: this.navigatorPosition,
                    sizeRatio: this.navigatorSizeRatio,
                    height: this.navigatorHeight,
                    width: this.navigatorWidth,
                    tileSources: this.tileSources,
                    tileHost: this.tileHost,
                    prefixUrl: this.prefixUrl,
                    overlays: this.overlays,
                    viewer: this
                })
            }
            p[this.hash].animating = false;
            p[this.hash].forceRedraw = true;
            O(this, o);
            Q(this);
            for (U = 0; U < this.overlayControls.length; U++) {
                T = this.overlayControls[U];
                if (T.point != null) {
                    this.drawer.addOverlay(T.id, new C.Point(T.point.X, T.point.Y), C.OverlayPlacement.TOP_LEFT)
                } else {
                    this.drawer.addOverlay(T.id, new C.Rect(T.rect.Point.X, T.rect.Point.Y, T.rect.Width, T.rect.Height), T.placement)
                }
            }
            b[this.hash] = this;
            this.raiseEvent("open");
            if (this.navigator) {
                this.navigator.open(V)
            }
            return this
        }, close: function () {
            this.source = null;
            this.drawer = null;
            this.drawers = [];
            this.viewport = this.preserveViewport ? this.viewport : null;
            this.canvas.innerHTML = "";
            b[this.hash] = null;
            delete b[this.hash];
            return this
        }, isMouseNavEnabled: function () {
            return this.innerTracker.isTracking()
        }, setMouseNavEnabled: function (T) {
            this.innerTracker.setTracking(T);
            return this
        }, areControlsEnabled: function () {
            return this.controls.length && this.controls[i].isVisibile()
        }, setControlsEnabled: function (T) {
            if (T) {
                A(this)
            } else {
                u(this)
            }
        }, isFullPage: function () {
            return this.container.parentNode == document.body
        }, setFullPage: function (ae) {
            var ab = document.body, ac = ab.style, aa = document.documentElement.style, ad = this.container.style, W = this.canvas.style, T, V, Z, Y, U, X;
            if (ae == this.isFullPage()) {
                return
            }
            if (ae) {
                this.bodyOverflow = ac.overflow;
                this.docOverflow = aa.overflow;
                ac.overflow = "hidden";
                aa.overflow = "hidden";
                this.bodyWidth = ac.width;
                this.bodyHeight = ac.height;
                ac.width = "100%";
                ac.height = "100%";
                W.backgroundColor = "black";
                W.color = "white";
                ad.position = "fixed";
                this.previousBody = [];
                U = ab.childNodes.length;
                for (X = 0; X < U; X++) {
                    this.previousBody.push(ab.childNodes[0]);
                    ab.removeChild(ab.childNodes[0])
                }
                if (this.toolbar && this.toolbar.element) {
                    this.toolbar.parentNode = this.toolbar.element.parentNode;
                    this.toolbar.nextSibling = this.toolbar.element.nextSibling;
                    ab.appendChild(this.toolbar.element);
                    this.toolbar.element.setAttribute("class", this.toolbar.element.className + " fullpage");
                    this.toolbar.element.style.position = "fixed";
                    this.container.style.top = C.getElementSize(this.toolbar.element).y + "px"
                }
                ab.appendChild(this.container);
                p[this.hash].prevContainerSize = C.getWindowSize();
                C.delegate(this, K)()
            } else {
                ac.overflow = this.bodyOverflow;
                aa.overflow = this.docOverflow;
                ac.width = this.bodyWidth;
                ac.height = this.bodyHeight;
                W.backgroundColor = "";
                W.color = "";
                ad.position = "relative";
                ad.zIndex = "";
                if (this.toolbar && this.toolbar.element) {
                    ab.removeChild(this.toolbar.element);
                    this.toolbar.element.setAttribute("class", this.toolbar.element.className.replace("fullpage", ""));
                    this.toolbar.element.style.position = "relative";
                    this.toolbar.parentNode.insertBefore(this.toolbar.element, this.toolbar.nextSibling);
                    delete this.toolbar.parentNode;
                    delete this.toolbar.nextSibling;
                    this.container.style.top = "auto"
                }
                ab.removeChild(this.container);
                U = this.previousBody.length;
                for (X = 0; X < U; X++) {
                    ab.appendChild(this.previousBody.shift())
                }
                this.element.appendChild(this.container);
                p[this.hash].prevContainerSize = C.getElementSize(this.element);
                C.delegate(this, F)()
            }
            if (this.viewport) {
                T = this.viewport.getBounds();
                this.viewport.resize(p[this.hash].prevContainerSize);
                V = this.viewport.getBounds();

                if (ae) {
                    p[this.hash].fsBoundsDelta = new C.Point(V.width / T.width, V.height / T.height)
                } else {
                    this.viewport.update();
                    this.viewport.zoomBy(Math.max(p[this.hash].fsBoundsDelta.x, p[this.hash].fsBoundsDelta.y), null, true);
                    for (Y in b) {
                        Z = b[Y];
                        if (Z !== this && Z != this.navigator) {
                            Z.open(Z.source);
                            if (Z.navigator) {
                                Z.navigator.open(Z.source)
                            }
                        }
                    }
                }
                p[this.hash].forceRedraw = true;
                this.raiseEvent("resize", this);
                D(this)
            }
            return this
        }, isVisible: function () {
            return this.container.style.visibility != "hidden"
        }, setVisible: function (T) {
            this.container.style.visibility = T ? "" : "hidden";
            return this
        }, bindSequenceControls: function () {
            var Y = C.delegate(this, z), U = C.delegate(this, m), T = C.delegate(this, c), W = C.delegate(this, d), Z = this.navImages, V = [], X = true;
            if (this.showSequenceControl && p[this.hash].sequenced) {
                if (this.previousButton || this.nextButton) {
                    X = false
                }
                this.previousButton = new C.Button({
                    element: this.previousButton ? C.getElement(this.previousButton) : null,
                    clickTimeThreshold: this.clickTimeThreshold,
                    clickDistThreshold: this.clickDistThreshold,
                    tooltip: C.getString("Tooltips.PreviousPage"),
                    srcRest: G(this.prefixUrl, Z.previous.REST),
                    srcGroup: G(this.prefixUrl, Z.previous.GROUP),
                    srcHover: G(this.prefixUrl, Z.previous.HOVER),
                    srcDown: G(this.prefixUrl, Z.previous.DOWN),
                    onRelease: W,
                    onFocus: Y,
                    onBlur: U
                });
                this.nextButton = new C.Button({
                    element: this.nextButton ? C.getElement(this.nextButton) : null,
                    clickTimeThreshold: this.clickTimeThreshold,
                    clickDistThreshold: this.clickDistThreshold,
                    tooltip: C.getString("Tooltips.NextPage"),
                    srcRest: G(this.prefixUrl, Z.next.REST),
                    srcGroup: G(this.prefixUrl, Z.next.GROUP),
                    srcHover: G(this.prefixUrl, Z.next.HOVER),
                    srcDown: G(this.prefixUrl, Z.next.DOWN),
                    onRelease: T,
                    onFocus: Y,
                    onBlur: U
                });
                this.previousButton.disable();
                if (X) {
                    this.paging = new C.ButtonGroup({
                        buttons: [this.previousButton, this.nextButton],
                        clickTimeThreshold: this.clickTimeThreshold,
                        clickDistThreshold: this.clickDistThreshold
                    });
                    this.pagingControl = this.paging.element;
                    if (this.toolbar) {
                        this.toolbar.addControl(this.navControl, C.ControlAnchor.BOTTOM_RIGHT)
                    } else {
                        this.addControl(this.pagingControl, C.ControlAnchor.TOP_LEFT)
                    }
                }
            }
        }, bindStandardControls: function () {
            var ae = C.delegate(this, x), T = C.delegate(this, n), U = C.delegate(this, J), W = C.delegate(this, g), Z = C.delegate(this, v),
                aa = C.delegate(this, k), X = C.delegate(this, I), ad = C.delegate(this, z), V = C.delegate(this, m), ac = this.navImages, Y = [], ab = true;
            if (this.showNavigationControl) {
                if (this.zoomInButton || this.zoomOutButton || this.homeButton || this.fullPageButton) {
                    ab = false
                }
                Y.push(this.zoomInButton = new C.Button({
                    element: this.zoomInButton ? C.getElement(this.zoomInButton) : null,
                    clickTimeThreshold: this.clickTimeThreshold,
                    clickDistThreshold: this.clickDistThreshold,
                    tooltip: C.getString("Tooltips.ZoomIn"),
                    srcRest: G(this.prefixUrl, ac.zoomIn.REST),
                    srcGroup: G(this.prefixUrl, ac.zoomIn.GROUP),
                    srcHover: G(this.prefixUrl, ac.zoomIn.HOVER),
                    srcDown: G(this.prefixUrl, ac.zoomIn.DOWN),
                    onPress: ae,
                    onRelease: T,
                    onClick: U,
                    onEnter: ae,
                    onExit: T,
                    onFocus: ad,
                    onBlur: V
                }));
                Y.push(this.zoomOutButton = new C.Button({
                    element: this.zoomOutButton ? C.getElement(this.zoomOutButton) : null,
                    clickTimeThreshold: this.clickTimeThreshold,
                    clickDistThreshold: this.clickDistThreshold,
                    tooltip: C.getString("Tooltips.ZoomOut"),
                    srcRest: G(this.prefixUrl, ac.zoomOut.REST),
                    srcGroup: G(this.prefixUrl, ac.zoomOut.GROUP),
                    srcHover: G(this.prefixUrl, ac.zoomOut.HOVER),
                    srcDown: G(this.prefixUrl, ac.zoomOut.DOWN),
                    onPress: W,
                    onRelease: T,
                    onClick: Z,
                    onEnter: W,
                    onExit: T,
                    onFocus: ad,
                    onBlur: V
                }));
                Y.push(this.homeButton = new C.Button({
                    element: this.homeButton ? C.getElement(this.homeButton) : null,
                    clickTimeThreshold: this.clickTimeThreshold,
                    clickDistThreshold: this.clickDistThreshold,
                    tooltip: C.getString("Tooltips.Home"),
                    srcRest: G(this.prefixUrl, ac.home.REST),
                    srcGroup: G(this.prefixUrl, ac.home.GROUP),
                    srcHover: G(this.prefixUrl, ac.home.HOVER),
                    srcDown: G(this.prefixUrl, ac.home.DOWN),
                    onRelease: aa,
                    onFocus: ad,
                    onBlur: V
                }));
                Y.push(this.fullPageButton = new C.Button({
                    element: this.fullPageButton ? C.getElement(this.fullPageButton) : null,
                    clickTimeThreshold: this.clickTimeThreshold,
                    clickDistThreshold: this.clickDistThreshold,
                    tooltip: C.getString("Tooltips.FullPage"),
                    srcRest: G(this.prefixUrl, ac.fullpage.REST),
                    srcGroup: G(this.prefixUrl, ac.fullpage.GROUP),
                    srcHover: G(this.prefixUrl, ac.fullpage.HOVER),
                    srcDown: G(this.prefixUrl, ac.fullpage.DOWN),
                    onRelease: X,
                    onFocus: ad,
                    onBlur: V
                }));
                if (ab) {
                    this.buttons = new C.ButtonGroup({buttons: Y, clickTimeThreshold: this.clickTimeThreshold, clickDistThreshold: this.clickDistThreshold});
                    this.navControl = this.buttons.element;
                    this.addHandler("open", C.delegate(this, E));
                    if (this.toolbar) {
                        this.toolbar.addControl(this.navControl, C.ControlAnchor.TOP_LEFT)
                    } else {
                        this.addControl(this.navControl, C.ControlAnchor.BOTTOM_RIGHT)
                    }
                }
            }
        }, goToPage: function (T) {
            if (this.tileSources.length > T) {
                p[this.hash].sequence = T;
                if (this.nextButton) {
                    if ((this.tileSources.length - 1) === T) {
                        this.nextButton.disable()
                    } else {
                        this.nextButton.enable()
                    }
                }
                if (this.previousButton) {
                    if (T > 0) {
                        this.previousButton.enable()
                    } else {
                        this.previousButton.disable()
                    }
                }
                this.openTileSource(this.tileSources[T])
            }
            if (C.isFunction(this.onPageChange)) {
                this.onPageChange({page: T, viewer: this})
            }
        }, addLayer: function (U) {
            var T = new C.Drawer({
                source: U,
                viewport: this.viewport,
                element: this.canvas,
                maxImageCacheCount: this.maxImageCacheCount,
                imageLoaderLimit: this.imageLoaderLimit,
                minZoomImageRatio: this.minZoomImageRatio,
                wrapHorizontal: this.wrapHorizontal,
                wrapVertical: this.wrapVertical,
                immediateRender: this.immediateRender,
                blendTime: this.blendTime,
                alwaysBlend: this.alwaysBlend,
                minPixelRatio: this.minPixelRatio
            });
            this.drawers.push(T);
            D(this);
            return T.canvas
        }
    });
    var l = new Date, S;
    C.frameTime = 0;
    var e = 0;
    var h = false;

    function Q(T) {
        if (a) {
            window.setTimeout(function () {
                if (a && !h) {
                    a = false;
                    console.log("Attempting to restart animation loop");
                    if (e && P) {
                        P(e)
                    }
                    o(T)
                }
            }, 2000)
        }
    }

    function O(Y, U, W) {
        var V, Z, T;
        if (C.DEBUG_FPS) {
            var X = (S = new Date) - l;
            C.frameTime += (X - C.frameTime) / 20;
            l = S
        }
        if (a) {
            e = j(function () {
                h = true;
                U(Y)
            });
            return e
        }
        if (!Y.fastAnimations && p[Y.hash].animating) {
            return window.setTimeout(function () {
                U(Y)
            }, 1)
        }
        V = +new Date();
        W = W ? W : V;
        Z = W + 1000 / 60;
        T = Math.max(1, Z - V);
        return window.setTimeout(function () {
            U(Y)
        }, T)
    }

    function N(T) {
        window.setTimeout(function () {
            s(T)
        }, 20)
    }

    function u(T) {
        if (!T.autoHideControls) {
            return
        }
        T.controlsShouldFade = true;
        T.controlsFadeBeginTime = +new Date() + T.controlsFadeDelay;
        window.setTimeout(function () {
            N(T)
        }, T.controlsFadeDelay)
    }

    function s(X) {
        var W, T, U, V;
        if (X.controlsShouldFade) {
            W = new Date().getTime();
            T = W - X.controlsFadeBeginTime;
            U = 1 - T / X.controlsFadeLength;
            U = Math.min(1, U);
            U = Math.max(0, U);
            for (V = X.controls.length - 1; V >= 0; V--) {
                X.controls[V].setOpacity(U)
            }
            if (U > 0) {
                N(X)
            }
        }
    }

    function A(U) {
        var T;
        U.controlsShouldFade = false;
        for (T = U.controls.length - 1; T >= 0; T--) {
            U.controls[T].setOpacity(1)
        }
    }

    function z() {
        A(this)
    }

    function m() {
        u(this)
    }

    function y(X, T, Y, U) {
        var V, W;
        if (this.viewport && Y) {
            zoomPerClick = this.zoomPerClick;
            W = U ? 1 / zoomPerClick : zoomPerClick;
            this.viewport.zoomBy(W, this.viewport.pointFromPixel(T, true));
            this.viewport.applyConstraints()
        }
    }

    function w(V, T, W, U) {
        if (this.viewport) {
            if (!this.panHorizontal) {
                W.x = 0
            }
            if (!this.panVertical) {
                W.y = 0
            }
            this.viewport.panBy(this.viewport.deltaPointsFromPixels(W.negate()))
        }
    }

    function B(V, T, U, W) {
        if (U && this.viewport) {
            this.viewport.applyConstraints()
        }
    }

    function L(X, U, T, V) {
        var W;
        if (this.viewport) {
            W = Math.pow(this.zoomPerScroll, T);
            this.viewport.zoomBy(W, this.viewport.pointFromPixel(U, true));
            this.viewport.applyConstraints()
        }
        return false
    }

    function F(V, T, W, U) {
        if (!W) {
            p[this.hash].mouseInside = false;
            if (!p[this.hash].animating) {
                u(this)
            }
        }
    }

    function r(V, T, U, W) {
        if (!W) {
            p[this.hash].mouseInside = false;
            if (!p[this.hash].animating) {
                u(this)
            }
        }
    }

    function K(V, T, W, U) {
        p[this.hash].mouseInside = true;
        A(this)
    }

    function o(U) {
        var T;
        if (!U.source) {
            return
        }
        T = +new Date();
        D(U);
        O(U, arguments.callee, T)
    }

    function D(ab) {
        var U, Z, ac = ab.drawer.viewport;
        if (!ab.source) {
            return
        }
        U = C.getElementSize(ab.container);
        if (!U.equals(p[ab.hash].prevContainerSize)) {
            ab.viewport.resize(U, true);
            p[ab.hash].prevContainerSize = U;
            ab.raiseEvent("resize")
        }
        Z = ab.viewport.update();

        if (!p[ab.hash].animating && Z) {
            ab.raiseEvent("animationstart");
            A(ab);
            if (ab.fastAnimations) {
                if (ac.zoomSpring.current.value != ac.zoomSpring.target.value) {
                    p[ab.hash].startingZoom = ac.zoomSpring.start.value
                } else {
                    p[ab.hash].startingZoom = null
                }
                if ((ac.centerSpringX.current.value != ac.centerSpringX.target.value) || (ac.centerSpringY.current.value != ac.centerSpringY.target.value)) {
                    p[ab.hash].startingCenter = new C.Point(ab.drawer.viewport.centerSpringX.start.value, ab.drawer.viewport.centerSpringY.start.value)
                } else {
                    p[ab.hash].startingCenter = null
                }
            }
        }
        function aa() {
            var ag, af = ab.drawers.length;
            for (ag = 0; ag < af; ag += 1) {
                ab.drawers[ag].update()
            }
        }

        function Y() {
            var ag, af = ab.drawers.length;
            for (ag = 0; ag < af; ag += 1) {
                if (ab.drawers[ag].needsUpdate()) {
                    return true
                }
            }
            return false
        }

        function V(ag, af) {
            var ai, ah = ab.drawers.length;
            for (ai = 0; ai < ah; ai += 1) {
                ab.drawers[ai].canvas.style[t] = ag;
                ab.drawers[ai].canvas.style[q] = af
            }
        }

        if (Z) {
            if (ab.fastAnimations && M) {
                if (p[ab.hash].startingZoom) {
                    var ae = ac.getZoom(true) / p[ab.hash].startingZoom;
                    var W = "scale(" + ae.toFixed(8) + ")";
                    var ad = "center";
                    if (ac.zoomPoint) {
                        var T = ac.pixelFromPoint(ac.zoomPoint);
                        ad = (T.x).toFixed(0) + "px " + (T.y).toFixed(0) + "px"
                    }
                    V(W, ad)
                } else {
                    if (p[ab.hash].startingCenter) {
                        var X = ac.deltaPixelsFromPoints(ac.getCenter(true).minus(p[ab.hash].startingCenter));
                        var W = "translate(" + (-X.x).toFixed(0) + "px, " + (-X.y).toFixed(0) + "px)";
                        V(W, "center")
                    }
                }
            } else {
                aa()
            }

            if (ab.navigator) {
                ab.navigator.update(ab.viewport)
            }
            ab.raiseEvent("animation")
        } else {
            if (p[ab.hash].forceRedraw || Y()) {
                aa();
                if (ab.navigator) {
                    ab.navigator.update(ab.viewport)
                }
                p[ab.hash].forceRedraw = false
            }
        }
        if (p[ab.hash].animating && !Z) {
            ab.raiseEvent("animationfinish");
            if (ab.fastAnimations && M) {
                V(f, "center");
                aa()
            }
            if (!p[ab.hash].mouseInside) {
                u(ab)
            }
        }
        p[ab.hash].animating = Z
    }

    function G(U, T) {
        return U ? U + T : T
    }

    function x() {
        p[this.hash].lastZoomTime = +new Date();
        p[this.hash].zoomFactor = this.zoomPerSecond;
        p[this.hash].zooming = true;
        H(this)
    }

    function g() {
        p[this.hash].lastZoomTime = +new Date();
        p[this.hash].zoomFactor = 1 / this.zoomPerSecond;
        p[this.hash].zooming = true;
        H(this)
    }

    function n() {
        p[this.hash].zooming = false
    }

    function H(T) {
        window.setTimeout(C.delegate(T, R), 10)
    }

    function R() {
        var V, T, U;
        if (p[this.hash].zooming && this.viewport) {
            V = +new Date();
            T = V - p[this.hash].lastZoomTime;
            adjustedFactor = Math.pow(p[this.hash].zoomFactor, T / 1000);
            this.viewport.zoomBy(adjustedFactor);
            this.viewport.applyConstraints();
            p[this.hash].lastZoomTime = V;
            H(this)
        }
    }

    function J() {
        if (this.viewport) {
            p[this.hash].zooming = false;
            this.viewport.zoomBy(this.zoomPerClick / 1);
            this.viewport.applyConstraints()
        }
    }

    function v() {
        if (this.viewport) {
            p[this.hash].zooming = false;
            this.viewport.zoomBy(1 / this.zoomPerClick);
            this.viewport.applyConstraints()
        }
    }

    function E() {
        this.buttons.emulateEnter();
        this.buttons.emulateExit()
    }

    function k() {
        if (this.viewport) {
            this.viewport.goHome()
        }
    }

    function I() {
        this.setFullPage(!this.isFullPage());
        if (this.buttons) {
            this.buttons.emulateExit()
        }
        this.fullPageButton.element.focus();
        if (this.viewport) {
            this.viewport.applyConstraints()
        }
    }

    function d() {
        var T = p[this.hash].sequence - 1;
        this.goToPage(T)
    }

    function c() {
        var T = p[this.hash].sequence + 1;
        this.goToPage(T)
    }
}(OpenSeadragon));
(function (d) {
    d.Navigator = function (f) {
        var j = this, h = f.viewer, g = d.getElementSize(h.element);
        if (!f.id) {
            f.id = "navigator-" + (+new Date());
            this.element = d.makeNeutralElement("div");
            this.element.id = f.id;
            this.element.className = "navigator"
        }
        f = d.extend(true, {navigatorSizeRatio: d.DEFAULT_SETTINGS.navigatorSizeRatio}, f, {
            element: this.element,
            showNavigator: false,
            mouseNavEnabled: false,
            showNavigationControl: false,
            showSequenceControl: false
        });
        f.minPixelRatio = Math.min(f.navigatorSizeRatio * d.DEFAULT_SETTINGS.minPixelRatio, d.DEFAULT_SETTINGS.minPixelRatio);
        (function (k) {
            k.marginTop = "0px";
            k.marginRight = "0px";
            k.marginBottom = "0px";
            k.marginLeft = "0px";
            k.border = "2px solid #555";
            k.background = "#000";
            k.opacity = 0.8;
            k.overflow = "hidden"
        }(this.element.style));
        this.displayRegion = d.makeNeutralElement("textarea");
        this.displayRegion.id = this.element.id + "-displayregion";
        this.displayRegion.className = "displayregion";
        (function (k) {
            k.position = "relative";
            k.top = "0px";
            k.left = "0px";
            k.fontSize = "0px";
            k.border = "2px solid #900";
            try {
                k.outline = "2px auto #900"
            } catch (l) {
            }
            k.background = "transparent";
            k["float"] = "left";
            k.cssFloat = "left";
            k.styleFloat = "left";
            k.zIndex = 999999999;
            k.cursor = "default"
        }(this.displayRegion.style));
        this.element.innerTracker = new d.MouseTracker({
            element: this.element, scrollHandler: function () {
                return false
            }
        }).setTracking(true);
        this.displayRegion.innerTracker = new d.MouseTracker({
            element: this.displayRegion,
            clickTimeThreshold: this.clickTimeThreshold,
            clickDistThreshold: this.clickDistThreshold,
            clickHandler: d.delegate(this, b),
            dragHandler: d.delegate(this, c),
            releaseHandler: d.delegate(this, a),
            scrollHandler: d.delegate(this, e),
            focusHandler: function () {
                var k = d.getElementPosition(j.viewer.element);
                window.scrollTo(0, k.y);
                j.viewer.setControlsEnabled(true);
                (function (l) {
                    l.border = "2px solid #437AB2";
                    l.outline = "2px auto #437AB2"
                }(this.element.style))
            },
            blurHandler: function () {
                j.viewer.setControlsEnabled(false);
                (function (k) {
                    k.border = "2px solid #900";
                    k.outline = "2px auto #900"
                }(this.element.style))
            },
            keyHandler: function (l, m, k) {
                switch (m) {
                    case 61:
                        j.viewer.viewport.zoomBy(1.1);
                        j.viewer.viewport.applyConstraints();
                        return false;
                    case 45:
                        j.viewer.viewport.zoomBy(0.9);
                        j.viewer.viewport.applyConstraints();
                        return false;
                    case 48:
                        j.viewer.viewport.goHome();
                        j.viewer.viewport.applyConstraints();
                        return false;
                    case 119:
                    case 87:
                    case 38:
                        k ? j.viewer.viewport.zoomBy(1.1) : j.viewer.viewport.panBy(new d.Point(0, -0.05));
                        j.viewer.viewport.applyConstraints();
                        return false;
                    case 115:
                    case 83:
                    case 40:
                        k ? j.viewer.viewport.zoomBy(0.9) : j.viewer.viewport.panBy(new d.Point(0, 0.05));
                        j.viewer.viewport.applyConstraints();
                        return false;
                    case 97:
                    case 37:
                        j.viewer.viewport.panBy(new d.Point(-0.05, 0));
                        j.viewer.viewport.applyConstraints();
                        return false;
                    case 100:
                    case 39:
                        j.viewer.viewport.panBy(new d.Point(0.05, 0));
                        j.viewer.viewport.applyConstraints();
                        return false;
                    default:
                        return true
                }
            }
        }).setTracking(true);
        this.element.appendChild(this.displayRegion);
        h.addControl(this.element, d.ControlAnchor.TOP_RIGHT);
        if (f.width && f.height) {
            this.element.style.width = f.width + "px";
            this.element.style.height = f.height + "px"
        } else {
            this.element.style.width = (g.x * f.navigatorSizeRatio) + "px";
            this.element.style.height = (g.y * f.navigatorSizeRatio) + "px"
        }
        d.Viewer.apply(this, [f])
    };
    d.extend(d.Navigator.prototype, d.EventHandler.prototype, d.Viewer.prototype, {
        update: function (f) {
            var g, h, j;
            if (f && this.viewport) {
                g = f.getBounds(true);
                h = this.viewport.pixelFromPoint(g.getTopLeft());
                j = this.viewport.pixelFromPoint(g.getBottomRight());
                (function (k) {
                    k.top = h.y + "px";
                    k.left = h.x + "px";
                    k.width = (Math.abs(h.x - j.x) - 3) + "px";
                    k.height = (Math.abs(h.y - j.y) - 3) + "px"
                }(this.displayRegion.style))
            }
        }
    });
    function b(h, f, j, g) {
        this.displayRegion.focus()
    }

    function c(h, f, j, g) {
        if (this.viewer.viewport) {
            if (!this.panHorizontal) {
                j.x = 0
            }
            if (!this.panVertical) {
                j.y = 0
            }
            this.viewer.viewport.panBy(this.viewport.deltaPointsFromPixels(j))
        }
    }

    function a(h, f, g, j) {
        if (g && this.viewer.viewport) {
            this.viewer.viewport.applyConstraints()
        }
    }

    function e(k, g, f, h) {
        var j;
        if (this.viewer.viewport) {
            j = Math.pow(this.zoomPerScroll, f);
            this.viewer.viewport.zoomBy(j, this.viewport.getCenter());
            this.viewer.viewport.applyConstraints()
        }
        return false
    }
}(OpenSeadragon));
(function (b) {
    var a = {
        Errors: {
            Failure: "Sorry, but Seadragon Ajax can't run on your browser!\nPlease try using IE 7 or Firefox 3.\n",
            Dzc: "Sorry, we don't support Deep Zoom Collections!",
            Dzi: "Hmm, this doesn't appear to be a valid Deep Zoom Image.",
            Xml: "Hmm, this doesn't appear to be a valid Deep Zoom Image.",
            Empty: "You asked us to open nothing, so we did just that.",
            ImageFormat: "Sorry, we don't support {0}-based Deep Zoom Images.",
            Security: "It looks like a security restriction stopped us from loading this Deep Zoom Image.",
            Status: "This space unintentionally left blank ({0} {1}).",
            Unknown: "Whoops, something inexplicably went wrong. Sorry!"
        },
        Messages: {Loading: "Loading..."},
        Tooltips: {FullPage: "Toggle full page", Home: "Go home", ZoomIn: "Zoom in", ZoomOut: "Zoom out", NextPage: "Next page", PreviousPage: "Previous page"}
    };
    b.extend(b, {
        getString: function (g) {
            var f = g.split("."), d = null, c = arguments, e;
            for (e = 0; e < f.length; e++) {
                d = a[f[e]] || {}
            }
            if (typeof(d) != "string") {
                d = ""
            }
            return d.replace(/\{\d+\}/g, function (h) {
                var j = parseInt(h.match(/\d+/)) + 1;
                return j < c.length ? c[j] : ""
            })
        }, setString: function (g, f) {
            var e = g.split("."), c = b.Strings, d;
            for (d = 0; d < e.length - 1; d++) {
                if (!c[e[d]]) {
                    c[e[d]] = {}
                }
                c = c[e[d]]
            }
            c[e[d]] = f
        }
    })
}(OpenSeadragon));
(function (a) {
    a.Point = function (b, c) {
        this.x = typeof(b) == "number" ? b : 0;
        this.y = typeof(c) == "number" ? c : 0
    };
    a.Point.prototype = {
        plus: function (b) {
            return new a.Point(this.x + b.x, this.y + b.y)
        }, minus: function (b) {
            return new a.Point(this.x - b.x, this.y - b.y)
        }, times: function (b) {
            return new a.Point(this.x * b, this.y * b)
        }, divide: function (b) {
            return new a.Point(this.x / b, this.y / b)
        }, negate: function () {
            return new a.Point(-this.x, -this.y)
        }, distanceTo: function (b) {
            return Math.sqrt(Math.pow(this.x - b.x, 2) + Math.pow(this.y - b.y, 2))
        }, apply: function (b) {
            return new a.Point(b(this.x), b(this.y))
        }, equals: function (b) {
            return (b instanceof a.Point) && (this.x === b.x) && (this.y === b.y)
        }, toString: function () {
            return "(" + this.x + "," + this.y + ")"
        }
    }
}(OpenSeadragon));
(function ($) {
    $.TileSource = function (width, height, tileSize, tileOverlap, minLevel, maxLevel) {
        var _this = this, callback = null, readyHandler = null, args = arguments, options, i;
        if ($.isPlainObject(width)) {
            options = width
        } else {
            options = {width: args[0], height: args[1], tileSize: args[2], tileOverlap: args[3], minlevel: args[4], maxLevel: args[5]}
        }
        $.EventHandler.call(this);
        $.extend(true, this, options);
        for (i = 0; i < arguments.length; i++) {
            if ($.isFunction(arguments[i])) {
                callback = arguments[i];
                this.addHandler("ready", function (placeHolderSource, readySource) {
                    callback(readySource)
                });
                break
            }
        }
        if ("string" == $.type(arguments[0])) {
            this.aspectRatio = 1;
            this.dimensions = new $.Point(10, 10);
            this.tileSize = 0;
            this.tileOverlap = 0;
            this.minLevel = 0;
            this.maxLevel = 0;
            this.ready = false;
            this.getImageInfo(arguments[0])
        } else {
            this.ready = true;
            this.aspectRatio = (options.width && options.height) ? (options.width / options.height) : 1;
            this.dimensions = new $.Point(options.width, options.height);
            this.tileSize = options.tileSize ? options.tileSize : 0;
            this.tileOverlap = options.tileOverlap ? options.tileOverlap : 0;
            this.minLevel = options.minLevel ? options.minLevel : 0;
            this.maxLevel = options.maxLevel ? options.maxLevel : ((options.width && options.height) ? Math.ceil(Math.log(Math.max(options.width, options.height)) / Math.log(2)) : 0);
            if (callback && $.isFunction(callback)) {
                callback(this)
            }
        }
    };
    $.TileSource.prototype = {
        getLevelScale: function (level) {
            return 1 / (1 << (this.maxLevel - level))
        }, getNumTiles: function (level) {
            var scale = this.getLevelScale(level), x = Math.ceil(scale * this.dimensions.x / this.tileSize),
                y = Math.ceil(scale * this.dimensions.y / this.tileSize);
            return new $.Point(x, y)
        }, getPixelRatio: function (level) {
            var imageSizeScaled = this.dimensions.times(this.getLevelScale(level)), rx = 1 / imageSizeScaled.x, ry = 1 / imageSizeScaled.y;
            return new $.Point(rx, ry)
        }, getTileAtPoint: function (level, point) {
            var pixel = point.times(this.dimensions.x).times(this.getLevelScale(level)), tx = Math.floor(pixel.x / this.tileSize),
                ty = Math.floor(pixel.y / this.tileSize);
            return new $.Point(tx, ty)
        }, getTileBounds: function (level, x, y) {
            var dimensionsScaled = this.dimensions.times(this.getLevelScale(level)), px = (x === 0) ? 0 : this.tileSize * x - this.tileOverlap,
                py = (y === 0) ? 0 : this.tileSize * y - this.tileOverlap, sx = this.tileSize + (x === 0 ? 1 : 2) * this.tileOverlap,
                sy = this.tileSize + (y === 0 ? 1 : 2) * this.tileOverlap, scale = 1 / dimensionsScaled.x;
            sx = Math.min(sx, dimensionsScaled.x - px);
            sy = Math.min(sy, dimensionsScaled.y - py);
            return new $.Rect(px * scale, py * scale, sx * scale, sy * scale)
        }, getImageInfo: function (url) {
            var _this = this, url = url, error, callbackName, callback, readySource, options, urlParts, filename, lastDot, tilesUrl;
            if (url) {
                urlParts = url.split("/");
                filename = urlParts[urlParts.length - 1];
                lastDot = filename.lastIndexOf(".");
                if (lastDot > -1) {
                    urlParts[urlParts.length - 1] = filename.slice(0, lastDot)
                }
            }
            callback = function (data) {
                var $TileSource = $.TileSource.determineType(_this, data, url);
                options = $TileSource.prototype.configure.apply(_this, [data, url]);
                readySource = new $TileSource(options);
                _this.ready = true;
                _this.raiseEvent("ready", readySource)
            };
            if (url.match(/\.js$/)) {
                callbackName = url.split("/").pop().replace(".js", "");
                $.jsonp({url: url, async: false, callbackName: callbackName, callback: callback})
            } else {
                $.makeAjaxRequest(url, function (xhr) {
                    var data = processResponse(xhr);
                    callback(data)
                })
            }
        }, supports: function (data, url) {
            return false
        }, configure: function (data, url) {
            throw new Error("Method not implemented.")
        }, getTileUrl: function (level, x, y) {
            throw new Error("Method not implemented.")
        }, tileExists: function (level, x, y) {
            var numTiles = this.getNumTiles(level);
            return level >= this.minLevel && level <= this.maxLevel && x >= 0 && y >= 0 && x < numTiles.x && y < numTiles.y
        }
    };
    $.extend(true, $.TileSource.prototype, $.EventHandler.prototype);
    function processResponse(xhr) {
        var responseText = xhr.responseText, status = xhr.status, statusText, data;
        if (!xhr) {
            throw new Error($.getString("Errors.Security"))
        } else {
            if (xhr.status !== 200 && xhr.status !== 0) {
                status = xhr.status;
                statusText = (status == 404) ? "Not Found" : xhr.statusText;
                throw new Error($.getString("Errors.Status", status, statusText))
            }
        }
        if (responseText.match(/\s*<.*/)) {
            try {
                data = (xhr.responseXML && xhr.responseXML.documentElement) ? xhr.responseXML : $.parseXml(responseText)
            } catch (e) {
                data = xhr.responseText
            }
        } else {
            if (responseText.match(/\s*[\{\[].*/)) {
                data = eval(responseText)
            } else {
                data = responseText
            }
        }
        return data
    }

    $.TileSource.determineType = function (tileSource, data, url) {
        var property;
        for (property in OpenSeadragon) {
            if (property.match(/.+TileSource$/) && $.isFunction(OpenSeadragon[property]) && $.isFunction(OpenSeadragon[property].prototype.supports) && OpenSeadragon[property].prototype.supports.call(tileSource, data, url)) {
                return OpenSeadragon[property]
            }
        }
    }
}(OpenSeadragon));
(function (c) {
    c.DziTileSource = function (f, o, p, l, m, h, k, d, n) {
        var g, j, e, q;
        if (c.isPlainObject(f)) {
            q = f
        } else {
            q = {
                width: arguments[0],
                height: arguments[1],
                tileSize: arguments[2],
                tileOverlap: arguments[3],
                tilesUrl: arguments[4],
                fileFormat: arguments[5],
                dispRects: arguments[6],
                minLevel: arguments[7],
                maxLevel: arguments[8]
            }
        }
        this._levelRects = {};
        this.tilesUrl = q.tilesUrl;
        this.fileFormat = q.fileFormat;
        this.dispRects = q.dispRects;
        if (this.dispRects) {
            for (g = this.dispRects.length - 1; g >= 0; g--) {
                j = this.dispRects[g];
                for (e = j.minLevel; e <= j.maxLevel; e++) {
                    if (!this._levelRects[e]) {
                        this._levelRects[e] = []
                    }
                    this._levelRects[e].push(j)
                }
            }
        }
        c.TileSource.apply(this, [q])
    };
    c.extend(c.DziTileSource.prototype, c.TileSource.prototype, {
        supports: function (e, d) {
            return (e.Image && "http://schemas.microsoft.com/deepzoom/2008" == e.Image.xmlns) || (e.documentElement && "Image" == e.documentElement.tagName && "http://schemas.microsoft.com/deepzoom/2008" == e.documentElement.namespaceURI)
        }, configure: function (k, j) {
            var h, e, d, f, g;
            if (k instanceof XMLDocument) {
                f = b(this, k)
            } else {
                if ("object" == c.type(k)) {
                    f = a(this, k)
                }
            }
            if (j && !f.tilesUrl) {
                if (!("http" == j.substring(0, 4))) {
                    g = location.protocol + "//" + location.host
                }
                h = j.split("/");
                e = h.pop();
                e = e.substring(0, e.indexOf("."));
                h = "/" + h.join("/") + "/" + e + "_files/";
                d = h;
                if (g) {
                    d = g + d
                }
                f.tilesUrl = d
            }
            return f
        }, getTileUrl: function (f, d, e) {
            return [this.tilesUrl, f, "/", d, "_", e, ".", this.fileFormat].join("")
        }, tileExists: function (d, m, k) {
            var o = this._levelRects[d], l, g, e, n, j, f, h;
            if (!o || !o.length) {
                return true
            }
            for (h = o.length - 1; h >= 0; h--) {
                l = o[h];
                if (d < l.minLevel || d > l.maxLevel) {
                    continue
                }
                g = this.getLevelScale(d);
                e = l.x * g;
                n = l.y * g;
                j = e + l.width * g;
                f = n + l.height * g;
                e = Math.floor(e / this.tileSize);
                n = Math.floor(n / this.tileSize);
                j = Math.ceil(j / this.tileSize);
                f = Math.ceil(f / this.tileSize);
                if (e <= m && m < j && n <= k && k < f) {
                    return true
                }
            }
            return false
        }
    });
    function b(m, q) {
        if (!q || !q.documentElement) {
            throw new Error(c.getString("Errors.Xml"))
        }
        var o = q.documentElement, d = o.tagName, l = null, n = [], k, p, f, j, g;
        if (d == "Image") {
            try {
                j = o.getElementsByTagName("Size")[0];
                l = {
                    Image: {
                        xmlns: "http://schemas.microsoft.com/deepzoom/2008",
                        Format: o.getAttribute("Format"),
                        DisplayRect: null,
                        Overlap: parseInt(o.getAttribute("Overlap")),
                        TileSize: parseInt(o.getAttribute("TileSize")),
                        Size: {Height: parseInt(j.getAttribute("Height")), Width: parseInt(j.getAttribute("Width"))}
                    }
                };
                if (!c.imageFormatSupported(l.Image.Format)) {
                    throw new Error(c.getString("Errors.ImageFormat", l.Image.Format.toUpperCase()))
                }
                k = o.getElementsByTagName("DisplayRect");
                for (g = 0; g < k.length; g++) {
                    p = k[g];
                    f = p.getElementsByTagName("Rect")[0];
                    n.push({
                        Rect: {
                            X: parseInt(f.getAttribute("X")),
                            Y: parseInt(f.getAttribute("Y")),
                            Width: parseInt(f.getAttribute("Width")),
                            Height: parseInt(f.getAttribute("Height")),
                            MinLevel: 0,
                            MaxLevel: parseInt(p.getAttribute("MaxLevel"))
                        }
                    })
                }
                if (n.length) {
                    l.Image.DisplayRect = n
                }
                return a(m, l)
            } catch (h) {
                throw (h instanceof Error) ? h : new Error(c.getString("Errors.Dzi"))
            }
        } else {
            if (d == "Collection") {
                throw new Error(c.getString("Errors.Dzc"))
            } else {
                if (d == "Error") {
                    return processDZIError(o)
                }
            }
        }
        throw new Error(c.getString("Errors.Dzi"))
    }

    function a(k, f) {
        var d = f.Image, m = d.Url, j = d.Format, n = d.Size, r = d.DisplayRect || [], e = parseInt(n.Width), o = parseInt(n.Height), q = parseInt(d.TileSize),
            l = parseInt(d.Overlap), p = [], g, h;
        for (h = 0; h < r.length; h++) {
            g = r[h].Rect;
            p.push(new c.DisplayRect(parseInt(g.X), parseInt(g.Y), parseInt(g.Width), parseInt(g.Height), 0, parseInt(g.MaxLevel)))
        }
        return {width: e, height: o, tileSize: q, tileOverlap: l, minLevel: null, maxLevel: null, tilesUrl: m, fileFormat: j, dispRects: p}
    }
}(OpenSeadragon));
(function (c) {
    c.LegacyTileSource = function (h) {
        var f, g, e;
        if (c.isArray(h)) {
            f = {type: "legacy-image-pyramid", levels: h}
        }
        f.levels = d(f.levels);
        g = f.levels[f.levels.length - 1].width;
        e = f.levels[f.levels.length - 1].height;
        c.extend(true, f, {width: g, height: e, tileSize: Math.max(e, g), tileOverlap: 0, minLevel: 0, maxLevel: f.levels.length - 1});
        c.TileSource.apply(this, [f]);
        this.levels = f.levels
    };
    c.LegacyTileSource.prototype = {
        supports: function (f, e) {
            return (f.type && "legacy-image-pyramid" == f.type) || (f.documentElement && "legacy-image-pyramid" == f.documentElement.getAttribute("type"))
        }, configure: function (g, f) {
            var e;
            if (g instanceof XMLDocument) {
                e = b(this, g)
            } else {
                if ("object" == c.type(g)) {
                    e = a(this, g)
                }
            }
            return e
        }, getLevelScale: function (f) {
            var e = NaN;
            if (f >= this.minLevel && f <= this.maxLevel) {
                e = this.levels[f].width / this.levels[this.maxLevel].width
            }
            return e
        }, getNumTiles: function (f) {
            var e = this.getLevelScale(f);
            if (e) {
                return new c.Point(1, 1)
            } else {
                return new c.Point(0, 0)
            }
        }, getPixelRatio: function (h) {
            var g = this.dimensions.times(this.getLevelScale(h)), f = 1 / g.x, e = 1 / g.y;
            return new c.Point(f, e)
        }, getTileAtPoint: function (f, e) {
            return new c.Point(0, 0)
        }, getTileBounds: function (e, m, h) {
            var f = this.dimensions.times(this.getLevelScale(e)), n = (m === 0) ? 0 : this.levels[e].width, l = (h === 0) ? 0 : this.levels[e].height,
                k = this.levels[e].width, j = this.levels[e].height, g = 1 / (this.width >= this.height ? f.y : f.x);
            k = Math.min(k, f.x - n);
            j = Math.min(j, f.y - l);
            return new c.Rect(n * g, l * g, k * g, j * g)
        }, getTileUrl: function (h, e, g) {
            var f = null;
            if (h >= this.minLevel && h <= this.maxLevel) {
                f = this.levels[h].url
            }
            return f
        }, tileExists: function (h, e, g) {
            var f = this.getNumTiles(h);
            return h >= this.minLevel && h <= this.maxLevel && e >= 0 && g >= 0 && e < f.x && g < f.y
        }
    };
    function d(h) {
        var e = [], g, f;
        for (f = 0; f < h.length; f++) {
            g = h[f];
            if (g.height && g.width && g.url && (g.url.toLowerCase().match(/^.*\.(png|jpg|jpeg|gif)$/) || (g.mimetype && g.mimetype.toLowerCase().match(/^.*\/(png|jpg|jpeg|gif)$/)))) {
                e.push({url: g.url, width: Number(g.width), height: Number(g.height)})
            }
        }
        return e.sort(function (k, j) {
            return k.height - j.height
        })
    }

    function b(l, o) {
        if (!o || !o.documentElement) {
            throw new Error(c.getString("Errors.Xml"))
        }
        var m = o.documentElement, f = m.tagName, k = null, n = [], g, h;
        if (f == "image") {
            try {
                k = {type: m.getAttribute("type"), levels: []};
                n = m.getElementsByTagName("level");
                for (h = 0; h < n.length; h++) {
                    g = n[h];
                    k.levels.push({url: g.getAttribute("url"), width: parseInt(g.getAttribute("width")), height: parseInt(g.getAttribute("height"))})
                }
                return a(l, k)
            } catch (j) {
                throw (j instanceof Error) ? j : new Error("Unknown error parsing Legacy Image Pyramid XML.")
            }
        } else {
            if (f == "collection") {
                throw new Error("Legacy Image Pyramid Collections not yet supported.")
            } else {
                if (f == "error") {
                    throw new Error("Error: " + o)
                }
            }
        }
        throw new Error("Unknown element " + f)
    }

    function a(e, f) {
        return f.levels
    }
}(OpenSeadragon));
(function (g) {
    g.ButtonState = {REST: 0, GROUP: 1, HOVER: 2, DOWN: 3};
    g.Button = function (h) {
        var j = this;
        g.EventHandler.call(this);
        g.extend(true, this, {
            tooltip: null,
            srcRest: null,
            srcGroup: null,
            srcHover: null,
            srcDown: null,
            clickTimeThreshold: g.DEFAULT_SETTINGS.clickTimeThreshold,
            clickDistThreshold: g.DEFAULT_SETTINGS.clickDistThreshold,
            fadeDelay: 0,
            fadeLength: 2000,
            onPress: null,
            onRelease: null,
            onClick: null,
            onEnter: null,
            onExit: null,
            onFocus: null,
            onBlur: null
        }, h);
        this.element = h.element || g.makeNeutralElement("button");
        this.element.href = this.element.href || "#";
        if (!h.element) {
            this.imgRest = g.makeTransparentImage(this.srcRest);
            this.imgGroup = g.makeTransparentImage(this.srcGroup);
            this.imgHover = g.makeTransparentImage(this.srcHover);
            this.imgDown = g.makeTransparentImage(this.srcDown);
            this.element.appendChild(this.imgRest);
            this.element.appendChild(this.imgGroup);
            this.element.appendChild(this.imgHover);
            this.element.appendChild(this.imgDown);
            this.imgGroup.style.position = this.imgHover.style.position = this.imgDown.style.position = "absolute";
            this.imgGroup.style.top = this.imgHover.style.top = this.imgDown.style.top = "0px";
            this.imgGroup.style.left = this.imgHover.style.left = this.imgDown.style.left = "0px";
            this.imgHover.style.visibility = this.imgDown.style.visibility = "hidden";
            if (g.Browser.vendor == g.BROWSERS.FIREFOX && g.Browser.version < 3) {
                this.imgGroup.style.top = this.imgHover.style.top = this.imgDown.style.top = ""
            }
        }
        this.addHandler("onPress", this.onPress);
        this.addHandler("onRelease", this.onRelease);
        this.addHandler("onClick", this.onClick);
        this.addHandler("onEnter", this.onEnter);
        this.addHandler("onExit", this.onExit);
        this.addHandler("onFocus", this.onFocus);
        this.addHandler("onBlur", this.onBlur);
        this.currentState = g.ButtonState.GROUP;
        this.fadeBeginTime = null;
        this.shouldFade = false;
        this.element.style.display = "inline-block";
        this.element.style.position = "relative";
        this.element.title = this.tooltip;
        this.tracker = new g.MouseTracker({
            element: this.element,
            clickTimeThreshold: this.clickTimeThreshold,
            clickDistThreshold: this.clickDistThreshold,
            enterHandler: function (m, k, n, l) {
                if (n) {
                    d(j, g.ButtonState.DOWN);
                    j.raiseEvent("onEnter", j)
                } else {
                    if (!l) {
                        d(j, g.ButtonState.HOVER)
                    }
                }
            },
            focusHandler: function (m, k, n, l) {
                this.enterHandler(m, k, n, l);
                j.raiseEvent("onFocus", j)
            },
            exitHandler: function (m, k, n, l) {
                f(j, g.ButtonState.GROUP);
                if (n) {
                    j.raiseEvent("onExit", j)
                }
            },
            blurHandler: function (m, k, n, l) {
                this.exitHandler(m, k, n, l);
                j.raiseEvent("onBlur", j)
            },
            pressHandler: function (l, k) {
                d(j, g.ButtonState.DOWN);
                j.raiseEvent("onPress", j)
            },
            releaseHandler: function (m, k, l, n) {
                if (l && n) {
                    f(j, g.ButtonState.HOVER);
                    j.raiseEvent("onRelease", j)
                } else {
                    if (l) {
                        f(j, g.ButtonState.GROUP)
                    } else {
                        d(j, g.ButtonState.HOVER)
                    }
                }
            },
            clickHandler: function (m, k, n, l) {
                if (n) {
                    j.raiseEvent("onClick", j)
                }
            },
            keyHandler: function (l, k) {
                if (13 === k) {
                    j.raiseEvent("onClick", j);
                    j.raiseEvent("onRelease", j);
                    return false
                }
                return true
            }
        }).setTracking(true);
        f(this, g.ButtonState.REST)
    };
    g.extend(g.Button.prototype, g.EventHandler.prototype, {
        notifyGroupEnter: function () {
            d(this, g.ButtonState.GROUP)
        }, notifyGroupExit: function () {
            f(this, g.ButtonState.REST)
        }, disable: function () {
            this.notifyGroupExit();
            this.element.disabled = true;
            g.setElementOpacity(this.element, 0.2, true)
        }, enable: function () {
            this.element.disabled = false;
            g.setElementOpacity(this.element, 1, true);
            this.notifyGroupEnter()
        }
    });
    function c(h) {
        window.setTimeout(function () {
            e(h)
        }, 20)
    }

    function e(k) {
        var l, h, j;
        if (k.shouldFade) {
            l = +new Date();
            h = l - k.fadeBeginTime;
            j = 1 - h / k.fadeLength;
            j = Math.min(1, j);
            j = Math.max(0, j);
            if (k.imgGroup) {
                g.setElementOpacity(k.imgGroup, j, true)
            }
            if (j > 0) {
                c(k)
            }
        }
    }

    function b(h) {
        h.shouldFade = true;
        h.fadeBeginTime = +new Date() + h.fadeDelay;
        window.setTimeout(function () {
            c(h)
        }, h.fadeDelay)
    }

    function a(h) {
        h.shouldFade = false;
        if (h.imgGroup) {
            g.setElementOpacity(h.imgGroup, 1, true)
        }
    }

    function d(h, j) {
        if (h.element.disabled) {
            return
        }
        if (j >= g.ButtonState.GROUP && h.currentState == g.ButtonState.REST) {
            a(h);
            h.currentState = g.ButtonState.GROUP
        }
        if (j >= g.ButtonState.HOVER && h.currentState == g.ButtonState.GROUP) {
            if (h.imgHover) {
                h.imgHover.style.visibility = ""
            }
            h.currentState = g.ButtonState.HOVER
        }
        if (j >= g.ButtonState.DOWN && h.currentState == g.ButtonState.HOVER) {
            if (h.imgDown) {
                h.imgDown.style.visibility = ""
            }
            h.currentState = g.ButtonState.DOWN
        }
    }

    function f(h, j) {
        if (h.element.disabled) {
            return
        }
        if (j <= g.ButtonState.HOVER && h.currentState == g.ButtonState.DOWN) {
            if (h.imgDown) {
                h.imgDown.style.visibility = "hidden"
            }
            h.currentState = g.ButtonState.HOVER
        }
        if (j <= g.ButtonState.GROUP && h.currentState == g.ButtonState.HOVER) {
            if (h.imgHover) {
                h.imgHover.style.visibility = "hidden"
            }
            h.currentState = g.ButtonState.GROUP
        }
        if (j <= g.ButtonState.REST && h.currentState == g.ButtonState.GROUP) {
            b(h);
            h.currentState = g.ButtonState.REST
        }
    }
}(OpenSeadragon));
(function (a) {
    a.ButtonGroup = function (b) {
        a.extend(true, this, {
            buttons: [],
            clickTimeThreshold: a.DEFAULT_SETTINGS.clickTimeThreshold,
            clickDistThreshold: a.DEFAULT_SETTINGS.clickDistThreshold,
            labelText: ""
        }, b);
        var d = this.buttons.concat([]), e = this, c;
        this.element = b.element || a.makeNeutralElement("fieldgroup");
        if (!b.group) {
            this.label = a.makeNeutralElement("label");
            this.element.style.display = "inline-block";
            this.element.appendChild(this.label);
            for (c = 0; c < d.length; c++) {
                this.element.appendChild(d[c].element)
            }
        }
        this.tracker = new a.MouseTracker({
            element: this.element,
            clickTimeThreshold: this.clickTimeThreshold,
            clickDistThreshold: this.clickDistThreshold,
            enterHandler: function () {
                var f;
                for (f = 0; f < e.buttons.length; f++) {
                    e.buttons[f].notifyGroupEnter()
                }
            },
            exitHandler: function () {
                var f, g = arguments.length > 2 ? arguments[2] : null;
                if (!g) {
                    for (f = 0; f < e.buttons.length; f++) {
                        e.buttons[f].notifyGroupExit()
                    }
                }
            },
            releaseHandler: function () {
                var f, g = arguments.length > 3 ? arguments[3] : null;
                if (!g) {
                    for (f = 0; f < e.buttons.length; f++) {
                        e.buttons[f].notifyGroupExit()
                    }
                }
            }
        }).setTracking(true)
    };
    a.ButtonGroup.prototype = {
        emulateEnter: function () {
            this.tracker.enterHandler()
        }, emulateExit: function () {
            this.tracker.exitHandler()
        }
    }
}(OpenSeadragon));
(function (a) {
    a.Rect = function (c, e, d, b) {
        this.x = typeof(c) == "number" ? c : 0;
        this.y = typeof(e) == "number" ? e : 0;
        this.width = typeof(d) == "number" ? d : 0;
        this.height = typeof(b) == "number" ? b : 0
    };
    a.Rect.prototype = {
        getAspectRatio: function () {
            return this.width / this.height
        }, getTopLeft: function () {
            return new a.Point(this.x, this.y)
        }, getBottomRight: function () {
            return new a.Point(this.x + this.width, this.y + this.height)
        }, getCenter: function () {
            return new a.Point(this.x + this.width / 2, this.y + this.height / 2)
        }, getSize: function () {
            return new a.Point(this.width, this.height)
        }, equals: function (b) {
            return (b instanceof a.Rect) && (this.x === b.x) && (this.y === b.y) && (this.width === b.width) && (this.height === b.height)
        }, toString: function () {
            return "[" + this.x + "," + this.y + "," + this.width + "x" + this.height + "]"
        }
    }
}(OpenSeadragon));
(function (a) {
    a.DisplayRect = function (d, g, e, c, f, b) {
        a.Rect.apply(this, [d, g, e, c]);
        this.minLevel = f;
        this.maxLevel = b
    };
    a.extend(a.DisplayRect.prototype, a.Rect.prototype)
}(OpenSeadragon));
(function (b) {
    b.Spring = function (d) {
        var c = arguments;
        if (typeof(d) != "object") {
            d = {
                initial: c.length && typeof(c[0]) == "number" ? c[0] : 0,
                springStiffness: c.length > 1 ? c[1].springStiffness : 5,
                animationTime: c.length > 1 ? c[1].animationTime : 1.5
            }
        }
        b.extend(true, this, d);
        this.current = {value: typeof(this.initial) == "number" ? this.initial : 0, time: new Date().getTime()};
        this.start = {value: this.current.value, time: this.current.time};
        this.target = {value: this.current.value, time: this.current.time}
    };
    b.Spring.prototype = {
        resetTo: function (c) {
            this.target.value = c;
            this.target.time = this.current.time;
            this.start.value = this.target.value;
            this.start.time = this.target.time
        }, springTo: function (c) {
            this.start.value = this.current.value;
            this.start.time = this.current.time;
            this.target.value = c;
            this.target.time = this.start.time + 1000 * this.animationTime
        }, shiftBy: function (c) {
            this.start.value += c;
            this.target.value += c
        }, update: function () {
            this.current.time = new Date().getTime();
            this.current.value = (this.current.time >= this.target.time) ? this.target.value : this.start.value + (this.target.value - this.start.value) * a(this.springStiffness, (this.current.time - this.start.time) / (this.target.time - this.start.time))
        }
    };
    function a(d, c) {
        return (1 - Math.exp(d * -c)) / (1 - Math.exp(-d))
    }
}(OpenSeadragon));
(function (a) {
    a.Tile = function (g, b, f, e, d, c) {
        this.level = g;
        this.x = b;
        this.y = f;
        this.bounds = e;
        this.exists = d;
        this.url = c;
        this.loaded = false;
        this.loading = false;
        this.element = null;
        this.image = null;
        this.style = null;
        this.position = null;
        this.size = null;
        this.blendStart = null;
        this.opacity = null;
        this.distance = null;
        this.visibility = null;
        this.beingDrawn = false;
        this.lastTouchTime = 0
    };
    a.Tile.prototype = {
        toString: function () {
            return this.level + "/" + this.x + "_" + this.y
        }, drawHTML: function (c) {
            var b = this.position.apply(Math.floor), d = this.size.apply(Math.ceil);
            if (!this.loaded || !this.image) {
                a.console.warn("Attempting to draw tile %s when it's not yet loaded.", this.toString());
                return
            }
            if (!this.element) {
                this.element = a.makeNeutralElement("img");
                this.element.src = this.url;
                this.style = this.element.style;
                this.style.position = "absolute";
                this.style.msInterpolationMode = "nearest-neighbor"
            }
            if (this.element.parentNode != c) {
                c.appendChild(this.element)
            }
            this.element.style.left = b.x + "px";
            this.element.style.top = b.y + "px";
            this.element.style.width = d.x + "px";
            this.element.style.height = d.y + "px";
            a.setElementOpacity(this.element, this.opacity)
        }, drawCanvas: function (d) {
            var b = this.position.apply(Math.floor), e = this.position.minus(b), c = this.size.plus(e).apply(Math.floor);
            if (!this.loaded || !this.image || !this.image.width) {
                a.console.warn("Attempting to draw tile %s when it's not yet loaded.", this.toString());
                return
            }
            d.globalAlpha = this.opacity;
            d.drawImage(this.image, b.x, b.y, c.x, c.y);
            if (a.DEBUG_DRAWING) {
                d.strokeStyle = "red";
                d.font = "10px sans-serif";
                d.textAlign = "left";
                d.textBaseline = "top";
                d.fillText(this.url, b.x + 10, b.y + 10);
                d.strokeRect(b.x, b.y, c.x, c.y)
            }
        }, unload: function () {
            if (this.element && this.element.parentNode) {
                this.element.parentNode.removeChild(this.element)
            }
            this.element = null;
            this.image = null;
            this.loaded = false;
            this.loading = false
        }
    }
}(OpenSeadragon));
(function (a) {
    a.OverlayPlacement = {CENTER: 0, TOP_LEFT: 1, TOP: 2, TOP_RIGHT: 3, RIGHT: 4, BOTTOM_RIGHT: 5, BOTTOM: 6, BOTTOM_LEFT: 7, LEFT: 8};
    a.Overlay = function (d, b, c) {
        this.element = d;
        this.scales = b instanceof a.Rect;
        this.bounds = new a.Rect(b.x, b.y, b.width, b.height);
        this.position = new a.Point(b.x, b.y);
        this.size = new a.Point(b.width, b.height);
        this.style = d.style;
        this.placement = b instanceof a.Point ? c : a.OverlayPlacement.TOP_LEFT
    };
    a.Overlay.prototype = {
        adjust: function (b, c) {
            switch (this.placement) {
                case a.OverlayPlacement.TOP_LEFT:
                    break;
                case a.OverlayPlacement.TOP:
                    b.x -= c.x / 2;
                    break;
                case a.OverlayPlacement.TOP_RIGHT:
                    b.x -= c.x;
                    break;
                case a.OverlayPlacement.RIGHT:
                    b.x -= c.x;
                    b.y -= c.y / 2;
                    break;
                case a.OverlayPlacement.BOTTOM_RIGHT:
                    b.x -= c.x;
                    b.y -= c.y;
                    break;
                case a.OverlayPlacement.BOTTOM:
                    b.x -= c.x / 2;
                    b.y -= c.y;
                    break;
                case a.OverlayPlacement.BOTTOM_LEFT:
                    b.y -= c.y;
                    break;
                case a.OverlayPlacement.LEFT:
                    b.y -= c.y / 2;
                    break;
                case a.OverlayPlacement.CENTER:
                default:
                    b.x -= c.x / 2;
                    b.y -= c.y / 2;
                    break
            }
        }, destroy: function () {
            var b = this.element, c = this.style;
            if (b.parentNode) {
                b.parentNode.removeChild(b)
            }
            c.top = "";
            c.left = "";
            c.position = "";
            if (this.scales) {
                c.width = "";
                c.height = ""
            }
        }, drawHTML: function (c) {
            var e = this.element, f = this.style, g = this.scales, b, d;
            if (e.parentNode != c) {
                c.appendChild(e)
            }
            if (!g) {
                this.size = a.getElementSize(e)
            }
            b = this.position;
            d = this.size;
            this.adjust(b, d);
            b = b.apply(Math.floor);
            d = d.apply(Math.ceil);
            f.left = b.x + "px";
            f.top = b.y + "px";
            f.position = "absolute";
            if (g) {
                f.width = d.x + "px";
                f.height = d.y + "px"
            }
        }, update: function (b, c) {
            this.scales = b instanceof a.Rect;
            this.bounds = new a.Rect(b.x, b.y, b.width, b.height);
            this.placement = b instanceof a.Point ? c : a.OverlayPlacement.TOP_LEFT
        }
    }
}(OpenSeadragon));
(function (h) {
    var b = 60000, s = h.getWindowSize(), q = h.Browser.vendor, r = h.Browser.version,
        A = ((q == h.BROWSERS.FIREFOX) || (q == h.BROWSERS.OPERA) || (q == h.BROWSERS.SAFARI && r >= 4) || (q == h.BROWSERS.CHROME && r >= 2) || (q == h.BROWSERS.IE && r >= 9)),
        e = h.isFunction(document.createElement("canvas").getContext);
    h.CAN_USE_CANVAS = e;
    h.Drawer = function (C) {
        var B = arguments, D;
        if (!h.isPlainObject(C)) {
            C = {source: B[0], viewport: B[1], element: B[2]}
        }
        h.extend(true, this, {
            downloading: 0,
            tilesMatrix: {},
            tilesLoaded: [],
            coverage: {},
            lastDrawn: [],
            lastResetTime: 0,
            midUpdate: false,
            updateAgain: true,
            overlays: [],
            maxImageCacheCount: h.DEFAULT_SETTINGS.maxImageCacheCount,
            imageLoaderLimit: h.DEFAULT_SETTINGS.imageLoaderLimit,
            minZoomImageRatio: h.DEFAULT_SETTINGS.minZoomImageRatio,
            wrapHorizontal: h.DEFAULT_SETTINGS.wrapHorizontal,
            wrapVertical: h.DEFAULT_SETTINGS.wrapVertical,
            immediateRender: h.DEFAULT_SETTINGS.immediateRender,
            blendTime: h.DEFAULT_SETTINGS.blendTime,
            alwaysBlend: h.DEFAULT_SETTINGS.alwaysBlend,
            minPixelRatio: h.DEFAULT_SETTINGS.minPixelRatio
        }, C);
        this.container = h.getElement(this.element);
        this.canvas = h.makeNeutralElement(e ? "canvas" : "div");
        this.context = e ? this.canvas.getContext("2d") : null;
        this.normHeight = this.source.dimensions.y / this.source.dimensions.x;
        this.element = this.container;
        this.canvas.style.width = "100%";
        this.canvas.style.height = "100%";
        this.canvas.style.position = "absolute";
        this.container.style.textAlign = "left";
        this.container.appendChild(this.canvas);
        for (D = 0; D < this.overlays.length; D++) {
            if (h.isPlainObject(this.overlays[D])) {
                (function (I, E) {
                    var G = document.createElement("a"), F = new h.Rect(E.x, E.y, E.width, E.height), H = Math.floor(Math.random() * 10000000);
                    G.href = "#/overlay/" + H;
                    G.id = H;
                    G.className = E.className ? E.className : "openseadragon-overlay";
                    I.overlays[D] = new h.Overlay(G, F)
                }(this, this.overlays[D]))
            } else {
                if (h.isFunction(this.overlays[D])) {
                }
            }
        }
    };
    h.Drawer.prototype = {
        addOverlay: function (D, B, C) {
            D = h.getElement(D);
            if (m(this.overlays, D) >= 0) {
                return
            }
            this.overlays.push(new h.Overlay(D, B, C));
            this.updateAgain = true
        }, updateOverlay: function (E, B, D) {
            var C;
            E = h.getElement(E);
            C = m(this.overlays, E);
            if (C >= 0) {
                this.overlays[C].update(B, D);
                this.updateAgain = true
            }
        }, removeOverlay: function (C) {
            var B;
            C = h.getElement(C);
            B = m(this.overlays, C);
            if (B >= 0) {
                this.overlays[B].destroy();
                this.overlays.splice(B, 1);
                this.updateAgain = true
            }
        }, clearOverlays: function () {
            while (this.overlays.length > 0) {
                this.overlays.pop().destroy();
                this.updateAgain = true
            }
        }, needsUpdate: function () {
            return this.updateAgain
        }, numTilesLoaded: function () {
            return this.tilesLoaded.length
        }, reset: function () {
            j(this);
            this.lastResetTime = +new Date();
            this.updateAgain = true
        }, update: function () {
            this.midUpdate = true;
            z(this);
            this.midUpdate = false
        }, loadImage: function (E, H) {
            var G = this, F = false, C, D, B;
            if (!this.imageLoaderLimit || this.downloading < this.imageLoaderLimit) {
                this.downloading++;
                C = new Image();
                B = function (I) {
                    G.downloading--;
                    if (typeof(H) == "function") {
                        try {
                            H(C)
                        } catch (J) {
                            h.console.error("%s while executing %s callback: %s", J.name, E, J.message, J)
                        }
                    }
                };
                C.onload = function () {
                    t(C, B, true)
                };
                C.onabort = C.onerror = function () {
                    t(C, B, false)
                };
                D = window.setTimeout(function () {
                    h.console.warn("Timed out loading image " + C.src);
                    t(C, B, false, D)
                }, b);
                F = true;
                C.src = E
            }
            return F
        }
    };
    function z(M) {
        M.updateAgain = false;
        var R, B, D = null, G = false, N = +new Date(), C = M.viewport.getContainerSize(), O = M.viewport.getBounds(true), P = O.getTopLeft(),
            J = O.getBottomRight(), F = M.viewport.deltaPixelsFromPoints(M.source.getPixelRatio(0), true).x,
            H = Math.max(M.source.minLevel, Math.floor(Math.log(M.minZoomImageRatio) / Math.log(2))),
            L = Math.min(M.source.maxLevel, Math.floor(Math.log(F / M.minPixelRatio) / Math.log(2))), I, E, Q, T, S, K;
        if (M.immediateRender) {
            H = L
        }
        while (M.lastDrawn.length > 0) {
            R = M.lastDrawn.pop();
            R.beingDrawn = false
        }
        M.canvas.innerHTML = "";
        if (e) {
            M.canvas.width = C.x;
            M.canvas.height = C.y;
            M.context.clearRect(0, 0, C.x, C.y)
        }
        if (!M.wrapHorizontal && (J.x < 0 || P.x > 1)) {
            return
        } else {
            if (!M.wrapVertical && (J.y < 0 || P.y > M.normHeight)) {
                return
            }
        }
        if (!M.wrapHorizontal) {
            P.x = Math.max(P.x, 0);
            J.x = Math.min(J.x, 1)
        }
        if (!M.wrapVertical) {
            P.y = Math.max(P.y, 0);
            J.y = Math.min(J.y, M.normHeight)
        }
        H = Math.min(H, L);
        for (B = L; B >= H; B--) {
            I = M.viewport.deltaPixelsFromPoints(M.source.getPixelRatio(B), true).x;
            if ((!G && I >= M.minPixelRatio) || (B == H)) {
                drawLevel = true;
                G = true
            } else {
                if (!G) {
                    continue
                }
            }
            E = M.viewport.deltaPixelsFromPoints(M.source.getPixelRatio(B), false).x;
            Q = M.viewport.deltaPixelsFromPoints(M.source.getPixelRatio(0), false).x;
            T = M.immediateRender ? 1 : Q;
            S = Math.min(1, (I - 0.5) / 0.5);
            K = T / Math.abs(T - E);
            D = y(M, G, B, S, K, P, J, N, D);
            if (g(M.coverage, B)) {
                break
            }
        }
        a(M, M.lastDrawn);
        o(M.viewport, M.overlays, M.container);
        if (D) {
            c(M, D, N);
            M.updateAgain = true
        }
    }

    function y(F, N, C, E, M, O, P, D, G) {
        var L, K, J, I, B, H = F.viewport.pixelFromPoint(F.viewport.getCenter());
        J = F.source.getTileAtPoint(C, O);
        I = F.source.getTileAtPoint(C, P);
        B = F.source.getNumTiles(C);
        x(F.coverage, C);
        if (!F.wrapHorizontal) {
            I.x = Math.min(I.x, B.x - 1)
        }
        if (!F.wrapVertical) {
            I.y = Math.min(I.y, B.y - 1)
        }
        for (L = J.x; L <= I.x; L++) {
            for (K = J.y; K <= I.y; K++) {
                G = f(F, drawLevel, N, L, K, C, E, M, H, B, D, G)
            }
        }
        return G
    }

    function f(G, P, O, M, L, C, F, N, K, B, D, H) {
        var J = p(M, L, C, G.source, G.tilesMatrix, D, B, G.normHeight), E = P, I;
        k(G.coverage, C, M, L, false);
        if (!J.exists) {
            return H
        }
        if (O && !E) {
            if (v(G.coverage, C, M, L)) {
                k(G.coverage, C, M, L, true)
            } else {
                E = true
            }
        }
        if (!E) {
            return H
        }
        w(J, G.source.tileOverlap, G.viewport, K, N);
        if (J.loaded) {
            G.updateAgain = n(G, J, M, L, C, F, D)
        } else {
            if (J.loading) {
            } else {
                H = l(H, J)
            }
        }
        return H
    }

    function p(M, L, E, K, I, G, O, C) {
        var H, F, B, N, D, J;
        if (!I[E]) {
            I[E] = {}
        }
        if (!I[E][M]) {
            I[E][M] = {}
        }
        if (!I[E][M][L]) {
            H = (O.x + (M % O.x)) % O.x;
            F = (O.y + (L % O.y)) % O.y;
            B = K.getTileBounds(E, H, F);
            N = K.tileExists(E, H, F);
            D = K.getTileUrl(E, H, F);
            B.x += 1 * (M - H) / O.x;
            B.y += C * (L - F) / O.y;
            I[E][M][L] = new h.Tile(E, M, L, B, N, D)
        }
        J = I[E][M][L];
        J.lastTouchTime = G;
        return J
    }

    function c(B, C, D) {
        C.loading = B.loadImage(C.url, function (E) {
            d(B, C, D, E)
        })
    }

    function d(F, M, E, G) {
        var I, D, L, C, H, O, K, B, N, J;
        M.loading = false;
        if (F.midUpdate) {
            h.console.warn("Tile load callback in middle of drawing routine.");
            return
        } else {
            if (!G) {
                h.console.log("Tile %s failed to load: %s", M, M.url);
                M.exists = false;
                return
            } else {
                if (E < F.lastResetTime) {
                    h.console.log("Ignoring tile %s loaded before reset: %s", M, M.url);
                    return
                }
            }
        }
        M.loaded = true;
        M.image = G;
        I = F.tilesLoaded.length;
        if (F.tilesLoaded.length >= F.maxImageCacheCount) {
            D = Math.ceil(Math.log(F.source.tileSize) / Math.log(2));
            L = null;
            O = -1;
            for (J = F.tilesLoaded.length - 1; J >= 0; J--) {
                K = F.tilesLoaded[J];
                if (K.level <= F.cutoff || K.beingDrawn) {
                    continue
                } else {
                    if (!L) {
                        L = K;
                        O = J;
                        continue
                    }
                }
                B = K.lastTouchTime;
                C = L.lastTouchTime;
                N = K.level;
                H = L.level;
                if (B < C || (B == C && N > H)) {
                    L = K;
                    O = J
                }
            }
            if (L && O >= 0) {
                L.unload();
                I = O
            }
        }
        F.tilesLoaded[I] = M;
        F.updateAgain = true
    }

    function w(G, L, I, H, M) {
        var E = G.bounds.getTopLeft(), N = G.bounds.getSize(), F = I.pixelFromPoint(E, true), K = I.pixelFromPoint(E, false),
            C = I.deltaPixelsFromPoints(N, true), D = I.deltaPixelsFromPoints(N, false), J = K.plus(D.divide(2)), B = H.distanceTo(J);
        G.position = F;
        G.size = C;
        G.distance = B;
        G.visibility = M
    }

    function n(F, I, K, J, B, E, D) {
        var C = 1000 * F.blendTime, H, G;
        if (!I.blendStart) {
            I.blendStart = D
        }
        H = D - I.blendStart;
        G = Math.min(1, H / C);
        if (F.alwaysBlend) {
            G *= E
        }
        I.opacity = G;
        F.lastDrawn.push(I);
        if (G == 1) {
            k(F.coverage, B, K, J, true)
        } else {
            if (H < C) {
                return true
            }
        }
        return false
    }

    function j(B) {
        B.tilesMatrix = {};
        B.tilesLoaded = []
    }

    function g(G, I, B, H) {
        var E, F, D, C;
        if (!G[I]) {
            return false
        }
        if (B === undefined || H === undefined) {
            E = G[I];
            for (D in E) {
                if (E.hasOwnProperty(D)) {
                    F = E[D];
                    for (C in F) {
                        if (F.hasOwnProperty(C) && !F[C]) {
                            return false
                        }
                    }
                }
            }
            return true
        }
        return (G[I][B] === undefined || G[I][B][H] === undefined || G[I][B][H] === true)
    }

    function v(C, E, B, D) {
        if (B === undefined || D === undefined) {
            return g(C, E + 1)
        } else {
            return (g(C, E + 1, 2 * B, 2 * D) && g(C, E + 1, 2 * B, 2 * D + 1) && g(C, E + 1, 2 * B + 1, 2 * D) && g(C, E + 1, 2 * B + 1, 2 * D + 1))
        }
    }

    function k(D, F, C, E, B) {
        if (!D[F]) {
            h.console.warn("Setting coverage for a tile before its level's coverage has been reset: %s", F);
            return
        }
        if (!D[F][C]) {
            D[F][C] = {}
        }
        D[F][C][E] = B
    }

    function x(B, C) {
        B[C] = {}
    }

    function m(D, C) {
        var B;
        for (B = D.length - 1; B >= 0; B--) {
            if (D[B].element == C) {
                return B
            }
        }
        return -1
    }

    function l(B, C) {
        if (!B) {
            return C
        }
        if (C.visibility > B.visibility) {
            return C
        } else {
            if (C.visibility == B.visibility) {
                if (C.distance < B.distance) {
                    return C
                }
            }
        }
        return B
    }

    function t(B, E, D, C) {
        B.onload = null;
        B.onabort = null;
        B.onerror = null;
        if (C) {
            window.clearTimeout(C)
        }
        window.setTimeout(function () {
            E(B.src, D ? B : null)
        }, 1)
    }

    function o(B, E, C) {
        var D, F = E.length;
        for (D = 0; D < F; D++) {
            u(B, E[D], C)
        }
    }

    function u(B, D, C) {
        D.position = B.pixelFromPoint(D.bounds.getTopLeft(), true);
        D.size = B.deltaPixelsFromPoints(D.bounds.getSize(), true);
        D.drawHTML(C)
    }

    function a(D, C) {
        var B, E;
        for (B = C.length - 1; B >= 0; B--) {
            E = C[B];
            if (e) {
                E.drawCanvas(D.context)
            } else {
                E.drawHTML(D.canvas)
            }
            E.beingDrawn = true
        }
    }
}(OpenSeadragon));
(function (a) {
    a.Viewport = function (c) {
        var b = arguments;
        if (b.length && b[0] instanceof a.Point) {
            c = {containerSize: b[0], contentSize: b[1], config: b[2]}
        }
        if (c.config) {
            a.extend(true, c, c.config);
            delete c.config
        }
        a.extend(true, this, {
            containerSize: null,
            contentSize: null,
            zoomPoint: null,
            springStiffness: a.DEFAULT_SETTINGS.springStiffness,
            animationTime: a.DEFAULT_SETTINGS.animationTime,
            minZoomImageRatio: a.DEFAULT_SETTINGS.minZoomImageRatio,
            maxZoomPixelRatio: a.DEFAULT_SETTINGS.maxZoomPixelRatio,
            visibilityRatio: a.DEFAULT_SETTINGS.visibilityRatio,
            wrapHorizontal: a.DEFAULT_SETTINGS.wrapHorizontal,
            wrapVertical: a.DEFAULT_SETTINGS.wrapVertical
        }, c);
        this.centerSpringX = new a.Spring({initial: 0, springStiffness: this.springStiffness, animationTime: this.animationTime});
        this.centerSpringY = new a.Spring({initial: 0, springStiffness: this.springStiffness, animationTime: this.animationTime});
        this.zoomSpring = new a.Spring({initial: 1, springStiffness: this.springStiffness, animationTime: this.animationTime});
        this.resetContentSize(this.contentSize);
        this.goHome(true);
        this.update()
    };
    a.Viewport.prototype = {
        resetContentSize: function (b) {
            this.contentSize = b;
            this.contentAspectX = this.contentSize.x / this.contentSize.y;
            this.contentAspectY = this.contentSize.y / this.contentSize.x;
            this.homeBounds = new a.Rect(0, 0, 1, this.contentAspectY);
            this.fitWidthBounds = new a.Rect(0, 0, 1, this.contentAspectX);
            this.fitHeightBounds = new a.Rect(0, 0, 1, this.contentAspectY)
        }, getHomeZoom: function () {
            var b = Math.max(this.contentAspectX, this.contentAspectY) / this.getAspectRatio();
            return (b >= 1) ? 1 : b
        }, getMinZoom: function () {
            var b = this.getHomeZoom();
            zoom = this.minZoomImageRatio * b;
            return Math.min(zoom, b)
        }, getMaxZoom: function () {
            var b = this.contentSize.x * this.maxZoomPixelRatio / this.containerSize.x;
            return Math.max(b, this.getHomeZoom())
        }, getAspectRatio: function () {
            return this.containerSize.x / this.containerSize.y
        }, getContainerSize: function () {
            return new a.Point(this.containerSize.x, this.containerSize.y)
        }, getBounds: function (e) {
            var c = this.getCenter(e), d = 1 / this.getZoom(e), b = d / this.getAspectRatio();
            return new a.Rect(c.x - (d / 2), c.y - (b / 2), d, b)
        }, getCenter: function (h) {
            var f = new a.Point(this.centerSpringX.current.value, this.centerSpringY.current.value),
                k = new a.Point(this.centerSpringX.target.value, this.centerSpringY.target.value), g, l, c, j, b, d, e, m;
            if (h) {
                return f
            } else {
                if (!this.zoomPoint) {
                    return k
                }
            }
            g = this.pixelFromPoint(this.zoomPoint, true);
            l = this.getZoom();
            c = 1 / l;
            j = c / this.getAspectRatio();
            b = new a.Rect(f.x - c / 2, f.y - j / 2, c, j);
            d = this.zoomPoint.minus(b.getTopLeft()).times(this.containerSize.x / b.width);
            e = d.minus(g);
            m = e.divide(this.containerSize.x * l);
            return k.plus(m)
        }, getZoom: function (b) {
            if (b) {
                return this.zoomSpring.current.value
            } else {
                return this.zoomSpring.target.value
            }
        }, applyConstraints: function (f) {
            var m = this.getZoom(), j = Math.max(Math.min(m, this.getMaxZoom()), this.getMinZoom()), c, g, d, e, k, h, b, n = 0, l = 0;
            if (m != j) {
                this.zoomTo(j, this.zoomPoint, f)
            }
            c = this.getBounds();
            g = this.visibilityRatio * c.width;
            d = this.visibilityRatio * c.height;
            e = c.x + c.width;
            k = 1 - c.x;
            h = c.y + c.height;
            b = this.contentAspectY - c.y;
            if (this.wrapHorizontal) {
            } else {
                if (e < g) {
                    n = g - e
                } else {
                    if (k < g) {
                        n = k - g
                    }
                }
            }
            if (this.wrapVertical) {
            } else {
                if (h < d) {
                    l = d - h
                } else {
                    if (b < d) {
                        l = b - d
                    }
                }
            }
            if (n || l) {
                c.x += n;
                c.y += l;
                this.fitBounds(c, f)
            }
        }, ensureVisible: function (b) {
            this.applyConstraints(b)
        }, fitBounds: function (b, g) {
            var d = this.getAspectRatio(), e = b.getCenter(), f = new a.Rect(b.x, b.y, b.width, b.height), c, k, h, j;
            if (f.getAspectRatio() >= d) {
                f.height = b.width / d;
                f.y = e.y - f.height / 2
            } else {
                f.width = b.height * d;
                f.x = e.x - f.width / 2
            }
            this.panTo(this.getCenter(true), true);
            this.zoomTo(this.getZoom(true), null, true);
            c = this.getBounds();
            k = this.getZoom();
            h = 1 / f.width;
            if (h == k || f.width == c.width) {
                this.panTo(e, g);
                return
            }
            j = c.getTopLeft().times(this.containerSize.x / c.width).minus(f.getTopLeft().times(this.containerSize.x / f.width)).divide(this.containerSize.x / c.width - this.containerSize.x / f.width);
            this.zoomTo(h, j, g)
        }, goHome: function (b) {
            return this.fitVertically(b)
        }, fitVertically: function (c) {
            var b = this.getCenter();
            if (this.wrapHorizontal) {
                b.x = (1 + (b.x % 1)) % 1;
                this.centerSpringX.resetTo(b.x);
                this.centerSpringX.update()
            }
            if (this.wrapVertical) {
                b.y = (this.contentAspectY + (b.y % this.contentAspectY)) % this.contentAspectY;
                this.centerSpringY.resetTo(b.y);
                this.centerSpringY.update()
            }
            this.fitBounds(this.homeBounds, c)
        }, fitHorizontally: function (c) {
            var b = this.getCenter();
            if (this.wrapHorizontal) {
                b.x = (this.contentAspectX + (b.x % this.contentAspectX)) % this.contentAspectX;
                this.centerSpringX.resetTo(b.x);
                this.centerSpringX.update()
            }
            if (this.wrapVertical) {
                b.y = (1 + (b.y % 1)) % 1;
                this.centerSpringY.resetTo(b.y);
                this.centerSpringY.update()
            }
            this.fitBounds(this.fitWidthBounds, c)
        }, panBy: function (d, c) {
            var b = new a.Point(this.centerSpringX.target.value, this.centerSpringY.target.value);
            this.panTo(b.plus(d), c)
        }, panTo: function (b, c) {
            if (c) {
                this.centerSpringX.resetTo(b.x);
                this.centerSpringY.resetTo(b.y)
            } else {
                this.centerSpringX.springTo(b.x);
                this.centerSpringY.springTo(b.y)
            }
        }, zoomBy: function (c, d, b) {
            this.zoomTo(this.zoomSpring.target.value * c, d, b)
        }, zoomTo: function (d, c, b) {
            if (b) {
                this.zoomSpring.resetTo(d)
            } else {
                this.zoomSpring.springTo(d)
            }
            this.zoomPoint = c instanceof a.Point ? c : null
        }, resize: function (c, f) {
            var b = this.getBounds(), d = b, e = c.x / this.containerSize.x;
            this.containerSize = new a.Point(c.x, c.y);
            if (f) {
                d.width = b.width * e;
                d.height = d.width / this.getAspectRatio()
            }
            this.fitBounds(d, true)
        }, update: function () {
            var e = this.centerSpringX.current.value, c = this.centerSpringY.current.value, g = this.zoomSpring.current.value, h, f, d, b;
            if (this.zoomPoint) {
                h = this.pixelFromPoint(this.zoomPoint, true)
            }
            this.zoomSpring.update();
            if (this.zoomPoint && this.zoomSpring.current.value != g) {
                f = this.pixelFromPoint(this.zoomPoint, true);
                d = f.minus(h);
                b = this.deltaPointsFromPixels(d, true);
                this.centerSpringX.shiftBy(b.x);
                this.centerSpringY.shiftBy(b.y)
            } else {
                this.zoomPoint = null
            }
            this.centerSpringX.update();
            this.centerSpringY.update();
            return this.centerSpringX.current.value != e || this.centerSpringY.current.value != c || this.zoomSpring.current.value != g
        }, deltaPixelsFromPoints: function (b, c) {
            return b.times(this.containerSize.x * this.getZoom(c))
        }, deltaPointsFromPixels: function (c, b) {
            return c.divide(this.containerSize.x * this.getZoom(b))
        }, pixelFromPoint: function (b, d) {
            var c = this.getBounds(d);
            return b.minus(c.getTopLeft()).times(this.containerSize.x / c.width)
        }, pointFromPixel: function (b, d) {
            var c = this.getBounds(d);
            return b.divide(this.containerSize.x / c.width).plus(c.getTopLeft())
        }
    }
}(OpenSeadragon));