require('../vendor/avenza/js/avenza-viewer.js');
require('../vendor/avenza/js/openseadragon-min.js');
var Swal2 = require("sweetalert2");

(function() {

    let s,
        config,
        avenzaViewer,
        avenzaInitialized = false,
        avzLayerClass = 'avz_layer_0', // this might change after each map update, please inspect the marker element on the map
        mapublisherBundle = {
            init: function() {
                if (document.querySelector('.mod_mapublisher')) {
                    mapublisherBundle.initMap();
                    mapublisherBundle.setInitialZoom();
                    mapublisherBundle.registerEvents();
                    mapublisherBundle.initMapControll();
                    mapublisherBundle.initOnHash();
                    // FIXME: bootstrap required to manually open modal, but also importing bd twice breaks bootstrap accordion
                    window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
                }

            },
            initMap: function() {
                config = mapublisherBundle.getConfig();

                if (config && (undefined !== config.source)) {

                    mapublisherBundle.generateMap(config);

                    let textInput = document.querySelector('.formhybrid_mapublisher_node_filter_form [name="q"]');
                    mapublisherBundle.setAttribute(textInput, {
                        'autocomplete': 'off',
                        'role': 'combobox',
                        'aria-expanded': 'false',
                        'aria-autocomplete': 'list',
                        'aria-controls': 'autocomplete_ctrl_q'
                    })

                    let avzToolContainer = document.querySelector('.avz-button').parentNode.parentNode;
                    avzToolContainer.style.zIndex = 1;
                    document.querySelector('#map_container').prepend(avzToolContainer);

                    setTimeout(() => {
                        let listBox = document.querySelector('.autocomplete_results_container');
                        listBox.setAttribute('aria-label', 'Stationen');
                        const obsConfig = {childList: true};
                        const obsEl = document.querySelector('.autocomplete_results_container')

                        const callback = (mutationsList, observer) => {
                            for (const mutation of mutationsList) {
                                if (mutation.type === 'childList') {
                                    textInput.setAttribute('aria-expanded', mutation.target.hasChildNodes());
                                }
                            }
                        }

                        const autocompleteObserver = new MutationObserver(callback);
                        autocompleteObserver.observe(obsEl, obsConfig);
                    }, 200)


                }
            },
            generateMap: function(config) {

                AVENZA.initialize();
                avenzaViewer = AVENZA.embedViewer({
                    id: 'map_container',
                    prefixUrl: config.source,
                    minZoomImageRatio: 1,
                    disableClickCallout: 1
                });
            },
            getConfig: function() {
                return document.querySelector('.mapublisher-map') && JSON.parse(document.querySelector('.mapublisher-map').dataset.config);
            },
            initMapControll: function() {
                let mapOffset = document.querySelector("#map_container") && document.querySelector("#map_container").offsetTop,
                    windowOffset = window.pageYOffset,
                    mapHeight = document.querySelector("#map_container").offsetHeight;

                if ((windowOffset + 150) >= mapOffset) {
                    document.querySelector("#map_container").classList.add('fixed');
                }

                // window.onscroll = function() {
                //     windowOffset = document.documentElement.scrollTop;
                //
                //     if ((windowOffset + 150) >= mapOffset && windowOffset <= (mapOffset + mapHeight - 190)) {
                //         document.querySelector("#map_container").classList.add('fixed-controlls');
                //     } else {
                //         document.querySelector("#map_container").classList.remove('fixed-controlls');
                //     }
                // };
            },
            setInitialZoom: function() {
                let i = setInterval(function() {
                    if (avenzaViewer && ('undefined' !== typeof avenzaViewer._contentSize)) {
                        avenzaInitialized = true;
                        avenzaViewer.panToPointAndZoom({x: 500, y: 650}, 1, false);
                        clearInterval(i);
                    }
                }, 250);
            },
            registerEvents: function() {

                document.querySelector('.formhybrid_mapublisher_node_filter_form').addEventListener('submit', e => {
                    e.preventDefault();
                })

                document.querySelector('.avz-home').addEventListener('click', function(e) {
                    e.preventDefault();
                    mapublisherBundle.setInitialZoom();
                });

                document.querySelector('.avz-zoom-in').addEventListener('click', function(e) {
                    e.preventDefault();
                    avenzaViewer.zoomIn();
                });

                document.querySelector('.avz-zoom-out').addEventListener('click', function(e) {
                    e.preventDefault();
                    avenzaViewer.zoomOut();
                });

                document.querySelectorAll('.avz-home, .avz-zoom-in, .avz-zoom-out').forEach(el => {
                    el.addEventListener('keydown', e => {
                        if (e.key === 'Enter') {
                            e.preventDefault()
                            e.target.click()
                        }
                    })
                })

//                 document.querySelector('.formhybrid_mapublisher_node_filter_form [name="q"]').addEventListener('change', function(e) {
// console.log('change')
//                     const options = JSON.parse(document.querySelector('.formhybrid_mapublisher_node_filter_form [name="q"]').dataset.autocompletejsOptions).data.src;
//                     mapublisherBundle.clearAllHighlights();
//
//                     // Only proceed to search if input matches option
//                     if (options.includes(e.target.value)) {
//                         let value = this.value.substr(0, 1).toUpperCase() + this.value.substr(1),
//                             tag = document.querySelectorAll('[data-node*="' + value + '"]');
//                         if (tag.length > 0) {
//                             tag.forEach(t => {
//                                 AVENZA._addClassToFeature(t, 'avz-feature-search-highlight');
//                                 if (t.getAttribute('class').includes('avz_layer_0')) {
//                                     t.setAttribute("tabindex", 0);
//                                     t.setAttribute("role", 'button');
//                                 }
//                             })
//
//                             mapublisherBundle.passToSearch(tag[0], value);
//                             mapublisherBundle.scrollToViewport();
//                         }
//                     }
//                 });

                document.querySelector('.formhybrid_mapublisher_node_filter_form [name="q"]').addEventListener('keydown', function(e) {
                    // 13 = Enter
                    if (e.keyCode === 13) {
                        mapublisherBundle.clearAllHighlights();

                        let value = this.value.substr(0, 1).toUpperCase() + this.value.substr(1),
                            tag = document.querySelectorAll('[data-node*="' + value + '"]');

                        if (tag.length > 0) {
                            tag.forEach(t => {
                                AVENZA._addClassToFeature(t, 'avz-feature-search-highlight');
                                if (t.getAttribute('class').includes(avzLayerClass)) {
                                    t.setAttribute("tabindex", 0);
                                    t.setAttribute("role", 'button');
                                }
                            })

                            mapublisherBundle.passToSearch(tag[0], value);
                            mapublisherBundle.scrollToViewport();
                        }
                    }
                });

                document.addEventListener('huh.autocompletejs.onselection', (e) => {
                    mapublisherBundle.clearAllHighlights();
                    let value = e.detail.item.selection.value;
                    let tag = document.querySelectorAll('[data-node="' + value + '"]');
                    if (tag.length > 0) {
                        tag.forEach(t => {
                            AVENZA._addClassToFeature(t, 'avz-feature-search-highlight');
                            if (t.getAttribute('class').includes(avzLayerClass)) {
                                t.setAttribute("tabindex", 0);
                                t.setAttribute("role", 'button');
                            }
                        })

                        mapublisherBundle.passToSearch(tag[0], value);
                        mapublisherBundle.scrollToViewport();
                        mapublisherBundle.showDetails(tag[0].dataset.nodeId);
                    }
                });

                setTimeout(() => {
                    document.querySelectorAll('.avz-feature').forEach(item => {
                        item.addEventListener('click', function(e) {

                            if (!item.dataset.disable && item.dataset.nodeId != '0' && item.dataset.nodeId != null) {
                                mapublisherBundle.showDetails(item.dataset.nodeId);
                            }

                        });
                        item.addEventListener('keydown', function(e) {
                            if (e.keyCode === 13 && !item.dataset.disable && item.dataset.nodeId != '0' && item.dataset.nodeId != null) {
                                mapublisherBundle.showDetails(item.dataset.nodeId);
                            }
                            // else if (e.shiftKey && e.keyCode === 9) {
                            //     document.querySelector('.formhybrid_mapublisher_node_filter_form [name="q"]').focus();
                            // }

                        });
                    })
                }, 300);

            },
            clearAllHighlights: () => {
                document.querySelectorAll('.avz-feature').forEach(item => item.classList.remove('avz-feature-search-highlight'));
                document.querySelectorAll(avzLayerClass).forEach(item => {
                    item.removeAttribute('tabindex');
                    item.removeAttribute('role')
                });
            },
            passToSearch: function(arrTagElements, value) {
                // let centerPoint = (arrTagElements.length > 1) ? mapublisherBundle.getCenter(arrTagElements) : mapublisherBundle.getCoords($(arrTagElements));
                let centerPoint = mapublisherBundle.getCoords(arrTagElements);

                mapublisherBundle.doSearch(value, centerPoint, true);
            },
            doSearch: function(value, centerPoint, center) {
                if (centerPoint.zoom == '' || typeof centerPoint.zoom === 'undefined') {
                    centerPoint.zoom = 3.0;
                }

                if (value != '') {
                    avenzaViewer.search(value);

                    if (center) {
                        mapublisherBundle.centerAtPoint(centerPoint);
                    }
                }
            },
            centerAtPoint: function(centerPoint) {

                if (typeof centerPoint === 'undefined' || centerPoint === null) {
                    centerPoint = mapublisherBundle.getCoords(document.querySelector('.avz-feature-search-highlight'));
                }

                avenzaViewer.panToPointAndZoom({x: centerPoint.x, y: centerPoint.y}, centerPoint.zoom, false);
            },
            getCenter: function(arrElem) {
                let center = {distance: 0};
                let x = 0;
                let y = 0;

                arrElem.each(function(i, node) {
                    coords = mapublisherBundle.getCoords(node);

                    if (i > 0) {

                        distance = Math.sqrt((Math.pow((coords.x - center.lastX), 2) + Math.pow((coords.y - center.lastY), 2)));

                        if (distance > center.distance) {
                            center.distance = distance;
                        }
                    }

                    center.lastX = coords.x;
                    center.lastY = coords.y;

                    x += coords.x;
                    y += coords.y;
                });

                center.x = x / arrElem.length;
                center.y = y / arrElem.length;

                if (center.distance >= 500) center.zoom = 1.0;
                if (center.distance >= 200 && center.distance < 500) center.zoom = 2.0;
                if (center.distance < 200) center.zoom = 3.0;

                return center;
            },
            getCoords: function(elem) {
                let coords = {
                    x: mapublisherBundle.getAttr(elem, 'x'),
                    y: mapublisherBundle.getAttr(elem, 'y')
                };


                if (!coords.x || !coords.y) {
                    coords = {
                        x: mapublisherBundle.getAttr(elem, 'cx'),
                        y: mapublisherBundle.getAttr(elem, 'cy')
                    };
                }

                return coords;
            },
            getAttr: function(elem, strAttr) {
                return parseInt(elem.getAttribute(strAttr));
            },
            scrollToViewport: function() {
                document.querySelector('html, body').animate({scrollTop: document.querySelector("#map_container").offsetTop}, 500);
            },
            showDetails: function(id) {
                document.querySelector('body').insertAdjacentHTML('beforeend', config.loader);

                let useModal = document.querySelector('#map_container').dataset.modal;
                let privacyCenter = document.querySelector('.privacy-center');

                const handleResponse = data => {
                    let PrivacyCenter = window.PrivacyCenter;
                    let efzUrl = JSON.parse(data).result.data.response.efz_url;

                    if (!efzUrl) {
                        return;
                    }

                    if (PrivacyCenter === undefined) {
                        window.open(efzUrl);
                        return
                    }

                    let hostname = new URL(efzUrl).hostname;

                    if (PrivacyCenter.isExternalDomainAllowed(hostname)) {
                        window.open(efzUrl);
                        return;
                    }

                    // image -> show as alert box
                    let content = document.createElement('div'),
                        description = document.createElement('div'),
                        checkbox = document.createElement('div');

                    description.innerHTML = unescape(
                        privacyCenter.getAttribute('data-secure-external-links-text').replace('%target%', hostname)
                    );

                    checkbox.innerHTML = '<label>' +
                        '<input type="checkbox" name="save-setting" value="1">&nbsp;' +
                        unescape(privacyCenter.getAttribute('data-secure-external-links-save-settings')) +
                        '</label>';

                    content.appendChild(description);
                    content.appendChild(checkbox);

                    Swal2.fire({
                        html: content,
                        showCancelButton: true,
                        reverseButtons: true,
                        buttonsStyling: false,
                        confirmButtonText: privacyCenter.getAttribute('data-secure-external-links-yes'),
                        cancelButtonText: privacyCenter.getAttribute('data-secure-external-links-no'),
                        customClass: {
                            confirmButton: "btn btn-primary",
                            cancelButton: "btn"
                        }
                    })
                        .then((value) => {
                            if (value.isConfirmed) {
                                if (document.querySelector('.swal2-modal [name="save-setting"]').checked) {
                                    PrivacyCenter.allowExternalDomain(hostname);
                                }

                                window.open(efzUrl);
                            }
                        });
                    /*
                    // DEPRECATED
                    // Replace modal content
                    if (document.querySelector('#mapublisher_modal')) {
                        document.querySelector('#mapublisher_modal').parentNode.removeChild(document.querySelector('#mapublisher_modal'));
                    }

                    document.querySelector('body').insertAdjacentHTML('beforeend', JSON.parse(data).result.data.response.template);
                    // FIXME: other way to open modal?
                    var mapublisherModal = document.getElementById('mapublisher_modal')
                    var modal = bootstrap.Modal.getOrCreateInstance(mapublisherModal);// Returns a Bootstrap modal instance
                    modal.show();
                    document.querySelector('#loader').parentNode.removeChild(document.querySelector('#loader'));
                    */
                }

                var request = new XMLHttpRequest();
                let data = {
                    'id': id,
                    'jumpTo': config.detail.target,
                    'useModal': useModal
                };

                data = new URLSearchParams(Object.entries(data)).toString();

                request.open('POST', config.detail.loadDetail, true);
                request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8\n');
                request.responseType = "JSON";
                request.send(data);

                request.onload = function() {
                    if (this.status >= 200 && this.status < 400) {
                        // Success!
                        handleResponse(this.response);
                    } else {
                        // We reached our target server, but it returned an error

                    }
                };

            },
            initOnHash: function() {
                if (document.querySelector('.mod_Mapublisher') && window.location.hash) {
                    var hash = window.location.hash.replace('#', '');

                    mapublisherBundle.showDetails(hash);
                }
            },
            setAttribute: function(el, attrs) {
                for (var key in attrs) {
                    el.setAttribute(key, attrs[key]);
                }
            }
        };

    module.exports = mapublisherBundle;

    document.addEventListener('DOMContentLoaded', mapublisherBundle.init, true);

})();
