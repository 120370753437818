/*
 Copyright 2012, Avenza Systems Inc. 
 */
AVENZA = window.AVENZA || function () {
        var c = [];
        return {
            initialize: function () {
                for (AVENZA._BROWSER_IS_HTML5_READY = !0; 0 < c.length;)c.shift()()
            }, _addInitializer: function (d) {
                c.push(d)
            }, _missingHtml5Feature: function () {
                AVENZA._BROWSER_IS_HTML5_READY = !1
            }
        }
    }();
(function () {
    function c(b, e) {
        var f = document.createElementNS(a, "svg");
        f.setAttribute("viewBox", "0 0 " + b + " " + e);
        f.setAttribute("pointer-events", "none");
        return f
    }

    function d(b) {
        if (b.hasOwnProperty("polylines")) {
            var e = b.polylines, f = e.length, g = document.createElementNS(a, "g");
            g.setAttribute("id", b.id);
            g.setAttribute("class", "avz-feature");
            // added to enable finding when tag is selected
            f.setAttribute("data-node", b.attributes.stationname);
            f.setAttribute("aria-label", b.attributes.stationname);
            f.setAttribute("data-node-id", b.attributes.id_station);
            g.setAttribute("pointer-events", "visiblePainted");
            for (b = 0; b < f; b++) {
                var h = document.createElementNS(a, "polyline");
                h.setAttribute("points", e[b].coords);
                h.setAttribute("fill",
                    "none");
                g.appendChild(h)
            }
            b = g
        } else {
            f = b.polygons;
            g = f.length;
            e = "";
            for (h = 0; h < g; h++) {
                0 < e.length && (e += "Z");
                var e = f[h].coords.split(",");
                break;
            }

            0 < e.length && (e += "Z");

            var action = (b.attributes.id_efz == 0) ? 'no-action' : '';

            // rectangle Hbf = 1; Knotenbahnhof = 2
            if(b.attributes.stationtype == 1 || b.attributes.stationtype == 2)
            {
                f = document.createElementNS(a, "rect");
                f.setAttribute("x", b.extX);
                f.setAttribute("y", b.extY);
                f.setAttribute("rx", 2.4);
                f.setAttribute("ry", 2.4);
                f.setAttribute("width", b.extW);
                f.setAttribute("height", b.extH);
                f.setAttribute("class", "avz-feature avz-feature-rect " + action);
            }
            // circle
            else
            {
                f = document.createElementNS(a, "circle");
                f.setAttribute("cx", b.ctrX);
                f.setAttribute("cy", b.ctrY);
                f.setAttribute("r", 2.4);
                f.setAttribute("class", "avz-feature avz-feature-circle " + action);
            }

            f.setAttribute("data-node", b.attributes.stationname);
            f.setAttribute("aria-label", b.attributes.stationname);
            // f.setAttribute("data-node-id", b.attributes.id_efz);
            f.setAttribute("data-node-id", b.attributes.id_station);
            f.setAttribute("id", b.id);
            f.setAttribute("pointer-events", "visiblePainted");
            f.setAttribute("data-coordX", b.ctrX);
            f.setAttribute("data-coordY", b.ctrY);


            if("0" == b.attributes.id_efz) {
                f.setAttribute("data-disable", true);
            }


            b = f
        }
        return b
    }

    AVENZA._addClassToFeature = function (b, e) {
        var f = b.getAttribute("class").split(" ");
        -1 === f.indexOf(e) && f.push(e);
        b.setAttribute("class", f.join(" "))
    };
    AVENZA._removeClassFromFeature = function (b, e) {
        var f = b.getAttribute("class").split(" "), a = f.indexOf(e);
        0 <= a && (f.splice(a, 1), b.setAttribute("class", f.join(" ")))
    };
    AVENZA._isFeature = function (b) {
        var e = b.getAttribute("class");
        return e && (e = b.getAttribute("class").split(" "), 0 <= e.indexOf("avz-feature")) ? !0 : !1
    };
    var a = "http://www.w3.org/2000/svg";
    AVENZA._addInitializer(function () {
        document.createElementNS && document.createElementNS("http://www.w3.org/2000/svg",
            "svg").createSVGRect ? (AVENZA._createRootFeatureElement = c, AVENZA._createFeatureElement = d) : AVENZA._missingHtml5Feature()
    })
})();
(function () {
    // (function (c) {
    //     c.fn.offsetRelative = function (d) {
    //         var a = c(this), b = a.offsetParent(), a = a.position();
    //         d && "BODY" != b.get(0).tagName && b[0] != c(d)[0] && (d = b.offsetRelative(d), a.top += d.top, a.left += d.left);
    //         return a
    //     };
    //     c.fn.positionRelative = function (d) {
    //         return c(this).offsetRelative(d)
    //     }
    // })(jQuery);
    AVENZA._createBasicWidget = function () {
        var c = document.createElement("div");
        jQuery(c).addClass("avz-border").addClass("avz-dropshadow");
        c.classList.add('avz-border', "avz-dropshadow")
        return c
    };
    AVENZA._createButton = function (c, d) {
        var a = document.createElement("div");
        a.classList.add("avz-button", c);
        a.addEventListener('click', () => d);
        a.addEventListener('dblclick', () => !1);
        a.classList.add("avz-button", c);

        return a
    };
    AVENZA._createBorderButton = function (c, d, a) {
        var b = AVENZA._createButton(c, d);
        b.classList.add("avz-border", "avz-dropshadow");
        if (a) {
            b.classList.add("avz-toolbar-button");
            b.addEventListener('click', () => {
                b.classList.add("avz-widget-activate");
                setTimeout(function () {
                    b.classList.remove("avz-widget-activate")
                }, 200)
            })
        }
        AVENZA._isTouchDevice() || b.classList.add("avz-toolbar-button-notouch");
        return b;
    };
    AVENZA._quadForPageLocation =
        function (c, d) {
            var a = jQuery(c._element).offset(),
                b = jQuery(c._element).width(),
                e = jQuery(c._element).height(),
                f = d.y - a.top;
            return d.x - a.left < b / 2 ? f < e / 2 ? AVENZA.QUAD.TOP_LEFT : AVENZA.QUAD.BOTTOM_LEFT : f < e / 2 ? AVENZA.QUAD.TOP_RIGHT : AVENZA.QUAD.BOTTOM_RIGHT
        }
})();
(function () {
    function c(a) {
        var b = document.createElement("div");
        b.classList.add("avz-dropshadow","avz-border","avz-callout-bubble");
        return b
    }

    function d(a) {
        var b = c(a);
        // original
        // jQuery(b).children(".avz-webtag-title").append("<hr/>");

        jQuery(b).children(".avz-webtag-title");
        a.autoSize || (jQuery(b).width(a.width), jQuery(b).height(a.height), jQuery(b).css("overflow", "hidden"));
        a = AVENZA._elementForWebTag(a);
        jQuery(a).css("overflow", "hidden");
        jQuery(b).append(a);
        return b
    }

    AVENZA._createClickCallout = function (a, b, e, f) {
        e = document.createElement("div");
        jQuery(e).css("position", "absolute").css("pointer-events", "none");
        a = d(a);
        jQuery(a).css("pointer-events", "auto").addClass("avz-click-callout-bubble");
        var g = null;
        switch (f) {
            case AVENZA.QUAD.TOP_LEFT:
                g = "avz-top-left";
                break;
            case AVENZA.QUAD.TOP_RIGHT:
                g = "avz-top-right";
                break;
            case AVENZA.QUAD.BOTTOM_LEFT:
                g = "avz-bottom-left";
                break;
            case AVENZA.QUAD.BOTTOM_RIGHT:
                g = "avz-bottom-right"
        }
        g && jQuery(a).addClass(g);
        jQuery(e).append(a);
        b && (b = AVENZA._createButton("avz-button-close", b), jQuery(b).append(AVENZA._createCloseIcon()), jQuery(a).prepend(b));
        return {
            element: e, update: function () {
            }
        }
    };
    AVENZA._createHoverCallout = function (a, b) {
        var e = b ? c(a) : d(a);
        e.classList.add("avz-hover-callout-bubble")
        return {
            element: e, update: function () {
            }
        }
    };
    AVENZA._createFixedCallout = function (a) {
        a = d(a);
        a.classList.add("avz-fixed-callout-bubble");
        a.style.overflow = "hidden";
        return {
            element: a, update: function () {
            }
        }
    }
})();
(function () {
    function c(b) {
        if (b._options.selected) {
            var a = null, g = b._selectedFeatureId;
            g && b._featureData[g] && (a = {id: g, attributes: b._featureData[g].attributes});
            b._options.selected(a)
        }
    }

    function d(e, f, g, h) {

        h.pageX = g.getAttribute('x') ? parseInt(g.getAttribute('x')) : parseInt(g.getAttribute('cx'));
        h.pageY = g.getAttribute('y') ? parseInt(g.getAttribute('y')) : parseInt(g.getAttribute('cy'));

        e._selectedFeatureId = f;
        e._options.disableClickHighlight || AVENZA._addClassToFeature(g, "avz-feature-selected");
        g = e._featureData[f];

        f = AVENZA._webTagDataForFeature(e, g);
        if (!e._options.disableClickCallout) {
            var d = AVENZA._quadForPageLocation(e, {x: h.pageX, y: h.pageY}), i = AVENZA._createClickCallout(f, function () {
                    a(e)
                },
                !0, d);
            e._clickCallout = i.element;

            // e._addCallout(e._clickCallout, h, d, e._options.useCentroidForClickCallout ? {x: h.pageX, y: h.pageY} : null);

            if(g.attributes.stationtype == 1 || g.attributes.stationtype == 2) {
                e._addCallout(e._clickCallout, h, d, {x: h.pageX+10, y: h.pageY+10});
            }
            else{
                e._addCallout(e._clickCallout, h, d, {x: h.pageX, y: h.pageY+2});
            }
            i.update()
        }
        if (e._options.fixedCallout) h = AVENZA._createFixedCallout(f), e._fixedCallout ? jQuery(e._fixedCallout).replaceWith(h.element) : jQuery(e._element).append(h.element), e._fixedCallout = h.element, h.update();
        f.clickUrl && window.open(f.clickUrl, f.clickUrlTarget);
        b(e);
        c(e)
    }

    function a(b) {
        if (b._clickCallout) b._removeCallout(b._clickCallout), b._clickCallout = null;
        if (b._fixedCallout) jQuery(b._fixedCallout).remove(),
            b._fixedCallout = null;
        var a = b._selectedFeatureId;
        if (b._selectedFeatureId) {
            if (!b._options.disableClickHighlight) {
                var g = document.getElementById(b._selectedFeatureId);
                AVENZA._removeClassFromFeature(g, "avz-feature-selected")
            }
            b._selectedFeatureId = null
        }
        c(b);
        return a
    }

    function b(b) {
        if (b._hoverCallout) b._hoverCallout.parentNode.removeChild(b._hoverCallout), b._hoverCallout = null;
        var a = b._highlightedFeatureId;
        if (b._highlightedFeatureId) {
            var g = document.getElementById(b._highlightedFeatureId);
            b._options.disableHoverHighlight || AVENZA._removeClassFromFeature(g,
                "avz-feature-mouseover");
            b._highlightedFeatureId = null
        }
        return a
    }

    AVENZA._hideCalloutsForLayer = function (e, f) {
        var g = null;
        e._highlightedFeatureId && (g = e._featureData[e._highlightedFeatureId]) && f == g.layer && b(e);
        e._selectedFeatureId && (g = e._featureData[e._selectedFeatureId]) && f == g.layer && a(e)
    };
    AVENZA._handleFeatureClick = function (b, f, g) {
        if (!b._isAnimating) {
            for (var h = g.getAttribute("id"); !h;)g = g.parentNode, h = g.getAttribute("id");
            var c = a(b);
            h != c && AVENZA._isFeature(g) && d(b, h, g, f)
        }
    };
    AVENZA._addCalloutInteractionToFeatures =
        function (a, f) {
            AVENZA._isTouchDevice()
            f.childNodes.forEach(child => {
                child.addEventListener('mousemove', el => {
                    var h = el.target.getAttribute("id");
                    if (!a._isAnimating)
                        if (a._hoverCallout && a._highlightedFeatureId == h) {
                            a._hoverCallout.style.left = f.pageX + 10 + "px";
                            a._hoverCallout.style.top = f.pageY + 10 + "px";
                        } else {
                        var c = a._featureData[h];
                        if (c && c.layer && c.layer.visible && (b(a), a._highlightedFeatureId = h, a._options.disableHoverHighlight || AVENZA._addClassToFeature(el.target, "avz-feature-mouseover"), h != a._selectedFeatureId)) {
                            h = AVENZA._webTagDataForFeature(a, c);
                            if (!a._options.disableHoverCallout && (c = !a._options.useClickCalloutForHover, !c || h.title && 0 < h.title.length))
                                c = AVENZA._createHoverCallout(h, c),
                                    a._hoverCallout = c.element,
                                    a._hoverCallout.style.position = "absolute";
                                    a._hoverCallout.style.left = f.pageX + 10 + "px"
                                    a._hoverCallout.style.top = f.pageY + 10 + "px";
                                    document.querySelector("body").append(a._hoverCallout),
                                    c.update();
                            h.hoverUrl && window.open(h.hoverUrl, h.hoverUrlTarget)
                        }
                    }
                })
                child.addEventListener('mouseout', () => {b(a)})
                child.addEventListener('click', b => { AVENZA._handleFeatureClick(a, b, child)})
            })
        }
})();
(function () {
    AVENZA._showDialog = function (c, d) {
        let avzBg = document.querySelector(".avz-dialog-background")
        avzBg.parentNode.removeChild(avzBg);
        var a = document.createElement("div");
        // jQuery(a).addClass("avz-dialog-background").css("position", "absolute").css("top", "0").css("left", "0").css("width", "100%").css("height", "100%");
        c._element.append(a);
        var b = document.createElement("div");
        // jQuery(b).addClass("avz-dropshadow").addClass("avz-border").css("position", "absolute").css("margin", "10px").css("padding", "20px").html(d);
        a.append(b);
        // var e = jQuery(c._element).innerWidth(),
        //     f = jQuery(c._element).innerHeight(), g = jQuery(b).outerWidth(!0), h = jQuery(b).outerHeight(!0);
        // jQuery(b).css("top", (f - h) / 2 + "px").css("left", (e - g) / 2 + "px");
        return a
    };
    AVENZA._closeDialog = function () {
        jQuery(".avz-dialog-background").fadeOut(function () {
            jQuery(this).remove()
        })
    }
})();
(function () {
    function c(c, a) {
        var b = a, e;
        for (e in c)b = b.replace(RegExp("%" + e + "%", "g"), c[e]);
        return b
    }

    AVENZA._webTagDataForFeature = function (d, a) {
        if (!a.webtag._title) a.webtag._title = c(a.attributes, a.webtag.title);
        if (!a.webtag._content) {
            a.webtag._content = c(a.attributes, a.webtag.content);
            var b = a.webtag, e;
            e = d._sourceMediaFolder();
            e = a.webtag._content.replace(/sourcemedia/g, e);
            b._content = e
        }
        if (a.webtag.image && !a.webtag._image) a.webtag._image = {
            width: a.webtag.image.width, height: a.webtag.image.height, url: d._sourceMediaFolder() +
            "/" + c(a.attributes, a.webtag.image.url)
        };
        if (a.webtag.clickUrl && !a.webtag._clickUrl) a.webtag._clickUrl = c(a.attributes, a.webtag.clickUrl);
        if (a.webtag.clickUrlTarget && !a.webtag._clickUrlTarget) a.webtag._clickUrlTarget = c(a.attributes, a.webtag.clickUrlTarget);
        if (a.webtag.hoverUrl && !a.webtag._hoverUrl) a.webtag._hoverUrl = c(a.attributes, a.webtag.hoverUrl);
        if (a.webtag.hoverUrlTarget && !a.webtag._hoverUrlTarget) a.webtag._hoverUrlTarget = c(a.attributes, a.webtag.hoverUrlTarget);
        return {
            title: a.webtag._title,
            content: a.webtag._content,
            image: a.webtag._image,
            imageAutoSize: a.webtag.imageAutoSize,
            width: a.webtag.width,
            height: a.webtag.height,
            autoSize: a.webtag.autoSize,
            clickUrl: a.webtag._clickUrl,
            clickUrlTarget: a.webtag._clickUrlTarget,
            hoverUrl: a.webtag._hoverUrl,
            hoverUrlTarget: a.webtag._hoverUrlTarget
        }
    };
    AVENZA._elementForWebTagTitle = function (c) {
        var a = document.createElement("div");
        jQuery(a).addClass("avz-webtag-title").html(c.title);
        return a
    };
    AVENZA._CONTENT_IMAGE_PADDING = 5;
    AVENZA._elementForWebTag = function (c) {
        var a = document.createElement("div");
        jQuery(a).addClass("avz-webtag");
        var b = a, e = a;
        if (c.image && c.content) {
            var f = document.createElement("table");
            jQuery(f).css("border", "none").css("border-spacing", "0");
            b = document.createElement("td");
            jQuery(b).css("vertical-align", "top").css("padding", "0").css("padding-right", "7px");
            e = document.createElement("td");
            jQuery(e).css("vertical-align", "top").css("padding", "0");
            var g = document.createElement("tr");
            jQuery(g).append(b).append(e);
            jQuery(f).append(g);
            jQuery(a).append(f)
        }
        f = null;
        c.image && (f = document.createElement("img"),
            jQuery(f).addClass("avz-webtag-image").attr("src", c.image.url), c.imageAutoSize || jQuery(f).attr("width", c.image.width + "px").attr("height", c.image.height + "px"), jQuery(b).append(f));
        c.content && (b = document.createElement("div"), jQuery(b).addClass("avz-webtag-content").html(c.content), jQuery(e).append(b));
        return a
    }
})();
(function () {
    function c(b, a) {
        var f = document.createElement("li"), c = document.createElement("span");
        jQuery(c).attr("id", "check_" + a.id).css("visibility", a.visible ? "visible" : "hidden").html("&nbsp;&#10003;&nbsp;");
        var h = null;
        a.icon && (h = document.createElement("img"), jQuery(h).attr("src", b._sourceMediaFolder() + a.icon));
        var d = document.createElement("span");
        jQuery(d).html("&nbsp;" + a.name + "&nbsp;");
        jQuery(f).append(c).append(h).append(d).click(function () {
            b.setVisible(a, !a.visible);
            jQuery(f).addClass("avz-widget-activate");
            setTimeout(function () {
                jQuery(f).removeClass("avz-widget-activate")
            }, 200);
            return !1
        });
        return f
    }

    function d(b) {
        for (var a = document.createElement("ul"), f = b._layers.length, g = 0; g < f; g++) {
            var h = b._layers[g];
            h.userToggle && (h = c(b, h), jQuery(a).append(h))
        }
        jQuery(a).addClass("avz-widget").addClass("avz-dropshadow").addClass("avz-border").addClass("avz-layer-list");
        AVENZA._isTouchDevice() || jQuery(a).addClass("avz-layer-list-notouch");
        return a
    }

    function a(b) {
        var a = AVENZA._createBorderButton("avz-button-layers", function (a) {
            function e() {
                clearTimeout(j);
                j = null;
                jQuery(l).remove();
                jQuery(i).hide("fast", function () {
                    jQuery(i).remove()
                })
            }

            function c() {
                j || (j = setTimeout(e, 500))
            }

            var l = document.createElement("div"), i = d(b), j = null;
            jQuery(i).css("display", "none").css("position", "absolute").mouseleave(function () {
                c()
            }).mouseover(function () {
                clearTimeout(j);
                j = null
            });
            jQuery(l).css("position", "absolute").css("top", 0).css("left", 0).css("width", "100%").css("height", "100%");
            jQuery(b._element).append(l);
            jQuery(l).click(function () {
                c();
                return !1
            }).bind("touchstart", function () {
                c();
                return !1
            });
            jQuery(b._element).append(i);
            var k = jQuery(b._element).offset(), m = AVENZA._quadForPageLocation(b, {x: a.pageX, y: a.pageY});
            m.isTop ? jQuery(i).css("top", a.pageY - k.top - 3) : jQuery(i).css("bottom", k.top + jQuery(b._element).height() - a.pageY - 3);
            m.isLeft ? jQuery(i).css("left", a.pageX - k.left - 3) : jQuery(i).css("right", k.left + jQuery(b._element).width() - a.pageX - 3);
            jQuery(i).show("fast")
        }, !0);
        jQuery(a).attr("title", "Show layers").append(AVENZA._createLayerListIcon());
        return a
    }

    AVENZA._updateCheckVisibility = function (a) {
        document.querySelector("#check_" +
            a.id).style.visibility = a.visible ? "visible" : "hidden";
    };
    AVENZA._createLayersWidget = function (b) {
        return a(b)
    }
})();
(function () {
    function c(a) {
        a._options.disableSearchHighlight || document.querySelectorAll(".avz-feature-search-highlight").forEach(function (el) {
            AVENZA._removeClassFromFeature(el, "avz-feature-search-highlight")
        })
    }

    function d(a, b) {
        b = b.toLowerCase();
        const objArray = Object.keys(a._featureData).map(i => a._featureData[i])
        objArray.forEach(function (obj) {

            var g = AVENZA._webTagDataForFeature(a, obj);

            // CHANGED: check was 0 <= -> set to == because we only want results where title starts with search-string
            if (0 <= g.title.toLowerCase().indexOf(b)) (g = document.getElementById(obj.id)) && !a._options.disableSearchHighlight && AVENZA._addClassToFeature(g, "avz-feature-search-highlight")
        })
    }

    AVENZA._initializeForSearch = function (a) {
        a.search = function (b) {
            c(a);
            b && d(a, b)
        }
    };
    AVENZA._createSearchWidget = function (a) {
        var b = document.createElement("div");
        jQuery(b).addClass("avz-widget").addClass("avz-dropshadow").addClass("avz-border").addClass("avz-search-box").css("position", "absolute").css("overflow", "hidden");
        var e = function () {
            "" !== jQuery(f).val() ? jQuery(g).css("visibility", "visible") : jQuery(g).css("visibility", "hidden")
        }, f = document.createElement("input");
        jQuery(f).addClass("avz-search-box-input").attr("placeholder",
            a._options.searchBoxPlaceholderText).attr("type", "text").attr("autocorrect", "off").attr("autocapitalize", "off").keydown(function () {
        }).keypress(function (b) {
            if (13 == (b.keyCode ? b.keyCode : b.which)) {
                jQuery(this).blur();
                c(a);
                var f = jQuery(this).val();
                f && d(a, f);
                b.preventDefault()
            }
        }).keyup(e).change(e);
        jQuery(b).append(f);
        var g = AVENZA._createButton("avz-search-box-clear-button", function () {
            c(a);
            jQuery(f).blur().val("");
            e()
        });
        jQuery(g).css("float", "right").css("visibility", "hidden").append(AVENZA._createClearIcon());
        jQuery(b).append(g);
        return b
    }
})();
(function () {
    AVENZA._createHomeIcon = function () {
        var c = document.createElementNS("http://www.w3.org/1999/xhtml", "div");
            // c.setAttribute("class", "avz-icon avz-home");
            c.classList.add("avz-icon", "avz-home");
            c.setAttribute("tabindex", 0);
        // var c = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        // c.setAttribute("viewBox", "0 0 44 44");
        // var d = document.createElementNS("http://www.w3.org/2000/svg", "path");
        // d.setAttribute("class", "avz-icon");
        // d.setAttribute("d", "M36.239,22.267L22.059,9.707L22.002,9.77l-0.058-0.063l-6.349,5.623l0.003-3.394h-3.597v6.576l-4.24,3.754l1.492,1.683l2.748-2.432v12.188c0,0.324,0.264,0.588,0.592,0.588h6.25v-5.549c0-0.326,0.262-0.592,0.59-0.592h5.136\tc0.327,0,0.589,0.266,0.589,0.592v5.549h6.25c0.327,0,0.589-0.264,0.589-0.588V21.514l2.751,2.436L36.239,22.267z");
        // c.appendChild(d);
        return c
    };
    AVENZA._createClearIcon = function () {
        var c = document.createElementNS("http://www.w3.org/1999/xhtml", "div");
        c.setAttribute("class", "avz-icon avz-clear");

        // var c = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        // c.setAttribute("viewBox", "0 0 44 44");
        // var d = document.createElementNS("http://www.w3.org/2000/svg", "path");
        // d.setAttribute("class", "avz-icon");
        // d.setAttribute("d", "M22,2.898c-10.546,0-19.095,8.47-19.095,18.916C2.906,32.261,11.454,40.73,22,40.73c10.544,0,19.093-8.47,19.093-18.917C41.094,11.368,32.545,2.898,22,2.898z M33.389,27.638l-5.396,5.377l-5.819-5.796l-5.846,5.824l-5.396-5.377l5.846-5.823l-5.875-5.853l5.397-5.376l5.874,5.853l5.846-5.825l5.398,5.376l-5.846,5.824L33.389,27.638z");
        // c.appendChild(d);
        return c
    };
    AVENZA._createCloseIcon = function () {
        var c = document.createElementNS("http://www.w3.org/1999/xhtml", "div");
        c.setAttribute("class", "avz-icon avz-close");

        // var c = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        // c.setAttribute("viewBox", "0 0 44 44");
        // var d = document.createElementNS("http://www.w3.org/2000/svg", "path");
        // d.setAttribute("class", "avz-icon");
        // d.setAttribute("d", "M34.8020095825195,30.0493774414063L24.9715270996094,20.2188949584961 34.8020095825195,10.3884143829346 30.1387805938721,5.72518730163574 20.3083000183105,15.5556678771973 10.4775342941284,5.72490310668945 5.81430625915527,10.3881320953369 15.6450710296631,20.2188949584961 5.81458854675293,30.0493774414063 10.4778156280518,34.7126083374023 20.3082981109619,24.8821258544922 30.1387805938721,34.7126083374023 34.8020095825195,30.0493774414063z");
        // c.appendChild(d);
        return c
    };
    AVENZA._createLayerListIcon = function () {
        var c = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        c.setAttribute("viewBox", "0 0 44 44");
        var d = document.createElementNS("http://www.w3.org/2000/svg", "path");
        d.setAttribute("class", "avz-icon");
        d.setAttribute("d", "M22,30.99 L12.531,25.444 9.437,27.01 22,34.369 34.562,27.01 31.469,25.444 22,30.99M22,25.304 L12.256,19.596 9.437,21.022 22,28.381 34.562,21.022 31.744,19.596 22,25.304M22,23.297L9.437,15.938 22,9.582 34.562,15.938 22,23.297z");
        c.appendChild(d);
        return c
    };
    AVENZA._createZoomInIcon = function () {
        var c = document.createElementNS("http://www.w3.org/1999/xhtml", "div");
        c.setAttribute("class", "avz-icon avz-zoom-in");
        c.setAttribute("tabindex", 0);

        // var c = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        // c.setAttribute("viewBox", "0 0 44 44");
        // var d = document.createElementNS("http://www.w3.org/2000/svg", "path");
        // d.setAttribute("class", "avz-icon");
        // d.setAttribute("d", "M40.2000007629395,19.2667999267578L26.2975997924805,19.2667999267578 26.2975997924805,5.36439990997314 19.7028007507324,5.36439990997314 19.7028007507324,19.2667999267578 5.80000019073486,19.2667999267578 5.80000019073486,25.8616008758545 19.7028007507324,25.8616008758545 19.7028007507324,39.7640037536621 26.2975997924805,39.7640037536621 26.2975997924805,25.8616008758545 40.2000007629395,25.8616008758545 40.2000007629395,19.2667999267578z");
        // c.appendChild(d);
        return c
    };
    AVENZA._createZoomOutIcon = function () {
        var c = document.createElementNS("http://www.w3.org/1999/xhtml", "div");
        c.setAttribute("class", "avz-icon avz-zoom-out");
        c.setAttribute("tabindex", 0);
        // var c = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        // c.setAttribute("viewBox", "0 0 44 44");
        // var d = document.createElementNS("http://www.w3.org/2000/svg", "path");
        // d.setAttribute("class", "avz-icon");
        // d.setAttribute("d", "M40.2000007629395,19.2667999267578L5.80000019073486,19.2667999267578 5.80000019073486,25.8616008758545 40.2000007629395,25.8616008758545 40.2000007629395,19.2667999267578z");
        // c.appendChild(d);
        return c
    }
})();
(function () {
    function c() {
        throw"Not implemented";
    }

    function d(a, b) {
        a._options = {
            id: b.id,
            prefixUrl: b.prefixUrl || "./",
            disableMousePan: b.disableMousePan || !1,
            disableMouseZoom: b.disableMouseZoom || !1,
            navigationControls: b.navigationControls || AVENZA.QUAD.TOP_LEFT,
            layerList: b.layerList || AVENZA.QUAD.TOP_LEFT,
            mutuallyExclusiveLayers: b.mutuallyExclusiveLayers || !1,
            searchBox: b.searchBox || AVENZA.HIDDEN,
            searchBoxPlaceholderText: b.searchBoxPlaceholderText || "Search",
            disableClickCallout: b.disableClickCallout || !1,
            useCentroidForClickCallout: b.useCentroidForClickCallout || !1,
            disableHoverCallout: b.disableHoverCallout || !1,
            useClickCalloutForHover: b.useClickCalloutForHover || !1,
            fixedCallout: b.fixedCallout || !1,
            loadingMessage: b.loadingMessage || "Loading...",
            disableClickHighlight: b.disableClickHighlight || !1,
            disableHoverHighlight: b.disableHoverHighlight || !1,
            disableSearchHighlight: b.disableSearchHighlight || !1,
            viewport: b.viewport || null,
            maxZoom: b.maxZoom || 4,
            fastAnimations: !0
        };

        if (b.hasOwnProperty("fastAnimations")) a._options.fastAnimations = b.fastAnimations;
        if (b.hasOwnProperty("minZoomImageRatio")) a._options.minZoomImageRatio =
            b.minZoomImageRatio;
        if (b.hasOwnProperty("visibilityRatio")) a._options.visibilityRatio = b.visibilityRatio;
        if (b.hasOwnProperty("load") && "function" == typeof b.load) a._options.load = b.load;
        if (b.hasOwnProperty("error") && "function" == typeof b.error) a._options.error = b.error;
        if (b.hasOwnProperty("selected") && "function" == typeof b.selected) a._options.selected = b.selected;
        if (!a._options.id)throw"id must be set in options";
        "/" != a._options.prefixUrl.charAt(a._options.prefixUrl.length - 1) && (a._options.prefixUrl += "/")
    }

    AVENZA.HIDDEN = "hidden";
    AVENZA.FLOATING = "floating";
    AVENZA.QUAD = {TOP_LEFT: {isTop: !0, isLeft: !0}, TOP_RIGHT: {isTop: !0, isLeft: !1}, BOTTOM_LEFT: {isTop: !1, isLeft: !0}, BOTTOM_RIGHT: {isTop: !1, isLeft: !1}};
    AVENZA.embedViewer = function (a) {
        return !AVENZA._BROWSER_IS_HTML5_READY ? (jQuery("#" + a.id).children().css("display", "block"), null) : new AVENZA.Viewer(a)
    };

    AVENZA.Viewer = function (a) {
        d(this, a);

        AVENZA._initializePanZoomViewer(this);
        this._element.classList.add("avz-viewer");
        var b = this, a = this._options.prefixUrl;
        // AVENZA._showDialog(b,b._options.loadingMessage);

        var request = new XMLHttpRequest();
        request.open('GET', a + "data.js", true);
        request.responseType = "json"

        const handleSuccess = (a) => {
            b._featureData = {};
            b._contentSize = a.size;
            b._layers = a.layers;
            for (var f = AVENZA._createRootFeatureElement(b._contentSize.width, b._contentSize.height), c = !1, a = b._layers.length, h = a - 1; 0 <= h; h--) {
                var d = b._layers[h];
                d.id = "avz_layer_" + h;
                d.scaleLayer = d.hiddenAbove || d.hiddenBelow ? !0 : !1;
                d.hiddenAbove && (d.hiddenAbove /= 100);
                d.hiddenBelow && (d.hiddenBelow /= 100);
                if (!d.scaleLayer && b._options.mutuallyExclusiveLayers) h === a - 1 ? d.visible = !0 : d.visible && !c ? c = !0 : d.visible = !1;
                b._addLayer(d, h);
                if (d.features && 0 < d.features.length)for (var i = d.features, j = i.length - 1; 0 <= j; j--) {
                    var k = i[j];
                    k.id = "avz_feature_" + h + "_" + j;
                    k.layer = d;
                    b._featureData[k.id] = k;
                    k = AVENZA._createFeatureElement(k);
                    AVENZA._addClassToFeature(k, d.id);
                    f.append(k)
                }
            }

            AVENZA._addCalloutInteractionToFeatures(b, f);
            b._addContentElement(f, b._contentSize.width, b._contentSize.height);
            for (h = 0; h < a; ++h)f = b._layers[h], b._setVisible(f, f.visible);
            a = b._options.layerList;
            h = a != AVENZA.HIDDEN;
            f = !1;
            if (h) {
                c = b._layers.length;
                for (d = 0; d < c; d++)if (b._layers[d].userToggle) {
                    f = !0;
                    break
                }
            }
            h && f && (h = AVENZA._createLayersWidget(b), a == AVENZA.FLOATING && (a = null), b.addWidgetElement(h, a));
            a = b._options.searchBox;
            AVENZA._initializeForSearch(b);
            a != AVENZA.HIDDEN && (h = AVENZA._createSearchWidget(b), b.addWidgetElement(h, a));

            b._setInitialViewport(!0);
            // AVENZA._closeDialog();
            b._options.load && b._options.load()
        }

        request.onload = function() {
            if (this.status >= 200 && this.status < 400) {
                // Success!
                var resp = this.response;
                handleSuccess(resp);
            } else {
                // We reached our target server, but it returned an error

            }
        };

        request.onerror = function() {
            // There was a connection error of some sort
            console.log('error')
            // var f = "<p>Could not download data required to display map.</p>", f = /^file\:/.test(location.href) ?
            //     f + "<p>It appears that this web page is being loaded with a file url. \t\t\t\t\t\tSome web browsers do not allow background loading of data from the file \t\t\t\t\t\tsystem. Try loading this page through a web server instead.</p>" : f + ("<p>Error: " + a.status + " " + a.statusText + "</p>");
            // AVENZA._showDialog(b, f);
            // b._options.error && b._options.error(a.status, a.statusText)
        };

        request.send();

    };
    AVENZA.Viewer.getData = function() {
        return jQuery.ajax({url: 'de/service/stationsdatenbank?as=ajax&ag=mapublisher&aa=loadNodes', dataType: 'json'});
    };

    AVENZA.Viewer.prototype = {
        panUp: c, panDown: c, panLeft: c, panRight: c, panHome: c, zoomIn: c, zoomOut: c, panToPointAndZoom: c, search: c, addWidgetElement: c,
        setVisible: function (a, b) {
            var c, f = this._layers.length;
            if ("string" == typeof a)for (c = 0; c < f; c++)if (0 === a.localeCompare(this._layers[c].name)) {
                a = this._layers[c];
                break
            }
            if (a.hasOwnProperty("id"))if (!a.scaleLayer && this._options.mutuallyExclusiveLayers) {
                if (!a.visible && b)for (c = 0; c < f - 1; c++) {
                    var g = this._layers[c];
                    g.scaleLayer || this._setVisible(g, g === a)
                }
            } else this._setVisible(a, b)
        }, _setVisible: function (a, b) {
            // b ? jQuery("#" + a.id).fadeIn() : jQuery("#" + a.id).fadeOut();
            // document.querySelector("." + a.id).style.visibility = b ? "" : "hidden";
            a.visible = b;
            // AVENZA._updateCheckVisibility(a);
            AVENZA._hideCalloutsForLayer(this, a)
        }, _sourceMediaFolder: function () {
            return this._options.prefixUrl + "sourcemedia/"
        }, _addContentElement: c, _addCallout: c, _removeCallout: c, _addLayer: c, _setInitialViewport: c
    }
})();
(function () {
    AVENZA._isTouchDevice = function () {
        return !!("ontouchstart" in window)
    };
    AVENZA.TouchController = function (c, d) {
        var a = function (a) {
            this.init = function () {
            };
            this.dispose = function () {
            };
            this.onTouchStart = function (b) {
                if (1 <= b.touches.length) a.touchPixelOrigin = a.getTouchPixelOrigin(b), a.dragonViewer.viewport.applyConstraints(!0), a.setCurrentState(a.BASE_STATE)
            };
            this.onTouchMove = function (b) {
                if (2 <= b.touches.length) a.touchPixelOrigin = a.getTouchPixelOrigin(b), a.dragonViewer.viewport.applyConstraints(!0), a.setCurrentState(a.BASE_STATE);
                else {
                    var c = a.getTouchPixelOrigin(b), b = a.dragonViewer.viewport, d = b.getContainerSize(), e = b.getBounds(), c = new OpenSeadragon.Point(a.lastTouchPoint.x - 1.2 * (c.x - a.touchPixelOrigin.x) / d.x * e.width, a.lastTouchPoint.y - 1.2 * (c.y - a.touchPixelOrigin.y) / d.y * e.height);
                    b.panTo(c);
                    b.ensureVisible()
                }
            };
            this.onTouchEnd = function (b) {
                0 === b.touches.length && (a.setCurrentState(a.BASE_STATE), a.dragonViewer.viewport.applyConstraints(!0))
            };
            this.toString = function () {
                return "DragState"
            }
        }, b = function (a) {
            this.init = function () {
            };
            this.dispose =
                function () {
                };
            this.onTouchStart = function () {
            };
            this.onTouchMove = function (b) {
                b = a.getTouchDistance(b);
                if (0 < b) {
                    if (!a.initialTouchDistance) a.initialTouchDistance = b;
                    var b = b / a.initialTouchDistance, c = a.dragonViewer.viewport, d = c.pointFromPixel(a.touchPixelOrigin);
                    c.zoomTo(a.zoom * b, d);
                    c.ensureVisible()
                }
            };
            this.onTouchEnd = function (b) {
                if (2 > b.touches.length) a.setCurrentState(a.BASE_STATE), a.initialTouchDistance = null, a.dragonViewer.viewport.applyConstraints(!0)
            };
            this.toString = function () {
                return "PinchState"
            }
        };
        this.avenzaViewer =
            d;
        this.dragonViewer = c;
        this.lastTouchPoint = this.initialTouchDistance = this.touchPixelOrigin = null;
        this.lastZoom = 1;
        var e = null;
        this.getCurrentState = function () {
            return e
        };
        this.setCurrentState = function (a) {
            e && e.dispose();
            e = a;
            e.init()
        };
        this.getTouchPixelOrigin = function (a) {
            for (var b = a.touches.length, c = new OpenSeadragon.Point(0, 0), d = 0; d < b; d++) {
                var e = a.touches[d];
                c.x += e.pageX;
                c.y += e.pageY
            }
            c.x /= b;
            c.y /= b;
            return c
        };
        this.getTouchDistance = function (a) {
            if (2 > a.touches.length)return 0;
            var b = a.touches[0], a = a.touches[1];
            return Math.abs(b.pageX - a.pageX) + Math.abs(b.pageY - a.pageY)
        };
        this.onTouchStart = function (a) {
            a.preventDefault();
            e.onTouchStart(a)
        };
        this.onTouchMove = function (a) {
            a.preventDefault();
            e.onTouchMove(a)
        };
        this.onTouchEnd = function (a) {
            // TODO: refactor
            if(a.target.id.indexOf('avz_feature') >= 0) {
                var efz = a.target.dataset.nodeId;
                // $.ajax({
                //     url: window.location.href + '?as=ajax&ag=mapublisher&aa=loadDetail',
                //     dataType: 'JSON',
                //     method: 'POST',
                //     data: {id: efz},
                //     success: function (data, textStatus, jqXHR) {
                //         document.querySelector('body').append(data.result.data.modal);
                //         console.log('show modal', '#' + data.result.data.modalID)
                //         //$('#' + data.result.data.modalID).modal('show'); TODO
                //
                //         var newUrl = window.location.href + '#' + efz;
                //
                //         if (window.history) {
                //             history.pushState({url: window.location.href}, null, newUrl);
                //         }
                //     }
                // });

                // const handleResponse = (rsp) => {
                //
                // }
                // var request = new XMLHttpRequest();
                // request.open('POST', window.location.href + '?as=ajax&ag=mapublisher&aa=loadDetail', true);
                // request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
                // request.onload = function() {
                //     if (this.status >= 200 && this.status < 400) {
                //         // Success!
                //         var resp = this.response;
                //         console.log('resp', response)
                //     } else {
                //         // We reached our target server, but it returned an error
                //
                //     }
                // };
                // request.send({id: efz});
            }

            a.preventDefault();
            e.onTouchEnd(a)
        };
        this.TAP_ZOOM_FACTOR = 2;
        this.DOUBLE_TAP_ZOOM_FACTOR = 0.5;
        this.SCALE_TRESHOLD = 0.02;
        this.TAP_DISTANCE_TRESHOLD = 5;
        this.TAP_TIMEOUT = 800;
        this.BASE_STATE = new function (a) {
            this.init = function () {
            };
            this.dispose = function () {
            };
            this.onTouchStart = function (b) {
                var c =
                    b.touches.length, d = a.dragonViewer.viewport;
                a.touchPixelOrigin = a.getTouchPixelOrigin(b);
                a.lastTouchPoint = d.getCenter();
                a.zoom = d.getZoom();
                1 == c ? a.setCurrentState(a.TAP_STATE) : 2 == c && a.setCurrentState(a.TWO_FINGER_TAP_STATE)
            };
            this.onTouchMove = function () {
            };
            this.onTouchEnd = function () {
            };
            this.toString = function () {
                return "BaseState"
            }
        }(this);
        this.PINCH_STATE = d._options.disableMouseZoom ? this.BASE_STATE : new b(this);
        this.DRAG_STATE = d._options.disableMousePan ? this.BASE_STATE : new a(this);
        this.TAP_STATE = new function (a) {
            var b =
                null;
            this.init = function () {
                b = setTimeout(this.timeoutHandler, a.TAP_TIMEOUT)
            };
            this.dispose = function () {
                b && clearTimeout(b)
            };
            this.timeoutHandler = function () {
                b && (clearTimeout(b), b = null);
                a.setCurrentState(a.DRAG_STATE)
            };
            this.onTouchStart = function (b) {
                if (1 <= b.touches.length) a.touchPixelOrigin = a.getTouchPixelOrigin(b), a.setCurrentState(a.PINCH_STATE)
            };
            this.onTouchMove = function (b) {
                a.getTouchPixelOrigin(b).distanceTo(a.touchPixelOrigin) > a.TAP_DISTANCE_TRESHOLD && a.setCurrentState(a.DRAG_STATE)
            };
            this.onTouchEnd =
                function (b) {
                    AVENZA._handleFeatureClick(a.avenzaViewer, {pageX: a.touchPixelOrigin.x, pageY: a.touchPixelOrigin.y}, b.target);
                    a.setCurrentState(a.BASE_STATE)
                };
            this.toString = function () {
                return "TapState"
            }
        }(this);
        this.TWO_FINGER_TAP_STATE = this.PINCH_STATE;
        this.setCurrentState(this.BASE_STATE);
        if (c && c.canvas) a = c.canvas, OpenSeadragon.addEvent(a, "touchstart", this.onTouchStart), OpenSeadragon.addEvent(a, "touchmove", this.onTouchMove), OpenSeadragon.addEvent(a, "touchend", this.onTouchEnd)
    }
})();
(function () {
    function c(a) {
        if (AVENZA.HIDDEN != a._options.navigationControls) {
            var b = a._options.navigationControls;
            AVENZA.FLOATING == b && (b = null);

            var c = AVENZA._createBorderButton("avz-button-home", function () {

                a.panHome()
            }, !0);

            // c.setAttribute('title', "Return to original view");
            c.setAttribute("title", "Zurück zur Hauptansicht");
            c.setAttribute("aria-label", "Zurück zur Hauptansicht");

            c.append(AVENZA._createHomeIcon())

            var d = AVENZA._createBorderButton("avz-button-zoom-in", function () {
                a.zoomIn()
            }, !0);

            // d.setAttribute("title", "Zoom in");
            d.setAttribute("title", "Vergrößern");
            d.setAttribute("aria-label", "Vergrößern");

            d.append(AVENZA._createZoomInIcon());

            var e = AVENZA._createBorderButton("avz-button-zoom-out",
                function () {
                    a.zoomOut()
                }, !0);
            // e.setAttribute("title", "Zoom out");
            e.setAttribute("title", "Verkleinern");
            e.setAttribute("aria-label", "Verkleinern");
            // e.setAttribute("tabindex", 0);
            e.append(AVENZA._createZoomOutIcon());

            b && !b.isLeft ? (a.addWidgetElement(e, b), a.addWidgetElement(d, b), a.addWidgetElement(c, b)) : (a.addWidgetElement(c, b), a.addWidgetElement(d, b), a.addWidgetElement(e, b))
        }
    }

    function d(a) { // TODO?????
        // a.querySelector("a").addEventListener("touchstart", function (a) {
        //     var b = this, c = b.getAttribute("target") || null;
        //     window.open(b.getAttribute("href"), c);
        //     a.preventDefault()
        // })
    }

    function a(a, b) {
        var c = OpenSeadragon.getMousePosition(b).minus(OpenSeadragon.getElementPosition(a.element));
        return a.viewport.pointFromPixel(c)
    }

    function b(a, b, c) {
        var d = b.viewport, a = a._contentSize.width / b.viewport.containerSize.x, a = d.getZoom() / a, c = (new OpenSeadragon.Point(c.x, c.y)).times(a);
        return d.deltaPointsFromPixels(c)
    }

    function e(a, b) {
        for (var c = a._contentSize.width / b.viewport.containerSize.x, c = b.viewport.getZoom() / c, d = a._layers.length, e = 0; e < d; e++) {
            var f = a._layers[e], g = f.scaleLayer;
            g && (f.hiddenAbove && (g = c < f.hiddenAbove), g && f.hiddenBelow && (g = c >= f.hiddenBelow), a.setVisible(f, g))
        }
    }

    function f(a, b) {
        function c() {
            // AVENZA._closeDialog(a)
        }

        var d = AVENZA._createBorderButton("AVENZA_DEBUG_INFO", function () {
            for (var d = b.viewport, e = a._contentSize.width / b.viewport.containerSize.x, f = d.getZoom() / e, e = "zoom=" + f.toFixed(1), g = d.deltaPixelsFromPoints(d.getCenter()).divide(f), e = e + (" center=(" + g.x.toFixed(1) + "," + g.y.toFixed(1) + ")"), e = e + "<br/>", h = d.getBounds(), g = d.deltaPixelsFromPoints(h.getTopLeft()).divide(f), f = d.deltaPixelsFromPoints(h.getBottomRight()).divide(f), e = e + ("top left=(" + g.x.toFixed(1) + "," + g.y.toFixed(1) + ")"), e = e + (" bottom right=(" + f.x.toFixed(1) +
                "," + f.y.toFixed(1) + ")"), e = e + "<br/>", d = d.getContainerSize(), e = e + ("container size=" + d) + "<br/>" + ("content size=(" + a._contentSize.width + "," + a._contentSize.height + ")"), f = d = 0, g = a._layers.length, h = g - 1; 0 <= h; h--) {
                var n = a._layers[h];
                n.svg && d++;
                n.visible && f++
            }
            // e = AVENZA._showDialog(a, e + "<br/>" + (g + " layers, " + d + " svg layers, " + f + " visible") + "<br/>");
            // jQuery(e).click(c)
        }, !0);
        // jQuery(d).attr("title", "Display debugging information").css("padding", "5px").css("width", "auto").css("height", "auto").html("<b>Debug Info</b>");
        a.addWidgetElement(d, AVENZA.QUAD.BOTTOM_RIGHT)
    }

    function g(a) {
        var b = document.createElement("div");
        jQuery(b).attr("id", "AVENZA_DEBUG_FPS").css("background", "red").css("color", "white").css("padding", "3px").html("<b>Calculating...</b>").width(100);
        a.addWidgetElement(b, AVENZA.QUAD.TOP_LEFT);
        setInterval(function () {
            var a = 1E3 / OpenSeadragon.frameTime, c = "green";
            10 > a ? c = "red" : 30 > a && (c = "orange");
            jQuery(b).html("<center><b>" + a.toFixed(1) + " FPS</b></center>").css("background", c)
        }, 1E3)
    }

    function h(c, f) {
        c.pan = function (a,
                          b) {
            var c = f.viewport;
            c.panBy((new OpenSeadragon.Point(a, b)).divide(c.getZoom(!0)));
            c.applyConstraints(!1)
        };
        c.panUp = function () {
            this.pan(0, -l)
        };
        c.panDown = function () {
            this.pan(0, l)
        };
        c.panLeft = function () {
            this.pan(-l, 0)
        };
        c.panRight = function () {
            this.pan(l, 0)
        };
        c.panHome = function () {
            jQuery.isPlainObject(this._options.viewport) ? this._setInitialViewport(!1) : f.viewport.goHome(!1)
        };
        c.zoomIn = function () {
            var a = f.viewport;
            a.zoomBy(i);
            a.applyConstraints(!1)
        };
        c.zoomOut = function () {
            var a = f.viewport;
            a.zoomBy(j);
            a.applyConstraints(!1)
        };
        c.panToPointAndZoom = function (a, c, d) {
            var e = f.viewport, a = b(this, f, a), g = this._contentSize.width / f.viewport.containerSize.x, h = c * g, c = c * e.getAspectRatio() * g, c = new OpenSeadragon.Rect(a.x - 0.5 / h, a.y - 0.5 / c, 1 / h, 1 / c);
            e.fitBounds(c, d);
            e.applyConstraints(d)
        };
        c._addCallout = function (c, e, g, h) {
            e = h ? b(this, f, h) : a(f, e);
            h = null;
            switch (g) {
                case AVENZA.QUAD.TOP_LEFT:
                    h = OpenSeadragon.OverlayPlacement.TOP_LEFT;
                    break;
                case AVENZA.QUAD.TOP_RIGHT:
                    h = OpenSeadragon.OverlayPlacement.TOP_RIGHT;
                    break;
                case AVENZA.QUAD.BOTTOM_LEFT:
                    h = OpenSeadragon.OverlayPlacement.BOTTOM_LEFT;
                    break;
                case AVENZA.QUAD.BOTTOM_RIGHT:
                    h = OpenSeadragon.OverlayPlacement.BOTTOM_RIGHT
            }
            c.style.visibility = "hidden";
            f.drawer.addOverlay(c, e, h);
            f.drawer.update();
            f.drawer.updateOverlay(c, e, h);
            f.drawer.update();
            c.style.visibility = "visible";
            d(c);
            jQuery(c).find("img").load(function () {
                f.drawer.update()
            })
        };
        c._removeCallout = function (a) {
            f.drawer.removeOverlay(a)
        };
        c._setInitialViewport = function (a) {
            var b = this._options.viewport;
            Object.prototype.toString.call(b) === '[object Object]' && this.panToPointAndZoom(b, b.zoom, a);
            e(this, f)
        };
        c._addContentElement =
            function (a) {
                f.drawer.addOverlay(a, f.viewport.getBounds());
                f.drawer.container.appendChild(a)
            };
        c.addWidgetElement = function (a, b) {
            var c = null;
            "string" === typeof a ? (c = AVENZA._createBasicWidget(), c.style.padding = "5px", c.innerHtml = a) : c = a;
            c.classList.add("avz-widget");
            var e = null;
            switch (b) {
                case AVENZA.QUAD.TOP_RIGHT:
                    e = OpenSeadragon.ControlAnchor.TOP_RIGHT;
                    break;
                case AVENZA.QUAD.BOTTOM_LEFT:
                    e = OpenSeadragon.ControlAnchor.BOTTOM_LEFT;
                    break;
                case AVENZA.QUAD.BOTTOM_RIGHT:
                    e = OpenSeadragon.ControlAnchor.BOTTOM_RIGHT;
                    break;
                case AVENZA.QUAD.TOP_LEFT:
                    e = OpenSeadragon.ControlAnchor.TOP_LEFT
            }
            f.addControl(c, e);
            d(c);
            return c
        };
        c._addLayer = function (a) {
            var b = null;
            if (a.tileSet) {
                var e = a.tileSet, d = [], g = null, h = null;
                e.displayRects.forEach(function (item) {
                    d.push(new OpenSeadragon.DisplayRect(item.rect.x, item.rect.y, item.rect.width, item.rect.height, item.minLevel, item.maxLevel));
                    g = item.minLevel;
                    h = item.maxLevel
                });
                e = new OpenSeadragon.DziTileSource(e.width, e.height, e.tileSize, 0, c._options.prefixUrl + e.prefixUrl + "/", e.format, d, g, h);
                b = f.isOpen() ? f.addLayer(e) : f.open(e).drawer.canvas
            } else a.dzi ? OpenSeadragon.createFromDZI(c._options.prefixUrl + a.dzi, function (c) {
                b = f.isOpen() ? f.addLayer(c) : f.open(c).canvas;
                b.setAttribute("id", a.id)
            }) : a.svg && (b = document.createElement("img"), b.setAttribute("class", "avz-svg-layer"), b.setAttribute("width", c._contentSize.width + "px"), b.setAttribute("height", c._contentSize.height + "px"), b.setAttribute("src", c._options.prefixUrl + a.svg), c._addContentElement(b, c._contentSize.width, c._contentSize.height));
            b && b.setAttribute("id", a.id)
        }
    }

    var l =
        0.2, i = 2, j = 1 / i;
    AVENZA._initializePanZoomViewer = function (a) {
        var b = {
            id: a._options.id,
            prefixUrl: a._options.prefixUrl,
            showNavigationControl: !1,
            showNavigator: !1,
            mouseNavEnabled: !(a._options.disableMousePan && a._options.disableMouseZoom),
            autoHideControls: !1,
            maxZoomPixelRatio: 1,
            minZoomImageRatio: a._options.hasOwnProperty("minZoomImageRatio") ? a._options.minZoomImageRatio : 0.9,
            visibilityRatio: a._options.hasOwnProperty("visibilityRatio") ? a._options.visibilityRatio : 0.5,
            animationTime: 0.5,
            minPixelRatio: 0.3,
            minZoomLevel: 1.2,
            immediateRender: !0,
            blendTime: 0,
            fastAnimations: a._options.fastAnimations
        }, d = new OpenSeadragon.Viewer(b);


        d.clearControls();
        d.innerTracker.clickHandler = null;
        if (a._options.disableMousePan) d.innerTracker.dragHandler = null;
        if (a._options.disableMouseZoom) d.innerTracker.scrollHandler = null;
        AVENZA._isTouchDevice() && new AVENZA.TouchController(d, a);
        h(a, d);
        d.addHandler("animationstart", function () {
            if (b.fastAnimations) {
                d.drawer.overlays[0].style.visibility = "hidden";
            }
            a._isAnimating = !0
        });
        d.addHandler("animationfinish", function () {
            if(b.fastAnimations) {
                d.drawer.overlays[0].style.visibility = "visible";

            }
            e(a, d);
            a._isAnimating = !1
        });
        if (AVENZA.DEBUG_FPS) OpenSeadragon.DEBUG_FPS = !0, g(a, d);
        if (AVENZA.DEBUG_DRAWING) OpenSeadragon.DEBUG_DRAWING = !0;
        AVENZA.DEBUG_INFO && f(a, d);
        c(a, d);
        a._element = d.element;
        a._element.style.position = "relative";
        var i = document.querySelector("#" + a._options.id).style.maxWidth;
        i && (d.canvas.style.maxWidth = i, d.ccontainer.style.maxWidth = i);
        var j = document.querySelector("#" + a._options.id).style.maxHeight;
        i && (d.canvas.style.maxHeight = j, d.container.style.maxHeight = j)
    };
    AVENZA._addInitializer(function () {
        (!OpenSeadragon.CAN_USE_CANVAS || !OpenSeadragon.CAN_USE_CSS_TRANSFORMS) && AVENZA._missingHtml5Feature()
    })
})();
